import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { validateDate } from "../../../utils/helpers/universalFunctions";

const PaymentStructure = () => {
  const {bookingDetailsData} = useSelector(state=>state.viewBookingReducer)


  return (
    <div>
      <table className="w-full border-collapse border-2 border-[#222]">
        <thead>
          <tr>
            <th
              colSpan="2"
              className="border-b-2 border-r-2 border-[#222] w-2/5 text-left"
            >
              Item Description
            </th>
            <th className="border-b-2 border-r-2 border-[#222]">
              Unit Price(Rs.)
            </th>
            <th className="border-b-2 border-r-2 border-[#222]">No. Of {bookingDetailsData?.charge_on === "day" ? "Days" : "Bookings"}</th>
            <th className="border-b-2 border-[#222]">Amount(Rs.)</th>
          </tr>
          <tr>
            <td
              colSpan="2"
              className="border-b border-r-2 border-[#222] p-1 text-sm"
              height="30"
            >
              Booking Frequency : {bookingDetailsData?.booking_frequency}
            </td>
            <td className="text-center border-r-2 border-[#222]">
              {
                bookingDetailsData?.unit_price!=="Not Chargable" ?
                parseFloat(bookingDetailsData?.unit_price)?.toFixed(2)
                :
                '0.00'
              }
            </td>
            <td className="text-center border-r-2 border-[#222]">
              {bookingDetailsData?.charge_on == "day" ? bookingDetailsData?.no_of_day : bookingDetailsData?.no_of_booking }
            </td>
            <td className="text-center border-r-2 border-[#222]">
              {
                bookingDetailsData?.unit_price && bookingDetailsData?.unit_price!=="Not Chargable" ?
                bookingDetailsData?.booking_amount?.toFixed(2)
                :
                '0.00'              
              }
            </td>
          </tr>
          <tr>
            <td
              colSpan="2"
              className="border-b border-r-2 border-[#222] text-sm p-1"
            >
              {
                bookingDetailsData?.booking_frequency  === 'One Time' ?
                <>
                  Date Of Booking: {moment(bookingDetailsData?.date_of_booking)?.format('DD/MM/YYYY')}
                </>
                :
                <>
                  Date Range: From: {moment(bookingDetailsData?.from_date)?.format('DD/MM/YYYY')} To: {moment(bookingDetailsData?.to_date)?.format('DD/MM/YYYY')}
                </>
              }

            </td>
            <td className="text-center border-r-2 border-[#222]"></td>
            <td className="text-center border-r-2 border-[#222]"></td>
            <td className="text-center border-r-2 border-[#222]"></td>
          </tr>
          <tr className="border-b-2 border-[#222]">
            <td
              colSpan="2"
              className="border-b border-r-2 border-[#222] p-1 text-sm"
              >
              Time Slot : {bookingDetailsData?.slot_type === 'hours' ? bookingDetailsData?.time_slot: `${moment(bookingDetailsData?.time_slot?.slice(0, 9), 'hh:mm:ss').format('hh:mm A')} - ${moment(bookingDetailsData?.time_slot?.slice(12), 'hh:mm:ss').format('hh:mm A')} (${bookingDetailsData?.facilities_type})`}
            </td>
            <td className="text-center border-r-2 border-[#222] border-b-2"></td>
            <td className="text-center border-r-2 border-[#222] border-b-2"></td>
            <td className="text-center border-r-2 border-[#222] border-b-2"></td>
          </tr>
            {
              bookingDetailsData?.slot_type  === 'hours' &&
              <tr className="border-b-2 border-[#222]">
                <td
                  colSpan="2"
                  className="border-b border-r-2 border-[#222] p-1 text-sm"
                  >
                Time Range: {`${moment(bookingDetailsData?.fromTime, 'hh:mm').format('hh:mm A')} - ${moment(bookingDetailsData?.toTime, 'hh:mm').format('hh:mm A')}`}
              </td>
              <td className="text-center border-r-2 border-[#222] border-b-2"></td>
              <td className="text-center border-r-2 border-[#222] border-b-2"></td>
              <td className="text-center border-r-2 border-[#222] border-b-2"></td>
            </tr>
            }
        </thead>
        <tbody>
          <tr>
            <td colSpan="2" className="border-r-2 border-[#222]">
              &nbsp;
            </td>
            <td className="text-right border-r-2 border-[#222] border-b-2 text-sm px-2" colSpan="2" >Sub Total</td>
            <td className="text-center border-r-2 border-[#222] border-b-2">
              {
                bookingDetailsData?.unit_price!=="Not Chargable" ?
                bookingDetailsData?.booking_amount?.toFixed(2)
                :
                '0.00'              
              }
            </td>
          </tr>
          <tr>
            <td colSpan="2" className="border-r-2 border-[#222]">
              &nbsp;
            </td>
            <td className="text-right border-r-2 border-[#222] border-b-2 text-sm px-2" colSpan="2" >Security Deposit Amount</td>
            <td className="text-center border-r-2 border-[#222] border-b-2">{bookingDetailsData?.security_deposit ? parseFloat(bookingDetailsData?.security_deposit)?.toFixed(2) : 0.00}</td>
          </tr>
          <tr>
            <td colSpan="2" className="border-r-2 border-[#222]">
              &nbsp;
            </td>
            <td className="text-right border-r-2 border-[#222] border-b-2 text-sm px-2" colSpan="2" >Grand Total</td>
            <td className="text-center border-r-2 border-[#222] border-b-2">
              {
                bookingDetailsData?.unit_price!=="Not Chargable" ?
                bookingDetailsData?.total_amount
                :
                parseFloat(bookingDetailsData?.security_deposit).toFixed(2)          
              }
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default PaymentStructure;
