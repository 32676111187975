import { getSingleDebitNoteList } from "../../../actions/FinanceActions/BillingManagementActions/ManageDebitNoteAction";
import * as type from "../../../actions/index.types";

export function manageDebitNoteReducer(
  state = {
    loading: false,
    updateLoader: false,
    uploadLoader: false,
    manageDebitNoteCurrent: null,
    manageDebitNoteActionList: [],
    printDebitReceipt: null,
    singleDebitNoteList: [],
    editSingleDebitNoteDetail: [],
    adjustmentPaymentDetails: null,
    adjustmentPaymentSave: null,
    makePaymentDetails: null,

    manageDebitNotePrevious: null,
    manageDebitNoteDraft: null,
    printDebitInvoice: null,
    // DebitNoteActionsList: null,
    manageDebitNoteTarrifList: null,
    manageDebitNoteFlatWingsWise: null,
    loadingManageDebitNoteFlatWingsWise: false,
    loadingManageDebitNoteArrearDetails: false,
    manageDebitNoteArrearDetails: null,
    error: null,
  },
  action
) {
  switch (action.type) {
    case type.GET_DEBIT_NOTE_CURRENT:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.GET_DEBIT_NOTE_CURRENT_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        manageDebitNoteCurrent: action.payload,
      };
    case type.GET_DEBIT_NOTE_CURRENT_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loading: false,
        manageDebitNoteCurrent: null,
      };

    case type.GET_DEBIT_NOTE_ACTION_LIST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.GET_DEBIT_NOTE_ACTION_LIST_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        manageDebitNoteActionList: action.payload,
      };
    case type.GET_DEBIT_NOTE_ACTION_LIST_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loading: false,
        manageDebitNoteActionList: [],
      };

    case type.GET_DEBIT_NOTE_RECEIPT_PRINT:
      return {
        ...state,
        printLoader: true,
        error: null,
      };
    case type.GET_DEBIT_NOTE_RECEIPT_PRINT_SUCCESS:
      return {
        ...state,
        error: null,
        printLoader: false,
        printDebitReceipt: action.payload,
      };
    case type.GET_DEBIT_NOTE_RECEIPT_PRINT_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        printLoader: false,
        printDebitReceipt: null,
      };


    case type.GET_SINGLE_DEBIT_NOTE_LIST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.GET_SINGLE_DEBIT_NOTE_LIST_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        singleDebitNoteList: action.payload,
      };
    case type.GET_SINGLE_DEBIT_NOTE_LIST_SUCCESS:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loading: false,
        singleDebitNoteList: null,
      };

    case type.EDIT_SINGLE_DEBIT_NOTE_DETAILS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.EDIT_SINGLE_DEBIT_NOTE_DETAILS_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        editSingleDebitNoteDetail: action.payload,
      };
    case type.EDIT_SINGLE_DEBIT_NOTE_DETAILS_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loading: false,
        editSingleDebitNoteDetail: null,
      };

    case type.EDIT_SINGLE_DEBIT_NOTE_SAVE:
      return {
        ...state,
        updateLoader: true,
        error: null,
      };
    case type.EDIT_SINGLE_DEBIT_NOTE_SAVE_SUCCESS:
      return {
        ...state,
        error: null,
        updateLoader: false,
      };
    case type.EDIT_SINGLE_DEBIT_NOTE_SAVE_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        updateLoader: false,
      };


    case type.ADJUSTMENT_PAYMENT_DETAILS_DEBIT_NOTE:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.ADJUSTMENT_PAYMENT_DETAILS_DEBIT_NOTE_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        adjustmentPaymentDetails: action.payload,
      };
    case type.ADJUSTMENT_PAYMENT_DETAILS_DEBIT_NOTE_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loading: false,
        adjustmentPaymentDetails: null,
      };

    case type.ADJUSTMENT_PAYMENT_SAVE_DEBIT_NOTE:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.ADJUSTMENT_PAYMENT_SAVE_DEBIT_NOTE_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        adjustmentPaymentSave: action.payload,
      };
    case type.ADJUSTMENT_PAYMENT_SAVE_DEBIT_NOTE_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loading: false,
        adjustmentPaymentSave: null,
      };

    case type.MAKE_PAYMENT_DETAILS_DEBIT_NOTE:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.MAKE_PAYMENT_DETAILS_DEBIT_NOTE_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        makePaymentDetails: action.payload,
      };
    case type.MAKE_PAYMENT_DETAILS_DEBIT_NOTE_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loading: false,
        makePaymentDetails: null,
      };

    case type.MAKE_PAYMENT_SAVE_DEBIT_NOTE:
      return {
        ...state,
        updateLoader: true,
        error: null,
      };
    case type.MAKE_PAYMENT_SAVE_DEBIT_NOTE_SUCCESS:
      return {
        ...state,
        error: null,
        updateLoader: false,
        makePaymentDetails: action.payload,
      };
    case type.MAKE_PAYMENT_SAVE_DEBIT_NOTE_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        updateLoader: false,
        makePaymentDetails: null,
      };



    case type.UPLOAD_DEBIT_NOTE_CURRENT_EXCEL:
      return {
        ...state,
        detail: [],
        uploadLoader: true,
      };
    case type.UPLOAD_DEBIT_NOTE_CURRENT_EXCEL_SUCCESS:
      return {
        ...state,
        detail: action.payload,
        uploadLoader: false,
      };
    case type.UPLOAD_DEBIT_NOTE_CURRENT_EXCEL_FAILURE:
      return {
        ...state,
        detail: action.payload,
        uploadLoader: false,
      };

    case type.UPLOAD_DEBIT_NOTE_CURRENT_PAYMENT:
      return {
        ...state,
        uploadLoader: true,
      };
    case type.UPLOAD_DEBIT_NOTE_CURRENT_PAYMENT_SUCCESS:
      return {
        ...state,
        uploadLoader: false,
      };
    case type.UPLOAD_DEBIT_NOTE_CURRENT_PAYMENT_FAILURE:
      return {
        ...state,
        uploadLoader: false,
      };


    case type.GET_DEBIT_NOTE_PREVIOUS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.GET_DEBIT_NOTE_PREVIOUS_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        manageDebitNotePrevious: action.payload,
      };
    case type.GET_DEBIT_NOTE_PREVIOUS_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loading: false,
        manageDebitNotePrevious: null,
      };

    case type.GET_DEBIT_NOTE_DRAFT:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.GET_DEBIT_NOTE_DRAFT_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        manageDebitNoteDraft: action.payload,
      };
    case type.GET_DEBIT_NOTE_DRAFT_FAILURE:
      return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        loading: false,
        manageDebitNoteDraft: null,
      };



    // case type.SINGLE_DEBIT_NOTE_LIST:
    //   return {
    //     ...state,
    //     loading: true,
    //     error: null,
    //   };
    // case type.SINGLE_DEBIT_NOTE_LIST_SUCCESS:
    //   return {
    //     ...state,
    //     error: null,
    //     loading: false,
    //     singleDebitNote: action.payload,
    //   };
    // case type.SINGLE_DEBIT_NOTE_LIST_FAILURE:
    //   return {
    //     ...state,
    //     error: action?.message || "Somthing went wrong.",
    //     loading: false,
    //     singleDebitNote: null,
    //   };

    // case type.GET_DEBIT_NOTE_ACTIONS_LIST:
    //   return {
    //     ...state,
    //     loading: true,
    //     error: null,
    //   };
    // case type.GET_DEBIT_NOTE_ACTIONS_LIST_SUCCESS:
    //   return {
    //     ...state,
    //     error: null,
    //     loading: false,
    //     DebitNoteActionsList: action.payload,
    //   };
    // case type.GET_DEBIT_NOTE_ACTIONS_LIST_FAILURE:
    //   return {
    //     ...state,
    //     error: action?.message || "Somthing went wrong.",
    //     loading: false,
    //     DebitNoteActionsList: null,
    //   };

    case type.GET_DEBIT_NOTE_INVOICE_PRINT:
      return {
        ...state,
        printLoader: true
      };
    case type.GET_DEBIT_NOTE_INVOICE_PRINT_SUCCESS:
      return {
        ...state,
        printLoader: false,
        printDebitInvoice: action.payload,
      };
    case type.GET_DEBIT_NOTE_INVOICE_PRINT_FAILURE:
      return {
        ...state,
        printLoader: false,
        printDebitInvoice: null,
      };


    case type.GET_DEBIT_NOTE_TARRIF_LIST:
      return {
        ...state,
        loading: true
      };
    case type.GET_DEBIT_NOTE_TARRIF_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        manageDebitNoteTarrifList: action.payload,
      };
    case type.GET_DEBIT_NOTE_TARRIF_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        manageDebitNoteTarrifList: null,
      };

    case type.GET_DEBIT_NOTE_FLAT_WING_WISE_LIST:
      return {
        ...state,
        loadingManageDebitNoteFlatWingsWise: true
      };
    case type.GET_DEBIT_NOTE_FLAT_WING_WISE_LIST_SUCCESS:
      return {
        ...state,
        loadingManageDebitNoteFlatWingsWise: false,
        manageDebitNoteFlatWingsWise: action.payload,
      };
    case type.GET_DEBIT_NOTE_FLAT_WING_WISE_LIST_FAILURE:
      return {
        ...state,
        loadingManageDebitNoteFlatWingsWise: false,
        error: action?.message || "Somthing went wrong.",
        manageDebitNoteFlatWingsWise: null,
      };


    case type.GET_DEBIT_NOTE_ARREAR_DETAILS:
      return {
        ...state,
        loadingManageDebitNoteArrearDetails: true,
        manageDebitNoteArrearDetails: null
      };
    case type.GET_DEBIT_NOTE_ARREAR_DETAILS_SUCCESS:
      return {
        ...state,
        loadingManageDebitNoteArrearDetails: false,
        manageDebitNoteArrearDetails: action.payload,
      };
    case type.GET_DEBIT_NOTE_ARREAR_DETAILS_FAILURE:
      return {
        ...state,
        loadingManageDebitNoteArrearDetails: false,
        error: action?.message || "Somthing went wrong.",
        manageDebitNoteArrearDetails: null,
      };

    case type.ADD_DEBIT_NOTE:
      return {
        ...state,
        updateLoader: true,
        error: null,

      };
    case type.ADD_DEBIT_NOTE_SUCCESS:
      return {
        ...state,
        updateLoader: false,
        error: null,

      };
    case type.ADD_DEBIT_NOTE_FAILURE:
      return {
        ...state,
        updateLoader: false,
        error: action?.message || "Somthing went wrong.",
      };
    case type.ADD_MULTIPLE_DEBIT_NOTE:
      return {
        ...state,
        updateLoader: true,
        error: null,

      };
    case type.ADD_MULTIPLE_DEBIT_NOTE_SUCCESS:
      return {
        ...state,
        updateLoader: false,
        error: null,

      };
    case type.ADD_MULTIPLE_DEBIT_NOTE_FAILURE:
      return {
        ...state,
        updateLoader: false,
        error: action?.message || "Somthing went wrong.",
      };


    default:
      return state;
  }
}
