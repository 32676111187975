import { notify } from '../../../../utils/notification';
import apiEndpoints from '../../../api';
import axiosInstance2 from '../../../axiosInstance2'
import {logOut} from '../../../../utils/helpers/logOut'
import { errorMsg } from '../../../../consts/responseMessages';


export async function getInvoiceHeadettingReq(action) {
    const BODY={
        'pageid': action.data?.societyId,
        "typeid": action.data.typeId,
    }

    return axiosInstance2.post(apiEndpoints.GET_INVOICE_HEAD_SETTING_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if((errors.response?.data.success === true && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)){
                logOut()
            }else if (errors.response?.data.success === false) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}
export async function getGLAccountReq(action) {
    const BODY={
        'pageid': action.data?.societyId,
    }

    return axiosInstance2.post(apiEndpoints.GET_GL_ACCOUNT_LIST_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if((errors.response?.data.success === true && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)){
                logOut()
            }else if (errors.response?.data.success === false) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}


export async function updateInvoiceHeadettingReq(action) {
    const BODY ={
        "pageid": action.data.societyId,
        "typeid": action.data.typeId,
        "head_detail": action.data.headDetails || [],
        // "more_head": action.data.more_head || [],
        // "arrear_head": action.data.arrear_head || [],
    }
    return axiosInstance2.post(apiEndpoints.UPDATE_INVOICE_HEAD_SETTING_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if((errors.response?.data.success === true && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)){
                logOut()
            }else if (errors.response?.data.success === false) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function addMoreHeadsReq(action) {
    const BODY ={
        "pageid": action.data.societyId,
        "headname": action.data.headName ,
        "head_detail": action.data.headDetail ,
        "hsncode": action.data.hsnCode ,
        "gl_code": action.data.glCode ,
        "amount": action.data.amount,
        "formula": action.data.formula,
        "tenancy_period": action.data.tenancyPeriod,
        "type": action.data.type,
        "typeid": action.data.typeId
    }
    return axiosInstance2.post(apiEndpoints.ADD_MORE_HEAD_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if((errors.response?.data.success === true && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)){
                logOut()
            }else if (errors.response?.data.success === false) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}
export async function editMoreHeadsReq(action) {
    const BODY ={
        "pageid": action.data.societyId,
        "headname": action.data.headName ,
        "hsncode": action.data.hsnCode ,
        "gl_code": action.data.glCode ,
        "amount": action.data.amount,
        "formula": action.data.formula,
        "type": action.data.type,
        "headid" : action.data.headId,
        "head_detail" : action.data.headDetail,
        "tenancy_period": action.data.tenancyPeriod,
    }
    return axiosInstance2.post(apiEndpoints.EDIT_MORE_HEAD_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if((errors.response?.data.success === true && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)){
                logOut()
            }else if (errors.response?.data.success === false) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}
export async function deleteMoreHeadsReq(action) {
    const BODY ={
        "pageid": action.data.societyId,
        "headid" : action.data.headId
    }
    return axiosInstance2.post(apiEndpoints.DELETE_MORE_HEAD_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if((errors.response?.data.success === true && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)){
                logOut()
            }else if (errors.response?.data.success === false) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}
export async function getMoreHeadDetailsReq(action) {
    const BODY ={
        "pageid": action.data.societyId,
        "headid": action.data.headId ,
    }
    return axiosInstance2.post(apiEndpoints.GET_MORE_HEAD_DETAILS_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if((errors.response?.data.success === true && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)){
                logOut()
            }else if (errors.response?.data.success === false) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}
export async function deleteUtiltySlab(action) {
    const BODY ={
        "pageid": action.data.societyId,
        "headid": action.data.headId ,
        "slabid": action.data.slabId ,
    }
    return axiosInstance2.post(apiEndpoints.DELETE_UTILITY_SLAB_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if((errors.response?.data.success === true && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)){
                logOut()
            }else if (errors.response?.data.success === false) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}
export async function addUtiltySlab(action) {
    const BODY ={
        "pageid": action.data.societyId,
        "headid": action.data.headId ,
        "charge": action.data.charge ,
        "rangetype": action.data.rangeType ,
        "range1": action.data.range1 ,
        "range2": action.data.range2 ,
    }
    return axiosInstance2.post(apiEndpoints.ADD_UTILITY_SLAB_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if((errors.response?.data.success === true && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)){
                logOut()
            }else if (errors.response?.data.success === false) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}
export async function getInvoiceFunctionalSettingReq(action) {
    const BODY ={
        "pageid": action.data.societyId,
        "typeid" : action.data.typeId
    }
    return axiosInstance2.post(apiEndpoints.GET_INVOICE_FUNCTIONAL_SETTING_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if((errors.response?.data.success === true && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)){
                logOut()
            }else if (errors.response?.data.success === false) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}
export async function saveInvoiceFunctionalSettingReq(action) {
    const BODY ={
        "pageid": action.data.societyId,
        "typeid": action.data.typeId,
        ...action.data.invoiceSetting
    }
    return axiosInstance2.post(apiEndpoints.SAVE_INVOICE_FUNCTIONAL_SETTING_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if((errors.response?.data.success === true && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)){
                logOut()
            }else if (errors.response?.data.success === false) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}
export async function getInvoiceSeriesListReq(action) {
    const BODY ={
        "pageid": action.data.societyId,
        "typeid" : action.data.typeId
    }
    return axiosInstance2.post(apiEndpoints.GET_INVOICE_SERIES_LIST_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if((errors.response?.data.success === true && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)){
                logOut()
            }else if (errors.response?.data.success === false) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}
export async function getTaxableFormatListReq(action) {
    const BODY ={
        "pageid": action.data.societyId,
        "typeid" : action.data.typeId
    }
    return axiosInstance2.post(apiEndpoints.GET_TAXABLE_FORMAT_LIST_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if((errors.response?.data.success === true && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)){
                logOut()
            }else if (errors.response?.data.success === false) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}
export async function deleteInvoiceSeriesReq(action) {
    const BODY ={
        "pageid": action.data.societyId,
        "seriesid" : action.data.seriesId,
    }
    return axiosInstance2.post(apiEndpoints.DELETE_INVOICE_SERIES_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if((errors.response?.data.success === true && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)){
                logOut()
            }else if (errors.response?.data.success === false) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}
export async function addInvoiceSeriesReq(action) {
    const BODY ={
        "pageid": action.data.societyId,
        "typeid" : action.data.typeId,
        "invoice_type" : action.data.invoiceType,
        "to_date" : action.data.toDate,
        "from_date" : action.data.fromDate,
        "start_no" : action.data.startNumber,
        "prefix" : action.data.prefix,
        "suffix" : action.data.suffix,
        "iszeropre" : action.data.isZero,
        "digit_number" : action.data.numDigit,
    }
    return axiosInstance2.post(apiEndpoints.ADD_INVOICE_SERIES_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if((errors.response?.data.success === true && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)){
                logOut()
            }else if (errors.response?.data.success === false) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}
export async function deleteTaxableMainHeadReq(action) {
    const BODY ={
        "pageid": action.data.societyId,
        "headid" : action.data.headId,
        
    }
    return axiosInstance2.post(apiEndpoints.DELETE_TAXABLE_MAIN_HEAD_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if((errors.response?.data.success === true && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)){
                logOut()
            }else if (errors.response?.data.success === false) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}
export async function addTaxableMainHeadReq(action) {
    const BODY ={
        "pageid": action.data.societyId,
        "head_id" : action.data.headId,
        "head_label" : action.data.headLable,
        "head_type" : action.data.HedType,
        "subtotal_label" : action.data.subTotalLabel,
        "hide_head" : action.data.hideHead,
        "hide_subtotal" : action.data.showSubtotal,
        "typeid" : action.data.typeId
    }
    return axiosInstance2.post(apiEndpoints.ADD_TAXABLE_MAIN_HEAD_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if((errors.response?.data.success === true && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)){
                logOut()
            }else if (errors.response?.data.success === false) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}
export async function addTaxableSubHeadReq(action) {
    const BODY ={
        "pageid": action.data.societyId,
        "headid" : action.data.headId,
        "subid" : action.data.subId,
        "type" : action.data.type,
        "active" : action.data.active,
        "typeid" : action.data.typeId,
    }
    return axiosInstance2.post(apiEndpoints.ADD_TAXABLE_SUB_HEAD_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if((errors.response?.data.success === true && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)){
                logOut()
            }else if (errors.response?.data.success === false) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}
export async function actionTaxableSubHeadReq(action) {
    const BODY ={
        "pageid": action.data.societyId,
        "action" : action.data.action,
        "subid" : action.data.subId,
        "status" : action.data.status ? action.data.status : null,
    }
    return axiosInstance2.post(apiEndpoints.ACTION_TAXABLE_SUB_HEAD_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if((errors.response?.data.success === true && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)){
                logOut()
            }else if (errors.response?.data.success === false) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}
export async function changeOrderTaxableHeadReq(action) {
    const BODY ={
        "pageid": action.data.societyId,
        "head_type" : action.data.headType,
        "headids" : action.data.headIds,
    }
    return axiosInstance2.post(apiEndpoints.CHANGE_ORDER_TAXABLE_HEAD_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if((errors.response?.data.success === true && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)){
                logOut()
            }else if (errors.response?.data.success === false) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}
export async function getInvoiceTypeListReqs(action) {
    const BODY ={
        "pageid": action.data.societyId,
    }
    return axiosInstance2.post(apiEndpoints.GET_INVOICE_TYPE_LIST_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if((errors.response?.data.success === true && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)){
                logOut()
            }else if (errors.response?.data.success === false) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}
export async function addInvoiceTypeListReqs(action) {
    const BODY ={
        "pageid": action.data.societyId,
        "invoice_title" : action.data.invoiceTitle,
        "invoice_type" : action.data.invoiceType,
        "frequency": action.data.frequency
    }
    return axiosInstance2.post(apiEndpoints.ADD_INVOICE_TYPE_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if((errors.response?.data.success === true && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)){
                logOut()
            }else if (errors.response?.data.success === false) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}
export async function deleteInvoiceTypeListReqs(action) {
    const BODY ={
        "pageid": action.data.societyId,
        "type_id": action.data.typeId,
    }
    return axiosInstance2.post(apiEndpoints.DELETE_INVOICE_TYPE_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if((errors.response?.data.success === true && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)){
                logOut()
            }else if (errors.response?.data.success === false) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}
export async function getInvoiceHeadListReq(action) {
    const BODY ={
        "pageid": action.data.societyId,
        "typeid": action.data.typeId,
    }
    return axiosInstance2.post(apiEndpoints.GET_INVOICE_HEAD_LIST_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if((errors.response?.data.success === true && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)){
                logOut()
            }else if (errors.response?.data.success === false) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}
export async function getBankDetailListReq(action) {
    const BODY ={
        "pageid": action.data.societyId,
    }
    return axiosInstance2.post(apiEndpoints.GET_BANK_DETAIL_LIST_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if((errors.response?.data.success === true && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)){
                logOut()
            }else if (errors.response?.data.success === false) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}
export async function getCreditNoteSettingDetailsReq(action) {
    const BODY ={
        "pageid": action.data.societyId,
        "typeid": action.data.invoiceType,
    }
    return axiosInstance2.post(apiEndpoints.GET_CREDIT_NOTE_SETTINGS_DETAIL_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if((errors.response?.data.success === true && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)){
                logOut()
            }else if (errors.response?.data.success === false) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}
export async function memberLedgerListReq(action) {
    const BODY ={
        "id": action.data.societyId,
    }
    return axiosInstance2.post(apiEndpoints.GET_MEMBER_LEDGER_LIST_API, {...BODY})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if((errors.response?.data.success === true && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)){
                logOut()
            }else if (errors.response?.data.success === false) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}