import React, { useEffect } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import ListHeader from "../../../components/ListHeader/ListHeader";
import Layout from "../../Layout/Layout";
import Button from "../../../components/Button/Button";
import ButtonG from "../../../components/Button/ButtonG";
import { DropdownFM2 } from "../../../components/Dropdown/dropdown2";
import { DatePickerComponentFM } from "../../../components/DatePicker/DatePicker";
import Checkbox from "../../../components/Checkbox/Checkbox";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Form, Formik } from "formik";
import { getFlatDetails, getWingsAndFlatDetails } from "../../../redux/actions/SetupRecordsActions/BuildingActions/WingsAndFlatsAction";
import { receiptReportTypeOptions } from "../../../consts/DropDownOptions";
import { PRINT_MEMBER_BILL_DUE_DETAILS_ENDPOINT, PRINT_MEMBER_DUE_SUMMARY_ENDPOINT } from "../../../Routing/routes";
import { MemberDueSummaryValidation } from "../../../validationSchema/financeSchema/billingReportSchema";
import { TextInputFM } from "../../../components/InputFields/TextInput";
import moment from "moment";
import { getInvoiceTypeList } from "../../../redux/actions/SetupRecordsActions/BillingAndInvoiceSetupActions/InvoiceSettingAction";

const MemberBillDueReport = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const { wingsData, flatsData } = useSelector(state => state.wingsReducer)
  const { loginData } = useSelector(state => state.loginReducer)
  const { typeLists } = useSelector(state => state.invoiceSettingReducer);

  const handleCancelClick = () => {
    navigate(-1);
  };

  const handleSaveClick = (values, { resetForm }) => {
    let query = []
    values.reportType && query.push(["reportType", values.reportType])
    values.invoiceType && query.push(["invoiceType", values.invoiceType])
    values.wingId && query.push(["wingId", values.wingId])
    values.flatId && query.push(["flatId", values.flatId])
    values.flatArea && query.push(["flatArea", values.flatArea])
    values.memberName && query.push(["memberName", values.memberName])
    values.toDate && query.push(["toDate", values.toDate])
    values.age && query.push(["age", values.age])

    let searchParams = createSearchParams(query)
    window.open(`${PRINT_MEMBER_BILL_DUE_DETAILS_ENDPOINT}?${searchParams}`, "_blank")
    // linkRef?.current?.click()
    resetForm()
  }

  useEffect(() => {
    const societyId = loginData?.default_community?.community_id
    dispatch(getWingsAndFlatDetails({ societyId }))
    dispatch(getInvoiceTypeList({ societyId }))
  }, [])


  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2 px-3">
        <Formik
          initialValues={{
            reportType: '',
            invoiceType: '',
            wingId: '',
            flatId: '',
            flatArea: 'No',
            memberName: '',
            toDate: '',
            age: ''
          }}
          onSubmit={handleSaveClick}
          validationSchema={MemberDueSummaryValidation}
        >
          {
            ({ values, setFieldValue }) => {
              return (
                <Form>
                  <div className="border-b border-[#CCC]">
                    <ListHeader leftContent="Member Bill Due Details">
                      <div className="mx-0.5 border-l-2"></div>
                      <ButtonG
                        label="Cancel"
                        onClick={handleCancelClick}
                        type={"button"}
                      />
                      <Button label="Get" className="px-6" type={"submit"} />
                    </ListHeader>
                  </div>
                  <div className="w-1/2 pt-3">
                    <div className="flex justify-between">
                      <span className="text-sm mt-2">Report Type</span>
                      <DropdownFM2
                        options={receiptReportTypeOptions}
                        width="224px"
                        height="36px"
                        placeholder="Select Type"
                        onSelect={(selectedItem) => {
                          setFieldValue('reportType', selectedItem.value)
                        }}
                        name='reportType'
                        {...(values.reportType === '' && { value: null })}
                      />
                    </div>
                  </div>
                  <div className="text-sm flex">
                    <div className="flex justify-between w-1/2">
                      <span className="mt-2">Wing & Flat </span>
                      <div className="items-center flex flex-col">
                        <div className="flex gap-2">
                          <DropdownFM2
                            options={wingsData?.map((item) => ({
                              value: item?.wing_id,
                              label: item?.number
                            })) || []}
                            width={"108px"}
                            className="text-xs"
                            placeholder={"Wing"}
                            onSelect={(selectedItem) => {
                              setFieldValue(`wingId`, selectedItem.value)
                              setFieldValue(`flatId`, '')
                              dispatch(getFlatDetails({ societyId: loginData?.default_community?.community_id, wingId: selectedItem?.value }))
                            }}
                            height='38px'
                            name={`wingId`}
                            {...(values.wingId === '' && { value: null })}
                          />
                          <DropdownFM2
                            options={values.wingId && flatsData?.manage_flat_details?.map((item) => ({
                              value: item?.flat_id,
                              label: item?.flat_no,
                              memberName: item?.subscriber_name
                            })) || []}
                            width={"108px"}
                            className="text-xs"
                            placeholder={"Flat/Unit"}
                            onSelect={(selectedItem) => {
                              setFieldValue(`flatId`, selectedItem.value)
                              setFieldValue('memberName', selectedItem.memberName)
                            }}
                            height='38px'
                            name={`flatId`}
                            {...(values.flatId === '' && { value: null })}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="text-[#AAAAAA] font-bold text-xs ms-20 mt-2">
                      {/* — {values.flatId ? values.memberName : 'Resident name show here , once wing and flat selected.'} */}
                    </div>
                  </div>
                  <div className=" w-1/2">
                    <TextInputFM
                      label='Member Name'
                      placeholder="Enter"
                      className="w-56"
                      name='memberName'
                    />
                  </div>
                  <div className="w-1/2">
                    <div className="flex justify-between">
                      <span className="text-sm mt-2">Report Type</span>
                      <DropdownFM2
                        options={
                          typeLists
                            ? typeLists?.map(
                              (item) => ({
                                label: item?.invoice_title,
                                value: item?.id,
                              })
                            )
                            : []
                        }
                        width="224px"
                        height="36px"
                        placeholder="Invoice type"
                        onSelect={(selectedItem) => {
                          setFieldValue('invoiceType', selectedItem.value)
                        }}
                        name='invoiceType'
                        {...(values.invoiceType === '' && { value: null })}
                      />
                    </div>
                  </div>
                  <div className=" w-[60%] flex justify-between items-center my-1">
                    <span className="text-sm">Flat Area</span>
                    <div className="min-w-80 flex gap-3 justify-between">
                      <Checkbox
                        text="Yes"
                        className="text-sm "
                        initialchecked={values.flatArea === 'Yes'}
                        onChange={(e) => { setFieldValue('flatArea', e.target.checked ? 'Yes' : 'No') }}
                      />
                    </div>
                  </div>
                  <div className="w-1/2 pt-3">
                    <div className="flex justify-between">
                      <span className="text-sm mt-2">As of Date</span>
                      <DatePickerComponentFM
                        className="w-56 h-9 ml-4 justify-between"
                        onDateChange={(selectedDate) => {
                          setFieldValue('toDate', moment(selectedDate)?.format('YYYY-MM-DD'))
                        }}
                        name='toDate'
                        defaultValue={values.toDate ? new Date(values.toDate) : null}
                      />
                    </div>
                  </div>
                  <div className="w-1/2">
                    <TextInputFM
                      label="Age Greater Than"
                      className="w-56"
                      placeholder="Billing Cycles (1, 2, 3, …)"
                      name='age'
                    />
                  </div>
                </Form>
              )
            }
          }
        </Formik>
      </div>
    </Layout>
  );
};

export default MemberBillDueReport;
