import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Loader from "../../../../components/Loader/Loader";
// import { getChequeDepositSlipReport } from "../../../redux/actions/FinanceActions/BillingReportsAction";
import parse from "html-react-parser";
import { getReceiptReport } from "../../../../redux/actions/ReportActions/FinanceReportActions";
import moment from "moment";
import { validateDate } from "../../../../utils/helpers/universalFunctions";

const demoData = [
  {
    "Wing & Flat No.": "A Block - 101",
    "Member Name	": "Balubhai Maganbhai Rathod",
    "Receipt No.		": "TR - 0028 / 2023-24",
    "Payment Mode": "Cheque",
    "Chq/Tran Number	": "4234234	",
    "Chq/Tran Date	": "21-03-2024	",
    "Bank Name	": "Development Credit Bank Ltd.	",
    "Branch Name	": "tes",
    "Amount (Rs.)	": "20.00	",
    "Payment Date	": "21-03-2024	",
    Status: "Verified & Posted	",
    Reason: "test",
    "Payment Due/Excess": "174.00",
  },
];
const PrintReceiptReport = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [headCells, setHeadCells] = useState([]);

  const { loginData } = useSelector((state) => state.loginReducer);
  const { receiptReport, loading } = useSelector(
    (state) => state.financeReportReducer
  );

  const getTableData = () => {
    dispatch(
      getReceiptReport({
        societyId: loginData?.default_community?.community_id,
        page: searchParams.get("page") || 0,
        pageLimit: searchParams.get("pageLimit") || 10,
        memberName: searchParams.get("memberName") || "",
        searchBy: searchParams.get("searchBy") || "",
        wingId: searchParams.get("wingId") || "",
        flatId: searchParams.get("flatId") || "",
        gstNo: searchParams.get("gstNo") || "",
        fromDate: searchParams.get("startDate") || "",
        toDate: searchParams.get("endDate") || "",
        invoiceType: searchParams.get("invoiceType") || "",
        export: 0,
      })
    );
  };

  useEffect(() => {
    getTableData();

    const afterprint = (e) => {
      e.preventDefault();
    };

    window.addEventListener("afterprint", afterprint);

    return () => {
      window.removeEventListener("afterprint", afterprint);
    };
  }, []);

  useEffect(() => {
    if (!loading && receiptReport?.data?.length > 0) {
      setTimeout(function () {
        window.print();
      }, 2000);
    }
  }, [dispatch]);

  // useEffect(() => {
  //   if (receiptReport?.data?.length)
  //     setHeadCells(
  //       Object.keys(receiptReport.data[0]).map((item) => ({
  //         label: item,
  //         id: item.replaceAll(" ", "_"),
  //         sort: true,
  //         width: "130px",
  //       }))
  //     );
  // }, [receiptReport]);

  useEffect(() => {
    if (receiptReport?.data?.length)
      setHeadCells(
        Object.entries(receiptReport.data.slice(-1)[0])
          .filter((item) => item[1] !== 0)
          ?.map((item) => ({
            label: item[0],
            id: item[0].replaceAll(" ", "_"),
            sort: true,
            width: "130px",
          }))
      );
  }, [receiptReport]);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        // <>{parse(chequeDepositSlipReport)}</>
        <div className="m-1">
          <table className="table-auto mx-auto border-collapse border border-black break-all">
            <caption className="caption-top  mt-4 text-center">
              <h1
                style={{
                  textDecoration: "underline",
                  fontSize: "22px",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                {loginData?.default_community?.community_name}
              </h1>
              <div className="text-[11px] ">
                (REG No.{loginData?.default_community?.regn_no} Date{" "}
                {moment(
                  new Date(loginData?.default_community?.date_of_regn)
                )?.format("DD-MM-YYYY")}
                )
              </div>
              <div className="text-[13px] ">
                {loginData?.default_community?.office_address}
              </div>
              <div className="font-extrabold text-lg mb-3  text-start">
                Receipt Report From:{" "}
                {searchParams.get("startDate")
                  ? moment(new Date(searchParams.get("startDate")))?.format(
                      "DD-MM-YYYY"
                    )
                  : "-"}{" "}
                To:{" "}
                {searchParams.get("endDate")
                  ? moment(new Date(searchParams.get("endDate")))?.format(
                      "DD-MM-YYYY"
                    )
                  : "-"}
              </div>
            </caption>
            <thead>
              <tr>
                {headCells.length &&
                  headCells?.map((item) => (
                    <td
                      className="text-[11px] p-1 font-semibold border border-black"
                      key={item?.id}
                      style={{ width: item.width || "fit-content" }}
                    >
                      {item?.label}
                    </td>
                  ))}
              </tr>
            </thead>
            <tbody>
              {receiptReport?.data?.map((row, rowIndex) => (
                <React.Fragment key={rowIndex}>
                  {headCells.length && (
                    <tr className="text-[#222] text-[11px]">
                      {headCells?.map((item, headIndex) => {
                        return (
                          <td
                            className={`${
                              rowIndex + 1 == receiptReport?.data?.length
                                ? " !font-bold text-sm"
                                : ""
                            } p-1 border border-black align-top`}
                          >
                            {/* {(index + 1)?.toString()?.padStart(2, 0)} */}
                            {row[item?.label]}
                          </td>
                        );
                      })}
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
          {!receiptReport?.data?.length && !loading && (
            <div className="text-[11px] my-10 text-center italic">
              No Records
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default PrintReceiptReport;
