import React, { useEffect } from "react";
import ListHeader from "../../../components/ListHeader/ListHeader";
import Layout from "../../Layout/Layout";
import Button from "../../../components/Button/Button";
import ViewBookingsTable from "./ViewBookingsTable";
import Search from "../../../components/SearchBox/Search";
import Dropdown2 from "../../../components/Dropdown/dropdown2";
import { PaymentStatusOptions } from "../../../consts/DropDownOptions";
import RangeDatePicker from "../../../components/DatePicker/RangeDatePicker";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import {
  ALL_FACILITY_AUDIT_LOG_ENDPOINT,
  FACILITY_REPORT_ENDPOINT,
  VIEW_BOOKINGS_ENDPOINT,
} from "../../../Routing/routes";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  getBookingList,
  getBookingStatusList,
} from "../../../redux/actions/ClubActions/ViewBookingsAction";
import { getFacilityList } from "../../../redux/actions/ClubActions/BookAFacilityAction";
import ButtonG from "../../../components/Button/ButtonG";
import moment from "moment";
import { validateDate } from "../../../utils/helpers/universalFunctions";
import { getPermission } from "../../../utils/helpers/getPermissions";
import * as permissions from "../../../Routing/permissions";

export const headCells = [
  { id: "sNo", label: "Sr. No.", width: "50px" },
  { id: "invoiceNo", label: "Invoice No.", sort: true, width: "90px" },
  { id: "facility", label: "Facility", sort: true, width: "80px" },
  {
    id: "bookingFrequency",
    label: "Booking Frequency",
    sort: true,
    width: "110px",
  },
  { id: "ticket", label: "Ticket Count", sort: true, width: "70px" },
  { id: "days", label: "Days Count", sort: true, width: "70px" },
  { id: "flat", label: "Flat", sort: true, width: "100px" },
  { id: "date", label: "Date(From-To)", sort: true, width: "120px" },
  { id: "amount", label: "Amount", sort: true, width: "90px" },
  { id: "status", label: "Status", sort: true, width: "90px" },
  { id: "action", label: "Action", width: "70px" },
];

const ViewBookings = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loginData } = useSelector((state) => state.loginReducer);
  const { loading, bookingList, bookingStatusList } = useSelector(
    (state) => state.viewBookingReducer
  );
  const { facilityList } = useSelector((state) => state.bookAFacilityReducer);

  const handleNavigation = (path) => () => {
    navigate(path);
  };

  const handleSearch = (text) => {
    if (text && text !== searchParams.get("search")) {
      searchParams.delete("page");
      searchParams.delete("search");
      navigate({
        pathname: `${VIEW_BOOKINGS_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["search", text?.trim()],
        ])}`,
      });
    }
  };
  const handelSelectStatus = (selectedItem) => {
    searchParams.delete("page");

    if (selectedItem.value?.toString() !== searchParams.get("status")) {
      searchParams.delete("status");
      navigate({
        pathname: `${VIEW_BOOKINGS_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["status", selectedItem.value],
        ])}`,
      });
    }
  };

  const handelPaymentStatus = (selectedItem) => {
    searchParams.delete("page");

    if (selectedItem.value?.toString() !== searchParams.get("pstatus")) {
      searchParams.delete("pstatus");
      navigate({
        pathname: `${VIEW_BOOKINGS_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["pstatus", selectedItem.value],
        ])}`,
      });
    }
  };

  const handelSelectFacility = (selectedItem) => {
    searchParams.delete("page");

    if (selectedItem.value?.toString() !== searchParams.get("facilityId")) {
      searchParams.delete("facilityId");
      navigate({
        pathname: `${VIEW_BOOKINGS_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["facilityId", selectedItem.value],
        ])}`,
      });
    }
  };

  const handleStartDate = (selectedDate) => {
    if (
      moment(selectedDate).format("YYYY-MM-DD") !==
      searchParams.get("startDate")
    ) {
      searchParams.delete("page");
      searchParams.delete("pageLimit");
      searchParams.delete("startDate");
      navigate({
        pathname: `${VIEW_BOOKINGS_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["startDate", moment(selectedDate).format("YYYY-MM-DD")],
        ])}`,
      });
    }
  };

  const handleEndDate = (selectedDate) => {
    if (
      moment(selectedDate).format("YYYY-MM-DD") !== searchParams.get("endDate")
    ) {
      searchParams.delete("page");
      searchParams.delete("pageLimit");
      searchParams.delete("endDate");
      navigate({
        pathname: `${VIEW_BOOKINGS_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["endDate", moment(selectedDate).format("YYYY-MM-DD")],
        ])}`,
      });
    }
  };

  const handleClear = () => {
    navigate(VIEW_BOOKINGS_ENDPOINT);
  };

  const getTableData = () => {
    const data = {
      societyId: loginData?.default_community?.community_id,
      page: searchParams.get("page") || 1,
      searchText: searchParams.get("search") || "",
      pageLimit: searchParams.get("pageLimit") || 10,
      facilityId: searchParams.get("facilityId") || "",
      pstatus: searchParams.get("pstatus") || "",
      status: searchParams.get("status") || "",
      startDate: searchParams.get("startDate") || "",
      endDate: searchParams.get("endDate") || "",
      ...(!getPermission(loginData, [permissions.VIEW_BOOKING_PERMISSION]) &&
        getPermission(loginData, [permissions.VIEW_OWN_BOOKING_PERMISSION]) &&
        (loginData?.user_type === "User" ||
          loginData?.user_type === "Tenant") && {
          memberId:
            loginData?.default_community?.subscriber_id ||
            loginData?.default_community?.tenant_id,
        }),
    };
    dispatch(getBookingList(data));
  };

  useEffect(() => {
    dispatch(getBookingStatusList());
    dispatch(
      getFacilityList({ societyId: loginData?.default_community?.community_id })
    );
  }, []);

  useEffect(() => {
    getTableData();
  }, [searchParams]);

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <ListHeader leftContent="View Bookings">
          <Search
            height="8"
            placeholder={"Search by Invoice/Facility/Flat"}
            onclick={handleSearch}
            value={searchParams.get("search")}
            className={"w-56"}
          />
          <div className="border-r border-[#CCC]"></div>
          <Button
            label="Facility Report"
            onClick={handleNavigation(FACILITY_REPORT_ENDPOINT)}
          />
          <Button
            label="Audit Logs"
            onClick={handleNavigation(ALL_FACILITY_AUDIT_LOG_ENDPOINT)}
          />
        </ListHeader>
        <div className="flex items-center border-t border-[#CCC]">
          <div className="flex items-center gap-2 p-2">
            <div>Filter</div>
            <Dropdown2
              options={facilityList?.facilty_list?.map((item) => ({
                label: item.facilities_name,
                value: item.id,
              }))}
              placeholder="Select Facility"
              className="text-[11px] ms-4"
              width="192px"
              height="32px"
              onSelect={(selectedItem) => {
                handelSelectFacility(selectedItem);
              }}
              value={
                searchParams.get("facilityId") && {
                  value: searchParams.get("facilityId"),
                  label: facilityList?.facilty_list?.find(
                    (item) =>
                      item.id?.toString() === searchParams.get("facilityId")
                  )?.facilities_name,
                }
              }
            />

            <Dropdown2
              options={bookingStatusList?.map((item) => ({
                label: item.key,
                value: item.value,
              }))}
              placeholder="Select Status"
              className="text-[11px] ms-4"
              width="192px"
              height="32px"
              onSelect={(selectedItem) => {
                handelSelectStatus(selectedItem);
              }}
              value={
                searchParams.get("status") && {
                  value: searchParams.get("status"),
                  label: bookingStatusList?.find(
                    (item) => item.value === searchParams.get("status")
                  )?.key,
                }
              }
            />
            <Dropdown2
              options={PaymentStatusOptions}
              placeholder="Select Payment Status"
              className="text-[11px] ms-4"
              width="192px"
              height="32px"
              onSelect={(selectedItem) => {
                handelPaymentStatus(selectedItem);
              }}
              value={
                searchParams.get("pstatus") && {
                  value: searchParams.get("pstatus"),
                  label: PaymentStatusOptions?.find(
                    (item) => item.value === searchParams.get("pstatus")
                  )?.label,
                }
              }
            />
          </div>
          <RangeDatePicker
            className="w-44 h-8"
            defaultStartValue={
              searchParams.get("startDate")
                ? new Date(searchParams.get("startDate"))
                : ""
            }
            defaultEndValue={
              searchParams.get("endDate")
                ? new Date(searchParams.get("endDate"))
                : ""
            }
            onStartDateChange={handleStartDate}
            onEndDateChange={handleEndDate}
          />
          <ButtonG label="Clear" className="ms-3 h-8" onClick={handleClear} />
        </div>
        <div className="ml-1">
          <ViewBookingsTable
            loading={loading}
            data={bookingList?.booking_list?.map((item, index) => ({
              sNo: (
                (bookingList?.current_page - 1) *
                  (searchParams.get("pageLimit") || 10) +
                1 +
                index
              )
                ?.toString()
                ?.padStart(2, "0"),
              invoiceNo: item?.invoice_number,
              bookingId: item?.id,
              invoiceId: item?.invId,
              facility: item?.facilities_name || "-",
              bookingFrequency: item?.booking_frequency || "-",
              booking_day: item?.booking_day || "-",
              noOfDays: item?.no_of_day || "-",
              flat: item?.wing_flat || "-",
              fromDate: validateDate(item?.from_date)
                ? moment(item?.from_date).format("DD-MM-YYYY")
                : "",
              toDate: validateDate(item?.to_date)
                ? moment(item?.to_date).format("DD-MM-YYYY")
                : "",
              statusValue: item.status,
              status:
                bookingStatusList?.find((e) => e.value === item.status)?.key ||
                "-",
              noOfBooking: item?.no_of_booking || "-",
              amount: item?.total_amount || 0,
              amountPaid: item?.paid_ammount || 0,
              amountDue: parseInt(item?.total_amount) - item?.paid_ammount || 0,
              memberId: parseInt(item?.member_id),
            }))}
            columns={headCells}
            checkbox={false}
            pagination={bookingList?.booking_list?.length}
            totalCount={bookingList?.total_count}
            totalPages={bookingList?.total_pages}
            start={
              (bookingList?.current_page - 1) *
                (searchParams.get("pageLimit") || 10) +
              1
            }
            end={
              (bookingList?.current_page - 1) *
                (searchParams.get("pageLimit") || 10) +
              bookingList?.booking_list?.length
            }
            currentPage={parseInt(bookingList?.current_page)}
            rowsPerPage={searchParams.get("pageLimit") || 10}
            getTableData={getTableData}
          ></ViewBookingsTable>

          {!bookingList?.booking_list?.length && !loading && (
            <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
              No Records
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default ViewBookings;
