import { useSelector } from "react-redux";
import { getPermission } from "../utils/helpers/getPermissions";
import ListHeaderBack from "../components/ListHeader/ListHeaderBack";
import Layout from "../pages/Layout/Layout";
import { useNavigate } from "react-router-dom";

const PermissionOnComponent = ({ permissionPaths, component }) => {
  const navigate = useNavigate();
  const { loginData } = useSelector((state) => state.loginReducer);
  return getPermission(loginData, permissionPaths) ? (
    <>{component}</>
  ) : (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="border-b border-[#CCC]">
          <ListHeaderBack onClick={() => navigate(-1)}></ListHeaderBack>
        </div>
        <div className="pt-5 p-2 text-center font-bold">
          You don't have access
        </div>
      </div>
    </Layout>
  );
};

export default PermissionOnComponent;
