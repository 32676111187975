import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Checkbox from "@mui/material/Checkbox";
import { visuallyHidden } from "@mui/utils";
import "../../../../components/TableComponent/Table.css";

import {
  DeleteIcon,
  PaymentIcon,
  SendSMSIcon,
  PrinterIcon,
  SortIcon,
  ViewIcon,
  EditIcon,
  PaymentAdjustmentIcon,
} from "../../../../assets";
import styled from "@emotion/styled";
import ItemsPerPage from "../../../../components/ItemsPerPage/ItemsPerPage";
import Pagination from "../../../../components/Pagination/Pagination";
import {
  stableSort,
  getComparator,
} from "../../../../utils/helpers/universalFunctions";
import {
  Link,
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { CircularProgress, Collapse, IconButton, Tooltip } from "@mui/material";
import Loader from "../../../../components/Loader/Loader";
import {
  PRINT_DELETED_INVOICE_REPORT_ENDPOINT,
  RECEIPT_REPORT_ENDPOINT,
} from "../../../../Routing/routes";
import DeleteModal from "../../../../components/Modal/DeleteModal";
import BankSelectionModel from "../../../../pages/Finance/BillingManagement/ManageDebitNote/BankSelectionModal";
import SendSmsModal from "../../../../components/Modal/SendSmsModal";
import { useSelector } from "react-redux";
import Button from "../../../../components/Button/Button";
import { societyStaffTypeOptions } from "../../../../consts/DropDownOptions";
import Dropdown2 from "../../../../components/Dropdown/dropdown2";
import { useDispatch } from "react-redux";
import { notify } from "../../../../utils/notification";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const TableRowStyled = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: #ffffff;
  }
  &:nth-of-type(even) {
    background-color: #f4f5f8;
  }
  &:nth-of-type(odd):hover {
    background-color: #ffffff;
  }
  &:nth-of-type(even):hover {
    background-color: #f4f5f8;
  }
`;

const TableCellStyled = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 600,
    zIndex: 0,
    color: "#AAAAAA",
    fontSize: "13px",
    padding: "12px 8px 12px 0",
    wordBreak: "break-word",
  },
  [`&.${tableCellClasses.body}`]: {
    borderBottom: "none",
    fontSize: "13px",
    padding: "12px 8px 12px 0",
    wordBreak: "break-word",
    cursor: "pointer",
  },
}));

const InterestCalculationReportTable = ({
  loading,
  data,
  columns,
  checkbox = true,
  pagination = false,

  //props are required if pagination is true
  totalCount,
  totalPages,
  start,
  end,
  currentPage,
  rowsPerPage,
  getTableData = false,
  selected = [],
  setSelected = () => {},
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  // const [selected, setSelected] = useState([]);
  const [receiptId, setReceiptId] = useState([]);
  const [action, setAction] = useState("");
  const [open, setOpen] = useState(-1);

  //   const { updateLoader, manageDebitNoteActionList } = useSelector(
  //     (state) => state.manageDebitNoteReducer
  //   );
  const { loginData } = useSelector((state) => state.loginReducer);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    const newSelected = event.target.checked ? data.map((item) => item.id) : [];
    setSelected(newSelected);
  };

  const handleClick = (event, id) => {
    const newSelected = selected.includes(id)
      ? selected.filter((selectedId) => selectedId !== id)
      : [...selected, id];
    setSelected(newSelected);
  };

  const handleChangePage = (newPage) => {
    searchParams.delete("page");
    navigate({
      pathname: `${RECEIPT_REPORT_ENDPOINT}`,
      search: `?${createSearchParams([
        ...searchParams.entries(),
        ["page", newPage],
      ])}`,
    });
  };

  const handleChangeRowsPerPage = (value) => {
    if (rowsPerPage !== value) {
      searchParams.delete("pageLimit");
      navigate({
        pathname: `${RECEIPT_REPORT_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["pageLimit", value],
        ])}`,
      });
    }
  };

  const isSelected = (id) => selected.includes(id);

  const [bankModel, setBankSelectionModel] = useState(false);

  //   useEffect(() => {
  //     setSelected([]);
  //   }, [searchParams]);
  //   useEffect(() => {
  //     dispatch(getDebitNoteActionList());
  //   }, [dispatch]);

  return (
    <Box sx={{ width: "100%", fontSize: "13px" }} className="table-component">
      {/* {!!selected?.length && (
        <div className="flex justify-end items-center border-t border-[#CCC] gap-2 p-2">
          <div className="flex items-center gap-2">
            <Dropdown2
              options={Object.entries(
                manageDebitNoteActionList?.data?.current
              ).map((item) => ({
                label: item[0],
                value: item[0],
              }))}
              placeholder="Choose Action"
              className="text-[11px] ms-4"
              width="160px"
              height="32px"
              onSelect={(selectedItem) => {
                setAction(selectedItem.value);
              }}
            />
            <Button
              label={
                <span className="w-8 inline-block">
                  {updateLoader ? (
                    <CircularProgress sx={{ color: "white" }} size={17} />
                  ) : (
                    "Apply"
                  )}
                </span>
              }
              className="font-semibold px-4 h-8"
              type="button"
              onClick={handleApply}
            />
          </div>
        </div>
      )} */}
      <TableContainer
        className="border-t border-[#CCC]"
        sx={{ minHeight: data?.length ? "500px" : "none" }}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead style={{textWrap: "nowrap"}}>
            <TableRow>
              {!!checkbox && (
                <TableCellStyled
                  size="small"
                  sx={{ pl: "0 !important", pr: 2, width: 36 }}
                >
                  <Checkbox
                    size="small"
                    indeterminate={false}
                    checked={
                      data?.length > 0 && selected?.length === data?.length
                    }
                    onChange={handleSelectAllClick}
                    inputProps={{
                      "aria-label": "select all",
                    }}
                    sx={{
                      p: 0,
                      color: "#AAAAA",
                      "&.Mui-checked": {
                        color: "#CF001C",
                      },
                    }}
                    disabled={loading || !data}
                  />
                </TableCellStyled>
              )}
              {<TableCellStyled size="small"></TableCellStyled>}
              {columns?.length
                ? columns?.map((column) => (
                    <TableCellStyled
                      size="small"
                      key={column.id + "heading"}
                      align={column.numeric ? "right" : "left"}
                      sortDirection={orderBy === column.id ? order : false}
                      sx={{
                        width: column.width || "fit-content",
                        minWidth: column.width || "fit-content",
                      }}
                    >
                      <TableSortLabel
                        active={orderBy === column.id}
                        direction={orderBy === column.id ? order : "asc"}
                        onClick={() => {
                          if (column.sort) {
                            handleRequestSort(column.id);
                          }
                        }}
                        IconComponent={SortIcon}
                        sx={{
                          ".MuiTableSortLabel-icon": {
                            opacity: "1 !important",
                          },
                          svg: {
                            flexShrink: 0,
                          },
                        }}
                        hideSortIcon={!column.sort}
                      >
                        {column.label}
                        {orderBy === column.id ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === "desc"
                              ? "sorted descending"
                              : "sorted ascending"}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    </TableCellStyled>
                  ))
                : ""}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCellStyled
                  sx={{ border: "none" }}
                  colSpan={columns?.length + 1}
                >
                  <Loader />
                </TableCellStyled>
              </TableRow>
            ) : (
              <>
                {!!data?.length &&
                  stableSort(data, getComparator(order, orderBy))?.map(
                    (row, rowIndex) => {
                      const isRowSelected = isSelected(row?.id);

                      return (
                        <>
                          <TableRowStyled
                            key={row?.id}
                            role="checkbox"
                            aria-checked={isRowSelected}
                            tabIndex={-1}
                            selected={isRowSelected}
                          >
                            {!!checkbox && (
                              <TableCellStyled
                                size="small"
                                sx={{ pl: "0 !important", zIndex: 0 }}
                                onClick={(event) => handleClick(event, row?.id)}
                              >
                                <Checkbox
                                  checked={selected?.includes(row?.id)}
                                  onChange={(e) => handleClick(e, row?.id)}
                                  size="small"
                                  color="primary"
                                  inputProps={{
                                    "aria-labelledby": `generic-table-checkbox-${row?.id}`,
                                  }}
                                  sx={{
                                    p: 0,
                                    color: "#AAAAA",
                                    "&.Mui-checked": {
                                      color: "#CF001C",
                                    },
                                  }}
                                />
                              </TableCellStyled>
                            )}
                            {
                              <TableCellStyled>
                                <IconButton
                                  aria-label="expand row"
                                  size="small"
                                  onClick={() =>
                                    setOpen(rowIndex == open ? -1 : rowIndex)
                                  }
                                >
                                  {open == rowIndex ? (
                                    <KeyboardArrowUpIcon />
                                  ) : (
                                    <KeyboardArrowDownIcon />
                                  )}
                                </IconButton>
                              </TableCellStyled>
                            }
                            {data?.length &&
                              columns.slice(0, -1).map((item, index) => {
                                return item?.label != "Amount" ? (
                                  <TableCellStyled
                                    size="small"
                                    align="left"
                                    // onClick={() => setOpen(rowIndex)}
                                    // onClick={(event) =>
                                    //   handleClick(event, item.label)
                                    // }
                                  >
                                    {Object.entries(row)[index][1]}
                                  </TableCellStyled>
                                ) : (
                                  <TableCellStyled size="small" align="left">
                                    <div>
                                      <b>Amount: </b> {row?.amount?.amount}
                                    </div>
                                    <div>
                                      <b>Paid: </b>{" "}
                                      {row?.amount?.paid
                                        ? row?.amount?.paid
                                        : (0).toFixed(2)}
                                    </div>
                                    <div>
                                      <b>Due: </b>{" "}
                                      {row?.amount?.due
                                        ? row?.amount?.amountDue
                                        : (0).toFixed(2)}
                                    </div>
                                  </TableCellStyled>
                                );
                              })}
                            <TableCellStyled
                              size="small"
                              align="left"
                              // onClick={(event) => handleClick(event, item.label)}
                            >
                              <Box
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Tooltip title="Print" arrow>
                                  <Link
                                    target="_blank"
                                    to={`${PRINT_DELETED_INVOICE_REPORT_ENDPOINT}/${row?.id}`}
                                  >
                                    <PrinterIcon className="fill-[#555] hover:fill-red-650 me-1.5" />
                                  </Link>
                                </Tooltip>
                              </Box>
                            </TableCellStyled>
                          </TableRowStyled>
                          <TableRow>
                            <TableCell
                              style={{ paddingBottom: 0, paddingTop: 0 }}
                              colSpan={columns.length + 1}
                            >
                              <Collapse
                                in={open == rowIndex}
                                timeout="auto"
                                unmountOnExit
                              >
                                <Box sx={{ margin: 1 }}>
                                  <h1>History</h1>
                                  <Table size="small" aria-label="purchases">
                                    {/* <TableHead>
                                      <TableRow>
                                        <TableCell>Date</TableCell>
                                        <TableCell>Customer</TableCell>
                                        <TableCell align="right">
                                          Amount
                                        </TableCell>
                                        <TableCell align="right">
                                          Total price ($)
                                        </TableCell>
                                      </TableRow>
                                    </TableHead> */}
                                    <TableBody>
                                      {console.log(
                                        columns?.slice(0, -1),
                                        "fadfads"
                                      )}
                                      {data?.length > 0 &&
                                        data?.map((newItem, newIndex) => {
                                          return (
                                            <TableRow key={newIndex}>
                                              {columns
                                                ?.slice(0, -1)
                                                ?.map((item, indexx) => {
                                                  return (
                                                    <TableCell
                                                      size="small"
                                                      align="left"
                                                      // onClick={(event) =>
                                                      //   handleClick(event, item.label)
                                                      // }
                                                    >
                                                      {item?.label != "Amount"
                                                        ? Object.entries(
                                                            newItem
                                                          )?.[indexx]?.[1]
                                                        : "1232"}
                                                    </TableCell>
                                                  );
                                                })}
                                            </TableRow>
                                          );
                                        })}
                                    </TableBody>
                                  </Table>
                                </Box>
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        </>
                      );
                    }
                  )}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <BankSelectionModel
        isOpen={bankModel}
        onClose={() => {
          setBankSelectionModel(false);
        }}
        receiptId={receiptId}
        //onSubmit={}
      />
      {!!pagination && data && (
        <div className="flex flex-row justify-between mt-3 pt-1 border-t border-[#ccc] text-[14px]">
          <ItemsPerPage
            rowsPerPage={rowsPerPage}
            onChange={handleChangeRowsPerPage}
          />
          <Pagination
            totalCount={totalCount}
            totalPages={totalPages}
            start={start}
            end={end}
            currentPage={currentPage}
            onChange={handleChangePage}
          />
        </div>
      )}
    </Box>
  );
};

InterestCalculationReportTable.propTypes = {
  columns: PropTypes.array.isRequired,
  defaultSortField: PropTypes.string,
  rowsPerPageOptions: PropTypes.array,
};

export default InterestCalculationReportTable;
