import React, { useEffect, useState } from "react";
import Layout from "../../../Layout/Layout";
import RangeDatePicker from "../../../../components/DatePicker/RangeDatePicker";
import FilterTextField from "../../../../components/InputFields/FilterTextFields";
import Dropdown2 from "../../../../components/Dropdown/dropdown2";
import { TextInput } from "../../../../components/InputFields/TextInput";
import Search from "../../../../components/SearchBox/Search";
import Button from "../../../../components/Button/Button";
import ButtonIco from "../../../../components/Button/ButtonPrint";
import { DownloadExt, UploadEx, PrinterIcon } from "../../../../assets";
import ListHeader from "../../../../components/ListHeader/ListHeader";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import {
  INTEREST_CALCULATION_REPORT_ENDPOINT,
  PRINT_INTEREST_CALCULATION_REPORT_ENDPOINT,
} from "../../../../Routing/routes";
import ManageDebitCurrentTable from "../../../../components/TableComponent/ManageDebitCurrentTable";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import moment from "moment";
import {
  downloadExcel,
  downloadFileFromURL,
  exportToCSV,
} from "../../../../utils/helpers/downloadMedia";

import ButtonG from "../../../../components/Button/ButtonG";
import {
  getFlatDetails,
  getWingsAndFlatDetails,
} from "../../../../redux/actions/SetupRecordsActions/BuildingActions/WingsAndFlatsAction";
import {
  downloadReceiptReport,
  getInterestCalculationReport,
} from "../../../../redux/actions/ReportActions/FinanceReportActions";
import { validateDate } from "../../../../utils/helpers/universalFunctions";
import InterestCalculationReportTable from "./InterestCalculationReportTable";

const headCells = [
  { id: "sNo", label: "Sr. No.", sort: false, width: "40px" },
  { id: "invoiceNo", label: "Invoice No.", sort: true, width: "160px" },
  { id: "memberDetails", label: "Member Details	", sort: true, width: "150px" },
  { id: "invoiceDate", label: "Invoice Date	", sort: true, width: "100px" },
  {
    id: "generationDate",
    label: "Generation Date",
    sort: true,
    width: "130px",
  },
  { id: "amount", label: "Amount", sort: false, width: "120px" },
  { id: "status", label: "Status", sort: true, width: "100px" },
  { id: "userId", label: "User Ids", sort: true, width: "100px" },
  { id: "userType", label: "User Type", sort: true, width: "100px" },

  {
    id: "action",
    label: "Action",
    sort: false,
    width: "100px",
  },
];
const invoice = [
  { label: "Debit Note", value: "Debit Note" },
  { label: "Supplementary", value: "Supplementary" },
];
const searchBy = [
  { label: "Invoice Date", value: "invoice_date" },
  { label: "Generation Date", value: "date" },
];
const debitorType = [
  { label: "All", value: "All" },
  { label: "Member", value: "Member" },
  { label: "Vendor", value: "Vendor" },
  { label: "Tenant", value: "Tenant" },
];
const gstNo = [
  { label: "B2B", value: "With GST No" },
  { label: "B2C", value: "WithOut GST No" },
];
const status = [
  { label: "All", value: "All" },
  { label: "Verified", value: "verified" },
  { label: "Unpaid", value: "pending" },
  { label: "Unverified", value: "unverified" },
  { label: "Rejected", value: "rejected" },
  { label: "Carried Forward", value: "carried_forward_status" },
  { label: "Carried Forward In Invoice", value: "carried_forward_in_invoice" },
  { label: "Adjust In Invoice", value: "adjust_in_invoice" },
  { label: "Excess Paid", value: "excess_paid" },
  { label: "Dues", value: "dues" },
];

const InterestCalculationReport = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { loginData } = useSelector((state) => state.loginReducer);

  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [selected, setSelected] = useState([]);
  const [file, setFile] = useState(null);
  // const [headCells, setHeadCells] = useState([]);
  const [defaultFromValue, setDefaultFromValue] = useState("");
  const [defaultToValue, setDefaultToValue] = useState("");

  const { interestCalculationtReport, loading } = useSelector(
    (state) => state.financeReportReducer
  );
  const { wingsData, flatsData } = useSelector((state) => state.wingsReducer);

  const handleNavigation = (path) => () => {
    navigate(path);
  };

  const handleOpenUploadModal = () => {
    setIsUploadModalOpen(true);
  };
  const handleCloseUploadModal = () => {
    setIsUploadModalOpen(false);
  };

  const handleDownloadExcel = () => {
    dispatch(
      downloadReceiptReport({
        societyId: loginData?.default_community?.community_id,
        page: searchParams.get("page") || 0,
        pageLimit: searchParams.get("pageLimit") || 10,
        memberName: searchParams.get("subscriber_name") || "",
        billNo: searchParams.get("billNo") || "",
        searchBy: searchParams.get("searchBy") || "",
        wingId: searchParams.get("wingId") || "",
        flatId: searchParams.get("flatId") || "",
        gstNo: searchParams.get("gstNo") || "",
        fromDate: searchParams.get("startDate") || "",
        toDate: searchParams.get("endDate") || "",
        export: 1,
        onSuccessCallback: (url) => {
          downloadFileFromURL({ url, fileName: "sample.csv" });
        },
      })
    );
  };
  const handlePrintData = () => {
    navigate({
      pathname: `${PRINT_INTEREST_CALCULATION_REPORT_ENDPOINT}`,
      search: `?${createSearchParams([...searchParams.entries()])}`,
    });
  };

  const handleInvoice = (selectedItem) => {
    searchParams.delete("page");
    searchParams.delete("pageLimit");
    searchParams.delete("invoice");
    navigate({
      pathname: `${INTEREST_CALCULATION_REPORT_ENDPOINT}`,
      search: `?${createSearchParams([
        ...searchParams.entries(),
        ["invoice", selectedItem.value],
      ])}`,
    });
  };
  const handleSelectGst = (selectedItem) => {
    searchParams.delete("page");
    searchParams.delete("pageLimit");
    searchParams.delete("gstNo");
    navigate({
      pathname: `${INTEREST_CALCULATION_REPORT_ENDPOINT}`,
      search: `?${createSearchParams([
        ...searchParams.entries(),
        ["gstNo", selectedItem.value],
      ])}`,
    });
  };
  const handleSearch = (text) => {
    if (text && text !== searchParams.get("billNo")) {
      searchParams.delete("page");
      searchParams.delete("pageLimit");
      searchParams.delete("billNo");
      navigate({
        pathname: `${INTEREST_CALCULATION_REPORT_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["billNo", text],
        ])}`,
      });
    } else if (!text) {
      searchParams.delete("billNo");
      navigate({
        pathname: `${INTEREST_CALCULATION_REPORT_ENDPOINT}`,
        search: `?${createSearchParams([...searchParams.entries()])}`,
      });
    }
  };
  const handleSearch2 = (text) => {
    if (text && text !== searchParams.get("subscriber_name")) {
      searchParams.delete("page");
      searchParams.delete("pageLimit");
      searchParams.delete("subscriber_name");
      navigate({
        pathname: `${INTEREST_CALCULATION_REPORT_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["subscriber_name", text],
        ])}`,
      });
    } else if (!text) {
      searchParams.delete("subscriber_name");
      navigate({
        pathname: `${INTEREST_CALCULATION_REPORT_ENDPOINT}`,
        search: `?${createSearchParams([...searchParams.entries()])}`,
      });
    }
  };
  const handleSearchBy = (text) => {
    if (text && text !== searchParams.get("searchBy")) {
      searchParams.delete("page");
      searchParams.delete("pageLimit");
      searchParams.delete("searchBy");
      navigate({
        pathname: `${INTEREST_CALCULATION_REPORT_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["searchBy", text],
        ])}`,
      });
    }
  };

  const handleSelectDebitorType = (selectedItem) => {
    searchParams.delete("page");
    searchParams.delete("status");
    searchParams.delete("debitor_type");

    navigate({
      pathname: `${INTEREST_CALCULATION_REPORT_ENDPOINT}`,
      search: `?${createSearchParams([
        ...searchParams.entries(),
        ["debitor_type", selectedItem.value],
      ])}`,
    });
  };
  const handleAmountChange = (clear = false) => {
    if (clear) {
      searchParams.delete("to_amount");
      searchParams.delete("from_amount");
      setDefaultFromValue("");
      setDefaultToValue("");
      navigate({
        pathname: `${INTEREST_CALCULATION_REPORT_ENDPOINT}`,
        search: `?${createSearchParams([...searchParams.entries()])}`,
      });
    } else {
      searchParams.delete("to_amount");
      searchParams.delete("from_amount");
      navigate({
        pathname: `${INTEREST_CALCULATION_REPORT_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["from_amount", defaultFromValue],
          ["to_amount", defaultToValue],
        ])}`,
      });
    }
  };
  const handleSelectWing = (selectedItem) => {
    if (selectedItem.value?.toString() !== searchParams.get("wingId")) {
      dispatch(
        getFlatDetails({
          societyId: loginData?.default_community?.community_id,
          wingId: selectedItem?.value,
        })
      );
      searchParams.delete("page");
      searchParams.delete("pageLimit");
      searchParams.delete("wingId");
      searchParams.delete("flatId");
      navigate({
        pathname: `${INTEREST_CALCULATION_REPORT_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["wingId", selectedItem.value],
        ])}`,
      });
    }
  };

  const handleSelectFlat = (selectedItem) => {
    if (selectedItem.value?.toString() !== searchParams.get("flatId")) {
      searchParams.delete("page");
      searchParams.delete("pageLimit");
      searchParams.delete("flatId");
      navigate({
        pathname: `${INTEREST_CALCULATION_REPORT_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["flatId", selectedItem.value],
        ])}`,
      });
    }
  };

  const handleStartDate = (selectedDate) => {
    if (
      moment(selectedDate).format("YYYY-MM-DD") !==
      searchParams.get("startDate")
    ) {
      searchParams.delete("page");
      searchParams.delete("pageLimit");
      searchParams.delete("startDate");
      navigate({
        pathname: `${INTEREST_CALCULATION_REPORT_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["startDate", moment(selectedDate).format("YYYY-MM-DD")],
        ])}`,
      });
    }
  };

  const handleEndDate = (selectedDate) => {
    if (
      moment(selectedDate).format("YYYY-MM-DD") !== searchParams.get("endDate")
    ) {
      searchParams.delete("page");
      searchParams.delete("pageLimit");
      searchParams.delete("endDate");
      navigate({
        pathname: `${INTEREST_CALCULATION_REPORT_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["endDate", moment(selectedDate).format("YYYY-MM-DD")],
        ])}`,
      });
    }
  };

  const handleClear = () => {
    setDefaultFromValue("");
    setDefaultToValue("");
    navigate(`${INTEREST_CALCULATION_REPORT_ENDPOINT}?searchBy=invoice_date`);
  };

  useEffect(() => {
    let societyId = loginData?.default_community?.community_id;
    dispatch(getWingsAndFlatDetails({ societyId }));
    if (searchParams.get("wingId")) {
      dispatch(
        getFlatDetails({ societyId, wingId: searchParams.get("wingId") })
      );
    }

    if (!searchParams.get("searchBy") || !searchParams.get("invoice")) {
      navigate({
        pathname: `${INTEREST_CALCULATION_REPORT_ENDPOINT}`,
        search: `?${createSearchParams([["searchBy", "invoice_date"]])}`,
      });
    }
    // setHeadCells([]);
    setDefaultFromValue("");
    setDefaultToValue("");
  }, []);

  const getTableData = () => {
    dispatch(
      getInterestCalculationReport({
        societyId: loginData?.default_community?.community_id,
        page: searchParams.get("page") || 0,
        pageLimit: searchParams.get("pageLimit") || 10,
        memberName: searchParams.get("subscriber_name") || "",
        billNo: searchParams.get("billNo") || "",
        searchBy: searchParams.get("searchBy") || "",
        wingId: searchParams.get("wingId") || "",
        flatId: searchParams.get("flatId") || "",
        gstNo: searchParams.get("gstNo") || "",
        fromDate: searchParams.get("startDate") || "",
        toDate: searchParams.get("endDate") || "",
        export: 0,
      })
    );
  };
  useEffect(() => {
    getTableData();
  }, [searchParams]);

  // useEffect(() => {
  //   if (interestCalculationtReport?.data?.length)
  //     setHeadCells(
  //       Object.keys(interestCalculationtReport.data[0]).map((item) => ({
  //         label: item,
  //         id: item.replaceAll(" ", "_"),
  //         sort: true,
  //         width: "130px",
  //       }))
  //     );
  // }, [interestCalculationtReport]);

  // useEffect(() => {
  //   setHeadCells(
  //     Object.keys(demoData[0]).map((item) => ({
  //       label: item,
  //       id: item.replaceAll(" ", "_"),
  //       sort: true,
  //       width: "130px",
  //     }))
  //   );
  // }, [demoData]);

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="border-b border-[#CCC]">
          <ListHeader leftContent="Interest Calculation Report">
            <Search
              height="8"
              onclick={handleSearch}
              value={searchParams.get("billNo")}
              placeholder="Enter Invoice No"
            />
            <div className="border-r border-[#CCC]"></div>
            <div className="flex text-sm gap-3">
              <button
                type="button"
                onClick={handlePrintData}
                className="flex text-red-650 border border-red-650 bg-white text-xs px-2 font-semibold items-center whitespace-nowrap rounded-lg"
              >
                <PrinterIcon className="mr-2 fill-red-650" /> Print
              </button>
              <ButtonIco
                type="button"
                onClick={handleDownloadExcel}
                icon={DownloadExt}
                children="Download Excel"
              />
              {/* <div className="border-r border-[#CCC]"></div> */}
            </div>
          </ListHeader>
        </div>

        <div className="p-1 flex flex-row justify-between items-center gap-4 mt-1">
          <Search
            height="8"
            onclick={handleSearch2}
            value={searchParams.get("subscriber_name")}
            placeholder="Search Vendor/Member"
          />
          {/* <div className="flex justify-between items-center gap-3">
            <div className="text-sm">Bill Type</div>
            <Dropdown2
              options={invoice}
              placeholder="Invoice type"
              className="text-[11px] ms-4"
              width="172px"
              height="32px"
              // onSelect={(selectedItem) => {
              //   handelSelectInvoiceType(selectedItem);
              // }}
              value={
                searchParams.get("typeid") && {
                  value: searchParams.get("typeid"),
                  label: invoice
                    ? invoice?.find(
                        (item) =>
                          item?.id === parseInt(searchParams.get("typeid"))
                      )?.invoice_title
                    : "",
                }
              }
            />
          </div> */}

          <div className="flex justify-between items-center gap-3">
            <div className="text-sm">Search By</div>
            <Dropdown2
              options={searchBy}
              placeholder="Invoice Date"
              className="text-[11px] ms-4"
              width="160px"
              height="32px"
              onSelect={(selectedItem) => {
                handleSearchBy(selectedItem.value);
              }}
              value={
                searchParams.get("searchBy") && {
                  value: searchParams.get("searchBy"),
                  label: searchBy?.find(
                    (item) => item.value == searchParams.get("searchBy")
                  )?.label,
                }
              }
            />
            {/* <div className="border-r border-[#CCC] h-full"></div>

           
            <div className="border-r border-[#CCC] h-full"></div> */}

            <div className="flex justify-between gap-3 items-center">
              <RangeDatePicker
                className={"w-[190px] h-8"}
                defaultStartValue={
                  searchParams.get("startDate")
                    ? new Date(searchParams.get("startDate"))
                    : ""
                }
                defaultEndValue={
                  searchParams.get("endDate")
                    ? new Date(searchParams.get("endDate"))
                    : ""
                }
                onStartDateChange={handleStartDate}
                onEndDateChange={handleEndDate}
              />
            </div>
          </div>
        </div>
        <div className="flex items-center gap-2 p-2 ps-1">
          <Dropdown2
            options={
              wingsData?.map((item) => ({
                value: item?.wing_id,
                label: item?.number,
              })) || []
            }
            placeholder="Select Wing"
            className="text-[11px] ms-4"
            width="172px"
            height="32px"
            onSelect={(selectedItem) => {
              handleSelectWing(selectedItem);
            }}
            value={
              searchParams.get("wingId") && {
                value: searchParams.get("wingId"),
                label: wingsData?.find(
                  (item) =>
                    item.wing_id?.toString() === searchParams.get("wingId")
                )?.number,
              }
            }
          />
          <Dropdown2
            options={
              (searchParams.get("wingId") &&
                flatsData?.manage_flat_details?.map((item) => ({
                  value: item?.flat_id,
                  label: item?.flat_no,
                }))) ||
              []
            }
            placeholder="Select Flat"
            className="text-[11px] ms-4"
            width="172px"
            height="32px"
            onSelect={(selectedItem) => {
              handleSelectFlat(selectedItem);
            }}
            value={
              searchParams.get("flatId") && {
                value: searchParams.get("flatId"),
                label: flatsData?.manage_flat_details?.find(
                  (item) =>
                    item.flat_id?.toString() === searchParams.get("flatId")
                )?.flat_no,
              }
            }
          />
          {/* <Dropdown2
            options={debitorType}
            placeholder="Debitor Type"
            className="text-[11px] ms-4"
            width="172px"
            height="32px"
            // onSelect={(selectedItem) => {
            //   handelSelectStatus(selectedItem);
            // }}
            value={
              searchParams.get("status") && {
                value: searchParams.get("status"),
                label: debitorType?.[searchParams.get("status")],
              }
            }
          />
          <Dropdown2
            options={status}
            placeholder="Status"
            className="text-[11px] ms-4"
            width="172px"
            height="32px"
            onSelect={(selectedItem) => {
              // handelSelectBillType(selectedItem);
            }}
            value={
              searchParams.get("bill_type") && {
                value: searchParams.get("bill_type"),
                label: status?.[searchParams.get("bill_type")],
              }
            }
          /> */}
          {/* <Dropdown2
            options={
              manageDebitNoteCurrent?.data?.debitor_type
                ? Object.entries(
                    manageDebitNoteCurrent?.data?.debitor_type
                  )?.map((item) => ({
                    label: item?.[1],
                    value: item?.[0],
                  }))
                : []
            }
            placeholder="Select Debitor Type"
            className="text-[11px] ms-4"
            width="172px"
            height="32px"
            onSelect={(selectedItem) => {
              handleSelectDebitorType(selectedItem);
            }}
            value={
              searchParams.get("debitor_type") && {
                value: searchParams.get("debitor_type"),
                label:
                  manageDebitNoteCurrent?.data?.debitor_type?.[
                    searchParams.get("debitor_type")
                  ],
              }
            }
          /> */}

          <ButtonG label="Clear" className="h-8" onClick={handleClear} />
        </div>
        {/* <div>
          <div className="flex justify-start gap-3 items-center my-0 p-1 mb-2">
            <div className="text-sm">Due Filter</div>
            <FilterTextField
              defaultFromValue={defaultFromValue && defaultFromValue}
              defaultToValue={defaultToValue && defaultToValue}
              onAmountFromChange={(e) => {
                setDefaultFromValue(e.target.value);
              }}
              onAmountToChange={(e) => {
                setDefaultToValue(e.target.value);
              }}
            />
            <Button
              label="Search"
              type="button"
              onClick={() => {
                handleAmountChange();
              }}
              className={`${
                !defaultFromValue || !defaultToValue ? "opacity-50" : ""
              }`}
              disabled={!defaultFromValue || !defaultToValue}
            />
            <Button
              label="Reset"
              type="button"
              className={`${
                !defaultFromValue || !defaultToValue ? "opacity-50" : ""
              }`}
              onClick={() => handleAmountChange(true)}
              disabled={!defaultFromValue || !defaultToValue}
            />
          </div>
        </div> */}
        <div>
          <InterestCalculationReportTable
            loading={loading}
            data={
              "fasdfds".split("").map((item, index) => ({
                sNo: index + 1,
                invoiceNo: "HC-0037/2025-26 Revised",
                memberDetails: "Mayank Lakhera Wing No.Test - A101",
                invoiceDate: "09-03-2026	",
                generationDate: "22-04-2024	",
                amount: {
                  amount: 1212,
                  paid: 12,
                  due: 1200,
                },
                status: "pending",
                useId: "QAdev4175@zipgrid.com	",
                userType: "Society",
              }))
              //   manageDebitNoteCurrent?.data?.debitnote_details?.map(
              //   (item, index) => ({
              //     sNo:
              //       manageDebitNoteCurrent?.data?.paginate?.current *
              //         manageDebitNoteCurrent?.data?.paginate?.limit +
              //       1 +
              //       index,
              //     id: item.id,

              //     flatNo: item?.flat?.flat_no,
              //     invoiceDate: item.invoice_date
              //       ? moment(new Date(item?.invoice_date))?.format("DD-MM-YYYY")
              //       : "-",
              //     generationDate: item?.CreatedOn
              //       ? moment(new Date(item?.CreatedOn))?.format("DD-MM-YYYY")
              //       : "-",
              //     amount: item.grandtotal,
              //     amountPaid: item.paid_amount,
              //     amountDue: item.due_amount,
              //     status: item.row_status,
              //     remarks: item.remarks,
              //     ismakePayment: item.ismakePayment ? item.ismakePayment : "",
              //     paymentLink: item?.paymentLink,
              //     payid: item.payments
              //       ? item.payments.map((payment) => payment.id)
              //       : [],
              //   })
              // )
            }
            columns={headCells}
            checkbox={false}
            // pagination={interestCalculationtReport?.data?.length}
            totalCount={interestCalculationtReport?.paginate?.total_count}
            totalPages={interestCalculationtReport?.paginate?.total_page}
            start={
              interestCalculationtReport?.paginate?.current *
                interestCalculationtReport?.paginate?.limit +
              1
            }
            end={
              interestCalculationtReport?.paginate?.current *
                interestCalculationtReport?.paginate?.limit +
              interestCalculationtReport?.data?.length
            }
            currentPage={
              Number(interestCalculationtReport?.paginate?.current) + 1
            }
            rowsPerPage={searchParams.get("pageLimit") || 10}
            getTableData={getTableData}
            selected={selected}
            setSelected={setSelected}
          ></InterestCalculationReportTable>

          {!interestCalculationtReport?.data?.length && !loading && (
            <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
              No Records
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default InterestCalculationReport;
