import React, { useEffect, useState } from "react";
import ListHeader from "../../../components/ListHeader/ListHeader";
import Search from "../../../components/SearchBox/Search";
import GenericTable from "../../../components/TableComponent/Table";
import Layout from "../../Layout/Layout";
import ButtonIco from "../../../components/Button/ButtonPrint";
import { DownloadExt } from "../../../assets";
import Button from "../../../components/Button/Button";
import Rangepayment_datePicker from "../../../components/DatePicker/RangeDatePicker";
import Dropdown2 from "../../../components/Dropdown/dropdown2";
import moment from "moment";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { INCOMPLETE_TRANSACTIONS_ENDPOINT, RECEIPTS_TRANSACTIONS_ENDPOINT } from "../../../Routing/routes";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { downloadReceiptList, getReceiptList } from "../../../redux/actions/FinanceActions/BillingReportsAction";
import ReceiptsTransactionsTable from "./ReceiptsTransactionsTable";
import RangeDatePicker from "../../../components/DatePicker/RangeDatePicker";
import { validateDate } from "../../../utils/helpers/universalFunctions";
import ButtonG from "../../../components/Button/ButtonG";
import { getInvoiceTypeList } from "../../../redux/actions/SetupRecordsActions/BillingAndInvoiceSetupActions/InvoiceSettingAction";

export const headCells = [
  { id: "id", label: "Sr. No.", width: "60px" },
  { id: "date", label: "Date", sort: false, width: "70px" },
  { id: "unitNumber", label: "Unit Number", sort: true, width: "110px" },
  { id: "type", label: "Type", sort: true, width: "70px" },
  { id: "paymentMethod", label: "Payment Mode", sort: true, width: "90px" },
  { id: "transactionid", label: "Number", sort: true, width: "110px" },
  {
    id: "transactionRefNo",
    label: "Ref No.",
    sort: true,
    width: "160px",
  },
  { id: "status", label: "Status", sort: true, width: "90px" },
  { id: "amount", label: "Amount", sort: true, width: "120px" },
  { id: "", label: "Action", sort: false },
];

const rows = [];

const ReceiptsTransactions = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { loginData } = useSelector((state) => state.loginReducer);
  const [invoiceTypeList, setInvoiceTypeList] = useState([]);
  const { loading, receiptList } = useSelector(
    (state) => state.billingReportsReducer
  );

  const { typeLists } = useSelector(
    (state) => state.invoiceSettingReducer
  );

  const handleNavigation = (path) => () => {
    navigate(path);
  };

  const handleSearch = (text) => {
    if (text && text !== searchParams.get("transactionid")) {
      searchParams.delete("page");
      searchParams.delete("transactionid");
      navigate({
        pathname: RECEIPTS_TRANSACTIONS_ENDPOINT,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["transactionid", text?.trim()],
        ])}`,
      });
    } else {
      searchParams.delete("transactionid");
      navigate({
        pathname: RECEIPTS_TRANSACTIONS_ENDPOINT,
        search: `?${createSearchParams([...searchParams.entries()])}`,
      })
    }
  };

  const handleSelectMode = (selectedItem) => {
    if (selectedItem.value?.toString() !== searchParams.get("modeOfPayment")) {
      searchParams.delete('page')
      searchParams.delete('pageLimit')
      searchParams.delete("modeOfPayment")
      navigate({
        pathname: `${RECEIPTS_TRANSACTIONS_ENDPOINT}`,
        search: `?${createSearchParams([...searchParams.entries(), ["modeOfPayment", selectedItem.value]])}`,
      })
    }
  };

  const handelSelectInvoiceType = (selectedItem) => {
    searchParams.delete("page");
    searchParams.delete("typeid");
    navigate({
      pathname: `${RECEIPTS_TRANSACTIONS_ENDPOINT}`,
      search: `?${createSearchParams([
        ...searchParams.entries(),
        ["typeid", selectedItem.value],
      ])}`,
    });
  };

  const handleStartDate = (selectedDate) => {
    if (
      moment(selectedDate).format("YYYY-MM-DD") !==
      searchParams.get("fromDate")
    ) {
      searchParams.delete("fromDate");
      navigate({
        pathname: RECEIPTS_TRANSACTIONS_ENDPOINT,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["fromDate", moment(selectedDate).format("YYYY-MM-DD")],
        ])}`,
      });
    }
  };

  const handleEndDate = (selectedDate) => {
    if (
      moment(selectedDate).format("YYYY-MM-DD") !== searchParams.get("toDate")
    ) {
      searchParams.delete("toDate");
      navigate({
        pathname: RECEIPTS_TRANSACTIONS_ENDPOINT,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["toDate", moment(selectedDate).format("YYYY-MM-DD")],
        ])}`,
      });
    }
  };

  const handleClear = () => {
    navigate(`${RECEIPTS_TRANSACTIONS_ENDPOINT}`);
  };

  const getTableData = () => {
    const data = {
      societyId: loginData?.default_community?.community_id,
      transactionId: searchParams.get("transactionid") || '',
      modeOfPayment: searchParams.get("modeOfPayment") || '',
      searchBy: searchParams.get("searchBy") || 'dated',
      fromDate: searchParams.get("fromDate") || '',
      toDate: searchParams.get("toDate") || '',
      InvoiceType: searchParams.get("typeid") || 1,
      page: searchParams.get("page") || 1,
      pageLimit: searchParams.get("pageLimit") || 10,
      export: 0,
    }
    dispatch(getReceiptList(data))
  }

  const handleDownloadExcel = () => {
    const data = {
      societyId: loginData?.default_community?.community_id,
      transactionId: searchParams.get("transactionid") || '',
      modeOfPayment: searchParams.get("modeOfPayment") || '',
      searchBy: searchParams.get("searchBy") || 'dated',
      fromDate: searchParams.get("fromDate") || '',
      toDate: searchParams.get("toDate") || '',
      page: searchParams.get("page") || 1,
      pageLimit: searchParams.get("pageLimit") || 10,
      export: 1,
    }
    dispatch(downloadReceiptList(data));
  };

  useEffect(() => {
    getTableData()
  }, [searchParams])

  useEffect(() => {
    dispatch(getInvoiceTypeList({
      societyId: loginData?.default_community?.community_id,
    }))
  }, [])

  useEffect(() => {
    if (typeLists) {
      setInvoiceTypeList(typeLists)
    }
  }, [typeLists])

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="border-b border-[#CCC]">
          <ListHeader leftContent="Receipts">
            <Button label="Incomplete Transaction" onClick={handleNavigation(INCOMPLETE_TRANSACTIONS_ENDPOINT)} />
            <div className="border-r border-[#CCC]"></div>
            <ButtonIco
              icon={DownloadExt}
              children="Download Excel"
              className="h-8"
              type="button"
            // onClick={handleDownloadExcel}
            />
          </ListHeader>
        </div>

        <div className="py-1 flex justify-between gap-2 my-1">
          <div className="flex gap-2">
            <Search height="8" width="48" placeholder="Search by Transaction No." onclick={handleSearch} value={searchParams.get("transactionid")} />
            <Dropdown2
              options={[
                { value: "Cheque", label: "Cheque" },
                { value: "NEFT", label: "NEFT" },
                { value: "Cash", label: "Cash" },
                { value: "Online", label: "Online" },
              ]}
              width="175px"
              height="8"
              placeholder="Payment Mode"
              onSelect={(selectedItem) => {
                handleSelectMode(selectedItem)
              }}
              value={
                searchParams.get("modeOfPayment") && {
                  value: searchParams.get("modeOfPayment"),
                  label: searchParams.get("modeOfPayment")
                }
              }
            />
          </div>
          <div className="flex items-center gap-2">
            <div className="flex items-center gap-2">
              <Dropdown2
                options={
                  invoiceTypeList
                    ? invoiceTypeList?.map(
                      (item) => ({
                        label: item?.invoice_title,
                        value: item?.id,
                      })
                    )
                    : []
                }
                placeholder="Invoice type"
                className="text-[11px] ms-4"
                width="175px"
                height="32px"
                onSelect={(selectedItem) => {
                  handelSelectInvoiceType(selectedItem);
                }}
                value={
                  searchParams.get("typeid") && {
                    value: searchParams.get("typeid"),
                    label: invoiceTypeList ? invoiceTypeList?.find((item) => item?.id === parseInt(searchParams.get("typeid")))?.invoice_title : "",
                  }
                }
              />
            </div>
            <span className="text-sm">Search By Date</span>
            <RangeDatePicker
              className={"w-[190px] h-[32px]"}
              defaultStartValue={
                searchParams.get("fromDate")
                  ? new Date(searchParams.get("fromDate"))
                  : ""
              }
              defaultEndValue={
                searchParams.get("toDate")
                  ? new Date(searchParams.get("toDate"))
                  : ""
              }
              onStartDateChange={handleStartDate}
              onEndDateChange={handleEndDate}
            />
            <ButtonG label="Clear" className="h-8" onClick={handleClear} />
          </div>
        </div>
        <div className="ml-1">
          <ReceiptsTransactionsTable
            loading={loading}
            data={receiptList?.data?.map((item, index) => ({
              sNo: ((receiptList?.paginate?.current * receiptList?.paginate?.limit + 1)+index)?.toString()?.padStart(2, "0"),
              id: item?.enc_key,
              // sNo: (index + 1)?.toString()?.padStart(2, '0'),
              date: validateDate(item?.payment_date) ? moment(item?.payment_date, 'YYYY-MM-DD hh:mm:ss').format('DD-MM-YYYY') : '-',
              unitNumber: item?.unit || '-',
              type: item?.modeofpayment || '-',
              paymentMethod: item?.paymentMethod || '-',
              transactionid: item?.transactionid || '-',
              amount: item?.amount || '-',
              transactionRefNo: item?.receipt_no || '-',
              status: item?.status || '-',
              userType: item?.modified_type || '-',
              ip: item?.IpAddress || '-',
            }))}
            columns={headCells}
            checkbox={false}
            pagination={receiptList?.data?.length}
            totalCount={receiptList?.paginate?.total_count}
            totalPages={receiptList?.paginate?.total_page}
            start={(receiptList?.paginate?.current) * receiptList?.paginate?.limit + 1}
            end={(receiptList?.paginate?.current) * receiptList?.paginate?.limit + receiptList?.data?.length}
            currentPage={parseInt(receiptList?.paginate?.current) + 1}
            rowsPerPage={searchParams.get("pageLimit") || 10}
            getTableData={getTableData}
          ></ReceiptsTransactionsTable>

          {!receiptList?.data?.length && !loading && (
            <div className="text-lg text-[#AAAAAA] mt-10 text-center italic">
              No Records
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default ReceiptsTransactions;
