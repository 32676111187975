import React, { useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Checkbox from "@mui/material/Checkbox";
import { visuallyHidden } from "@mui/utils";
import "../../../../components/TableComponent/Table.css";
import {
  ApproveIcon,
  DeleteIcon,
  EditIcon,
  EditRateChartIcon,
  PrinterIcon,
  SendEmail,
  SendSMSIcon,
  SortIcon,
} from "../../../../assets";
import styled from "@emotion/styled";
import {
  stableSort,
  getComparator,
} from "../../../../utils/helpers/universalFunctions";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Loader from "../../../../components/Loader/Loader";
import ItemsPerPage from "../../../../components/ItemsPerPage/ItemsPerPage";
import Pagination from "../../../../components/Pagination/Pagination";
import { Tooltip } from "@mui/material";
import RejectPaymentModal from "./RejectPaymentModal";
import VerifyAndPostModal from "./VerifyandPostModal";
import SendSmsModal from "../../../../components/Modal/SendSmsModal";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  EDIT_PAYMENT_DEBIT_NOTE_ENDPOINT,
  PRINT_DEBIT_NOTE_RECEIPT_ENDPOINT,
} from "../../../../Routing/routes";
import {
  rejectSingleReceiptDebitNote,
  resendDebitNoteReceiptEmailSms,
} from "../../../../redux/actions/FinanceActions/BillingManagementActions/ManageDebitNoteAction";
import DeleteModal from "../../../../components/Modal/DeleteModal";
import { getPermission } from "../../../../utils/helpers/getPermissions";
import * as permissions from "../../../../Routing/permissions";

function ActionComponent({
  row = null,
  singleDebitNote = null,
  id,
  receiptid,
  invid,
  invoiceid,
  PostStatus,
  paymentInvoiceId,
  status,
  transactionid,
  transactionno,
  receiptNo,
  getTableData = () => {},
  lastIndex = false,
}) {
  const data = useLocation().state;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loginData } = useSelector((state) => state.loginReducer);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showVerifyAndPostModal, setShowVerifyAndPostModal] = useState(false);
  const [showSendSMSModalModal, setShowSendSMSModalModal] = useState(false);

  const openModal = () => {
    setShowVerifyAndPostModal(true);
  };

  const closeModal = () => {
    setShowVerifyAndPostModal(false);
  };

  const handleDeleteClick = () => {
    setShowDeleteModal(true);
  };

  const handleSendSMSModalClick = () => {
    setShowSendSMSModalModal(true);
  };

  // const handleDelete = () => {
  //   let data = {
  //     societyId: loginData?.default_community?.community_id,
  //     paymentInvoiceId,
  //     onSuccessCallback: () => {
  //       setShowDeleteModal(false);
  //       getTableData();
  //     },
  //   };
  //   dispatch(rejectSingleReceiptDebitNote(data));
  // };

  const handleResendReceiptSMS = () => {
    dispatch(
      resendDebitNoteReceiptEmailSms({
        invIds: [invoiceid],
        societyId: loginData?.default_community?.community_id,
        notificationType: "SMS",
      })
    );
    setShowSendSMSModalModal(false);
  };

  const handleResendReceiptEmail = () => {
    dispatch(
      resendDebitNoteReceiptEmailSms({
        invIds: [invoiceid],
        societyId: loginData?.default_community?.community_id,
        notificationType: "EMAIL",
      })
    );
    setShowSendSMSModalModal(false);
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      {getPermission(loginData, [permissions.EDIT_RECEIPT_DEBIT_NOTE]) &&
        (status == "Unverified" || status == "Verified") &&
        row?.mode != "Online" &&
        singleDebitNote?.status != "Carried Forward" &&
        singleDebitNote?.status != "Carried Forward In Invoice" &&
        lastIndex && (
          <Tooltip title="Edit Receipt" arrow>
            <EditIcon
              className="fill-[#555] hover:fill-red-650 me-1.5"
              onClick={() => {
                navigate(`${EDIT_PAYMENT_DEBIT_NOTE_ENDPOINT}/${invoiceid}`, {
                  state: { paymentInvoiceId, paymentLink: data?.paymentLink },
                });
              }}
            />
          </Tooltip>
        )}

      {/* {PostStatus === "No" && status === "Unverified" && ( */}
      {/* <Tooltip title="Edit Payment" arrow>
        <EditRateChartIcon
          className="fill-[#555] hover:fill-red-650 me-1.5"
          // onClick={() => {
          //   navigate(`${EDIT_PAYMENT_ENDPOINT}/${invoiceid}`, {
          //     state: paymentInvoiceId,
          //   });
          // }}
        />
      </Tooltip> */}
      {/* )} */}

      {getPermission(loginData, [permissions.VERIFY_POST_DEBIT_NOTE]) &&
        status == "Unverified" &&
        row?.mode != "Online" &&
        singleDebitNote?.status != "Carried Forward" && (
          <Tooltip title="Verify & Post Entry" arrow>
            <ApproveIcon
              className="fill-[#555] hover:fill-red-650 me-1.5"
              onClick={openModal}
            />
          </Tooltip>
        )}
      <Tooltip title="Print" arrow>
        <Link
          target="_blank"
          to={`${PRINT_DEBIT_NOTE_RECEIPT_ENDPOINT}/${invoiceid}`}
        >
          <PrinterIcon className="fill-[#555] hover:fill-red-650 me-1.5" />
        </Link>
      </Tooltip>
      {getPermission(loginData, [permissions.DELETE_RECEIPT_DEBIT_NOTE]) &&
        row?.mode != "Online" &&
        singleDebitNote?.status != "Carried Forward" &&
        singleDebitNote?.status != "Carried Forward In Invoice" &&
        lastIndex && (
          <Tooltip title="Reject Receipt" arrow>
            <DeleteIcon
              className="fill-[#555] hover:fill-red-650 me-1.5"
              onClick={() => handleDeleteClick()}
            />
          </Tooltip>
        )}
      {getPermission(loginData, [
        permissions.RESEND_RECEIPT_EMAIL_SMS_DEBIT_NOTE,
      ]) && (
        // (status === "Unverified" ||
        //   singleDebitNote?.status == "Carried Forward") &&
        <Tooltip title="Resend Receipt Email" arrow>
          <SendEmail
            className="fill-[#555] hover:fill-red-650 me-1.5"
            onClick={() => handleSendSMSModalClick()}
          />
        </Tooltip>
      )}
      {getPermission(loginData, [
        permissions.RESEND_RECEIPT_EMAIL_SMS_DEBIT_NOTE,
      ]) && (
        // (
        //   status === "Unverified" ||
        //   singleDebitNote?.status == "Carried Forward") &&
        <Tooltip title="Resend Receipt SMS" arrow>
          <SendSMSIcon
            className="fill-[#555] hover:fill-red-650 me-1.5"
            onClick={handleResendReceiptEmail}
          />
        </Tooltip>
      )}

      <RejectPaymentModal
        invoiceid={row?.debitnoteid}
        paymentInvoiceId={paymentInvoiceId}
        isOpen={showDeleteModal}
        onCancel={() => {
          setShowDeleteModal(false);
        }}
        onDelete={() => {
          setShowDeleteModal(false);
        }}
        onConfirm={() => {
          getTableData();
          setShowDeleteModal(false);
        }}
      />
      {/* <DeleteModal
        isOpen={showDeleteModal}
        onCancel={() => setShowDeleteModal(false)}
        onDelete={handleDelete}
      /> */}
      <VerifyAndPostModal
        isOpen={showVerifyAndPostModal}
        onClose={closeModal}
        invoiceid={invoiceid}
        paymentInvoiceId={paymentInvoiceId}
        transactionid={transactionid}
        transactionno={transactionno}
        receiptNo={receiptNo}
      />

      <SendSmsModal
        isOpen={showSendSMSModalModal}
        onCancel={() => setShowSendSMSModalModal(false)}
        onConfirm={handleResendReceiptSMS}
      />
    </Box>
  );
}

const TableRowStyled = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: #ffffff;
  }
  &:nth-of-type(even) {
    background-color: #f4f5f8;
  }
  &:nth-of-type(odd):hover {
    background-color: #ffffff;
  }
  &:nth-of-type(even):hover {
    background-color: #f4f5f8;
  }
`;

const TableCellStyled = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 600,
    zIndex: 0,
    color: "#AAAAAA",
    fontSize: "13px",
    padding: "12px 8px 12px 0",
    wordBreak: "break-word",
  },
  [`&.${tableCellClasses.body}`]: {
    borderBottom: "none",
    fontSize: "13px",
    padding: "12px 8px 12px 0",
    wordBreak: "break-word",
    cursor: "pointer",
  },
}));

const ViewDebitNoteDetailsTable = ({
  loading,
  data,
  singleDebitNote = null,
  columns,
  checkbox = true,
  pagination = true,

  //props are required if pagination is true
  totalCount,
  totalPages,
  start,
  end,
  currentPage,
  rowsPerPage,
  getTableData = () => {},
}) => {
  const navigate = useNavigate();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(null);
  const [selected, setSelected] = useState([]);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    const newSelected = event.target.checked
      ? data.map((item) => item.employeeRoleId)
      : [];
    setSelected(newSelected);
  };

  const handleClick = (event, id) => {
    const newSelected = selected.includes(id)
      ? selected.filter((selectedId) => selectedId !== id)
      : [...selected, id];
    setSelected(newSelected);
  };

  const handleChangePage = (newPage) => {
    // searchParams.delete("page")
    navigate({
      pathname: `${""}`,
      // search: `?${createSearchParams([...searchParams.entries(), ["page", newPage]])}`,
    });
  };

  const handleChangeRowsPerPage = (value) => {
    if (rowsPerPage !== value) {
      // searchParams.delete("pageLimit")
      navigate({
        pathname: `${""}`,
        // search: `?${createSearchParams([...searchParams.entries(), ["pageLimit", value]])}`,
      });
    }
  };

  const isSelected = (id) => selected.includes(id);

  return (
    <Box sx={{ width: "100%", fontSize: "13px" }} className="table-component">
      <TableContainer
        className="border-t border-[#CCC]"
        sx={{ minHeight: data?.length ? "500px" : "none" }}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {!!checkbox && (
                <TableCellStyled
                  size="small"
                  sx={{ pl: "0 !important", pr: 2, width: 36 }}
                >
                  <Checkbox
                    size="small"
                    indeterminate={false}
                    checked={
                      data?.length > 0 && selected?.length === data?.length
                    }
                    onChange={handleSelectAllClick}
                    inputProps={{
                      "aria-label": "select all",
                    }}
                    sx={{
                      p: 0,
                      color: "#AAAAA",
                      "&.Mui-checked": {
                        color: "#CF001C",
                      },
                    }}
                    disabled={loading || !data}
                  />
                </TableCellStyled>
              )}
              {columns?.map((column) => (
                <TableCellStyled
                  size="small"
                  key={column.id + "heading"}
                  align={column.numeric ? "right" : "left"}
                  sortDirection={orderBy === column.id ? order : false}
                  sx={{
                    width: column.width || "fit-content",
                    minWidth: column.width || "fit-content",
                  }}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={orderBy === column.id ? order : "asc"}
                    onClick={() => {
                      if (column.sort) {
                        handleRequestSort(column.id);
                      }
                    }}
                    IconComponent={SortIcon}
                    sx={{
                      ".MuiTableSortLabel-icon": {
                        opacity: "1 !important",
                      },
                      svg: {
                        flexShrink: 0,
                      },
                    }}
                    hideSortIcon={!column.sort}
                  >
                    {column.label}
                    {orderBy === column.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCellStyled>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCellStyled
                  sx={{ border: "none", textAlign: "center" }}
                  colSpan={columns?.length + 1}
                >
                  {/* <Loader /> */}
                  No Records
                </TableCellStyled>
              </TableRow>
            ) : (
              <>
                {!!data?.length &&
                  stableSort(data, getComparator(order, orderBy))?.map(
                    (row, rowIndex) => {
                      const isRowSelected = isSelected(row?.id);

                      return (
                        <TableRowStyled
                          key={row?.employeeRoleId}
                          role="checkbox"
                          aria-checked={isRowSelected}
                          tabIndex={-1}
                          selected={isRowSelected}
                        >
                          {!!checkbox && (
                            <TableCellStyled
                              size="small"
                              sx={{ pl: "0 !important", zIndex: 0 }}
                              onClick={(event) =>
                                handleClick(event, row?.employeeRoleId)
                              }
                            >
                              <Checkbox
                                checked={selected?.includes(
                                  row?.employeeRoleId
                                )}
                                onChange={(e) =>
                                  handleClick(e, row?.employeeRoleId)
                                }
                                size="small"
                                color="primary"
                                inputProps={{
                                  "aria-labelledby": `generic-table-checkbox-${row?.id}`,
                                }}
                                sx={{
                                  p: 0,
                                  color: "#AAAAA",
                                  "&.Mui-checked": {
                                    color: "#CF001C",
                                  },
                                }}
                              />
                            </TableCellStyled>
                          )}

                          <TableCellStyled size="small" align="left">
                            {row.date !== "0000-00-00"
                              ? new Date(row.date).toLocaleString("en-GB", {
                                  day: "2-digit",
                                  month: "2-digit",
                                  year: "numeric",
                                })
                              : "-"}
                          </TableCellStyled>
                          <TableCellStyled
                            size="small"
                            align="left"
                            onClick={(event) => handleClick(event)}
                          >
                            {row?.receiptid}
                          </TableCellStyled>
                          <TableCellStyled
                            size="small"
                            align="left"
                            onClick={(event) => handleClick(event)}
                          >
                            {row?.mode}
                          </TableCellStyled>
                          <TableCellStyled
                            size="small"
                            align="left"
                            onClick={(event) => handleClick(event)}
                          >
                            {row?.totalAmount}
                          </TableCellStyled>

                          <TableCellStyled size="small" align="left">
                            {row?.amountPaid}
                          </TableCellStyled>
                          <TableCellStyled size="small" align="left">
                            {row?.amountDue}
                          </TableCellStyled>
                          <TableCellStyled size="small" align="left">
                            <ActionComponent
                              row={row}
                              id={row.societyId}
                              invid={row.invid}
                              invoiceid={row.invoiceid}
                              receiptid={row.receiptid}
                              status={row.status}
                              PostStatus={row.PostStatus}
                              paymentInvoiceId={row.paymentInvoiceId}
                              transactionid={row.transactionid}
                              transactionno={row.transactionno}
                              receiptNo={row.receiptNo}
                              getTableData={getTableData}
                              singleDebitNote={singleDebitNote}
                              lastIndex={rowIndex + 1 == data?.length}
                            />
                          </TableCellStyled>
                        </TableRowStyled>
                      );
                    }
                  )}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {!pagination && data && (
        <div className="flex flex-row justify-between mt-3 pt-1 border-t border-[#ccc] text-[14px]">
          <ItemsPerPage
            rowsPerPage={rowsPerPage}
            onChange={handleChangeRowsPerPage}
          />
          <Pagination
            totalCount={totalCount}
            totalPages={totalPages}
            start={start}
            end={end}
            currentPage={currentPage}
            onChange={handleChangePage}
          />
        </div>
      )}
    </Box>
  );
};

ViewDebitNoteDetailsTable.propTypes = {
  columns: PropTypes.array.isRequired,
  defaultSortField: PropTypes.string,
  rowsPerPageOptions: PropTypes.array,
};

export default ViewDebitNoteDetailsTable;
