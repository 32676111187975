import React, { useEffect } from "react";
import ListHeaderBack from "../../../components/ListHeader/ListHeaderBack";
import Layout from "../../Layout/Layout";
import { useNavigate } from "react-router-dom";
import FaciltiReportTable from "./FacilityReportTable";
import ButtonIco from "../../../components/Button/ButtonPrint";
import { faDownload, faPrint } from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import { downloadFacilityReportExcelSheet, getFacilityReport } from "../../../redux/actions/ClubActions/ViewBookingsAction";
import { useSelector } from "react-redux";
import { PRINT_FACILITY_REPORT_ENDPOINT } from "../../../Routing/routes";
import { downloadExcel } from "../../../utils/helpers/downloadMedia";
import { CircularProgress } from "@mui/material";

export const headCells = [
  { id: "sNo", label: "Sr. No.", width: "50px" },
  { id: "invoiceNo", label: "Invoice No.", sort: true, width: "100px" },
  { id: "status", label: "Status", width: "100px" },
  { id: "facility", label: "Facility", sort: true, width: "100px" },
  { id: "booking_frequency", label: "Frequency", width: "100px" },
  { id: "flatNo", label: "Flat No.", sort: true, width: "90px" },
  { id: "memberName", label: "Member Name", sort: true, width: "120px" },
  { id: "dateOfBooking", label: "Date Of Booking", sort: true, width: "130px" },
  { id: "from_date", label: "from Date", sort: true, width: "130px" },
  { id: "to_date", label: "to Date", sort: true, width: "130px" },
  { id: "bookingAmount", label: "Booking Amount", sort: true, width: "130px" },
  { id: "cgst", label: "CGST", sort: false, width: "50px" },
  { id: "sgst", label: "SGST", sort: false, width: "50px" },
  {
    id: "securityDeposit",
    label: "Security Deposit",
    sort: false,
    width: "70px",
  },
  { id: "totalAmount", label: "Total Amount", sort: false, width: "70px" },
];

function FacilityReport() {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const {loginData} = useSelector(state=>state.loginReducer)
  const { downLoader } = useSelector(state => state.facilityReportDownloadExcelReducer)
  const {loading, facilityReport} = useSelector(state=>state.viewBookingReducer)

  const handlePrint =()=>{
    window.open(PRINT_FACILITY_REPORT_ENDPOINT)
  }


  
  const handleDownloadExcel = ()=>{
    const onSucessCallback = (data)=>{
      downloadExcel({data, fileName: 'Facility Report Sheet.xlsx'})
    }
    const data = {
      societyId: loginData?.default_community?.community_id, 
      onSucessCallback
    }
    dispatch(downloadFacilityReportExcelSheet(data))
  }


  useEffect(()=>{
    dispatch(getFacilityReport({societyId: loginData?.default_community?.community_id}))
  }, [])

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div>
          <ListHeaderBack
            title="Facility Report"
            onClick={() => {
              navigate(-1);
            }}
          >
            <div className="border-r border-[#CCC]"></div>
            <div className="flex gap-2">
              <ButtonIco
                icon={faPrint}
                onClick={handlePrint}
                children="Print"
              />
              {downLoader ?
                <ButtonIco className=" w-32 justify-center" children={<CircularProgress sx={{ color: "#d32f2f" }} size={17} />} />
                :
                <ButtonIco icon={faDownload} children='Download Excel' onClick={handleDownloadExcel} />
              }
            </div>
          </ListHeaderBack>
        </div>
        <div>
          <div className="ml-1">
            <FaciltiReportTable
              loading={loading}
              data={
                facilityReport?.map((item, index)=>({
                  sNo: (index+1)?.toString()?.padStart(2, '0'),
                  frequency: item.booking_frequency,
                  status: item?.status,
                  fromDate: item.from_date,
                  toDate: item.to_date,
                  invoiceNo: item?.invoice_number || '-',
                  facility: item?.facilities_name || '-',
                  flatNo: item?.wing_flat || '-',
                  memberName: item?.member_name || '-',
                  dateOfBooking: item?.date_of_booking || '-',
                  bookingAmount: item?.booking_amount?.toString() || '-',
                  cgst: item?.cgst?.toString() || '-',
                  sgst: item?.sgst?.toString() || '-',
                  securityDeposit: item?.security_deposit?.toString() || '-',
                  totalAmount: item?.total_amount?.toString() || '-',
                }))
              }
              columns={headCells}
              checkbox={false}
              pagination={1}
            ></FaciltiReportTable>
          </div>
          {!facilityReport?.length && !loading && (
            <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
              No Records
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
}

export default FacilityReport;
