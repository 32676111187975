import React, { useEffect, useState } from "react";
import Layout from "../../../Layout/Layout";
import RangeDatePicker from "../../../../components/DatePicker/RangeDatePicker";
import FilterTextField from "../../../../components/InputFields/FilterTextFields";
import Dropdown2 from "../../../../components/Dropdown/dropdown2";
import { TextInput } from "../../../../components/InputFields/TextInput";
import Search from "../../../../components/SearchBox/Search";
import Button from "../../../../components/Button/Button";
import ButtonIco from "../../../../components/Button/ButtonPrint";
import { DownloadExt, UploadEx, PrinterIcon } from "../../../../assets";
import UploadTenantExcelModal from "../../../../components/Modal/UploadTenantExcelModal";
import UploadDebitNoteExcelModal from "../../../../components/Modal/UploadDebitNoteExcelUploadModal";
import ListHeader from "../../../../components/ListHeader/ListHeader";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import {
  ADD_DEBIT_NOTE_ENDPOINT,
  MAKE_PAYMENT_DEBIT_NOTE_MULTI_ENDPOINT,
  MANAGE_DEBIT_DRAFT_ENDPOINT,
  MANAGE_DEBIT_PREVIOUS_ENDPOINT,
  DEBIT_NOTE_INVOICE_REPORT_ENDPOINT,
  PRINT_DEBIT_NOTE_INVOICE_REPORT_ENDPOINT,
} from "../../../../Routing/routes";
import ManageDebitCurrentTable from "../../../../components/TableComponent/ManageDebitCurrentTable";
import DebitNoteInvoiceReportTable from "./DebitNoteInvoiceReportTable";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import moment from "moment";
import {
  downloadExcel,
  downloadFileFromURL,
  exportToCSV,
} from "../../../../utils/helpers/downloadMedia";
import {
  getDebitNoteCurrent,
  uploadDebitNoteCurrentExcel,
  downloadDebitNoteCurrentPayment,
  uploadDebitNoteCurrentPayment,
} from "../../../../redux/actions/FinanceActions/BillingManagementActions/ManageDebitNoteAction";
import ButtonG from "../../../../components/Button/ButtonG";
import {
  getFlatDetails,
  getWingsAndFlatDetails,
} from "../../../../redux/actions/SetupRecordsActions/BuildingActions/WingsAndFlatsAction";
import {
  downloadtDebitNoteInvoiceReport,
  getDebitNoteInvoiceReport,
} from "../../../../redux/actions/ReportActions/FinanceReportActions";
import { validateDate } from "../../../../utils/helpers/universalFunctions";
import { getInvoiceTypeList } from "../../../../redux/actions/SetupRecordsActions/BillingAndInvoiceSetupActions/InvoiceSettingAction";
import { getPermission } from "../../../../utils/helpers/getPermissions";
import * as permissions from "../../../../Routing/permissions";

const headingsDebitNoteInvoice = [
  { id: "invoiceNo", label: "Invoice No", sort: true, width: "130px" },
  { id: "invoiceDate", label: "Invoice Date", sort: true, width: "160px" },
  { id: "flatNo", label: "Flat No", sort: true, width: "100px" },
  { id: "memberName", label: "Member Name", sort: true, width: "100px" },
  {
    id: "gstNo",
    label: "GST No",
    sort: true,
    width: "130px",
  },
  { id: "penalty", label: "Penalty", sort: true, width: "120px" },
  { id: "testing", label: "Testing", sort: true, width: "100px" },
  {
    id: "creditCause",
    label: "Test credit Tariff",
    sort: false,
    width: "100px",
  },

  {
    id: "subTotal",
    label: "SubTotal",
    sort: false,
    width: "100px",
  },
  {
    id: "principalArrear",
    label: "Principal Arrear	",
    sort: false,
    width: "100px",
  },
  {
    id: "interestArrear",
    label: "Interest Arrear	",
    sort: false,
    width: "100px",
  },
  {
    id: "interest",
    label: "Interest",
    sort: false,
    width: "100px",
  },
  {
    id: "grandTotal",
    label: "Grand Total	",
    sort: false,
    width: "100px",
  },
  {
    id: "payment",
    label: "Payment",
    sort: false,
    width: "100px",
  },
  {
    id: "outstanding",
    label: "Outstanding",
    sort: false,
    width: "100px",
  },
];
const invoice = [
  { label: "Invoice", value: "A" },
  { label: "Drafts Invoice", value: "I" },
];
const invoiceType = [
  { label: "Debit Note", value: "Debit Note" },
  { label: "Supplementary", value: "Supplementary" },
];
const gstNo = [
  { label: "B2B", value: "With GST No" },
  { label: "B2C", value: "WithOut GST No" },
];

const DebitNoteInvoiceReport = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { loginData } = useSelector((state) => state.loginReducer);

  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [selected, setSelected] = useState([]);
  const [file, setFile] = useState(null);
  const [headCells, setHeadCells] = useState([]);

  //   const [defaultFromValue, setDefaultFromValue] = useState("");
  //   const [defaultToValue, setDefaultToValue] = useState("");
  //   const [invoiceType, setInvoicetype] = useState(1);

  const { debitNoteInvoiceReport, loading } = useSelector(
    (state) => state.financeReportReducer
  );
  const { wingsData, flatsData } = useSelector((state) => state.wingsReducer);
  const { typeLists } = useSelector((state) => state.invoiceSettingReducer);

  const handleNavigation = (path) => () => {
    navigate(path);
  };

  const handleOpenUploadModal = () => {
    setIsUploadModalOpen(true);
  };
  const handleCloseUploadModal = () => {
    setIsUploadModalOpen(false);
  };
  const onUpload = () => {
    let data = {
      societyId: loginData?.default_community?.community_id,
      invoiceType: invoiceType,
      file,
      onSuccessCallback: (result) => {
        if (result?.data.length > 0) {
          navigate(MAKE_PAYMENT_DEBIT_NOTE_MULTI_ENDPOINT, {
            state: { data: result.data },
          });
        }

        navigate(DEBIT_NOTE_INVOICE_REPORT_ENDPOINT);
      },
      onRequestEndCallback: () => {
        setFile(null);
        setIsUploadModalOpen(false);
      },
    };
    dispatch(uploadDebitNoteCurrentPayment(data));
  };
  const handleDownloadExcel = () => {
    dispatch(
      getDebitNoteInvoiceReport({
        societyId: loginData?.default_community?.community_id,
        page: searchParams.get("page") || 0,
        pageLimit: searchParams.get("pageLimit") || 10,
        memberName: searchParams.get("memberName") || "",
        invoice: searchParams.get("invoice") || "",
        invoiceType: searchParams.get("invoiceType") || "",
        wingId: searchParams.get("wingId") || "",
        flatId: searchParams.get("flatId") || "",
        gstNo: searchParams.get("gstNo") || "",
        fromDate: searchParams.get("fromDate") || "",
        toDate: searchParams.get("toDate") || "",
        export: 1,
        onSuccessCallback: (url) => {
          downloadFileFromURL({ url, fileName: "sample.csv" });
        },
      })
    );
  };
  // const handleDownloadExcel = () => {
  //   const data = debitNoteInvoiceReport?.data?.map((item, index) => ({
  //     //   sNo:
  //     //     // (debitNoteInvoiceReport?.data?.paginate?.current - 1) *
  //     //     //   debitNoteInvoiceReport?.data?.paginate?.limit +
  //     //     // 1 +
  //     //     index + 1,
  //     invoiceNo: item["Invoice No"],
  //     invoiceDate: validateDate(item["Invoice Date"])
  //       ? moment(new Date(item["Invoice Date"]))?.format("DD-MM-YYYY")
  //       : "-",
  //     flatNo: item["Flat No"],
  //     memberName: item["Member Name"],
  //     gstNo: item["GST No"],
  //     penalty: item["Penalty"],
  //     testing: item["Testing"],
  //     creditCause: item["Test credit cause"],
  //     subTotal: item["SubTotal"],
  //     principalArrear: item["Principal Arrear"],
  //     interestArrear: item["Interest Arrear"],
  //     interest: item["Interest"],
  //     grandTotal: item["Grand Total"],
  //     payment: item["Payment"],
  //     outstanding: item["Outstanding"],
  //   }));
  //   if (data?.length) exportToCSV(data, "debit-note-report");
  // };
  const handlePrintData = () => {
    navigate({
      pathname: `${PRINT_DEBIT_NOTE_INVOICE_REPORT_ENDPOINT}`,
      search: `?${createSearchParams([...searchParams.entries()])}`,
    });
  };

  const handleInvoice = (selectedItem) => {
    searchParams.delete("page");
    searchParams.delete("pageLimit");
    searchParams.delete("invoice");
    navigate({
      pathname: `${DEBIT_NOTE_INVOICE_REPORT_ENDPOINT}`,
      search: `?${createSearchParams([
        ...searchParams.entries(),
        ["invoice", selectedItem.value],
      ])}`,
    });
  };
  const handleSelectGst = (selectedItem) => {
    searchParams.delete("page");
    searchParams.delete("pageLimit");
    searchParams.delete("gstNo");
    navigate({
      pathname: `${DEBIT_NOTE_INVOICE_REPORT_ENDPOINT}`,
      search: `?${createSearchParams([
        ...searchParams.entries(),
        ["gstNo", selectedItem.value],
      ])}`,
    });
  };
  const handleSearch = (text) => {
    if (text && text !== searchParams.get("memberName")) {
      searchParams.delete("page");
      searchParams.delete("pageLimit");
      searchParams.delete("memberName");
      navigate({
        pathname: `${DEBIT_NOTE_INVOICE_REPORT_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["memberName", text],
        ])}`,
      });
    } else if (!text) {
      searchParams.delete("memberName");
      navigate({
        pathname: `${DEBIT_NOTE_INVOICE_REPORT_ENDPOINT}`,
        search: `?${createSearchParams([...searchParams.entries()])}`,
      });
    }
  };
  const handleInvoiceType = (text) => {
    if (text && text !== searchParams.get("invoiceType")) {
      searchParams.delete("page");
      searchParams.delete("pageLimit");
      searchParams.delete("invoiceType");
      navigate({
        pathname: `${DEBIT_NOTE_INVOICE_REPORT_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["invoiceType", text],
        ])}`,
      });
    }
  };
  //   const handleAmountChange = (clear = false) => {
  //     if (clear) {
  //       searchParams.delete("to_amount");
  //       searchParams.delete("from_amount");
  //       setDefaultFromValue("");
  //       setDefaultToValue("");
  //       navigate({
  //         pathname: `${DEBIT_NOTE_INVOICE_REPORT_ENDPOINT}`,
  //         search: `?${createSearchParams([...searchParams.entries()])}`,
  //       });
  //     } else {
  //       searchParams.delete("to_amount");
  //       searchParams.delete("from_amount");
  //       navigate({
  //         pathname: `${DEBIT_NOTE_INVOICE_REPORT_ENDPOINT}`,
  //         search: `?${createSearchParams([
  //           ...searchParams.entries(),
  //           ["from_amount", defaultFromValue],
  //           ["to_amount", defaultToValue],
  //         ])}`,
  //       });
  //     }
  //   };

  const handelSelectStatus = (selectedItem) => {
    searchParams.delete("page");
    searchParams.delete("status");

    navigate({
      pathname: `${DEBIT_NOTE_INVOICE_REPORT_ENDPOINT}`,
      search: `?${createSearchParams([
        ...searchParams.entries(),
        ["status", selectedItem.value],
      ])}`,
    });
  };
  const handelSelectBillType = (selectedItem) => {
    searchParams.delete("page");
    searchParams.delete("status");
    searchParams.delete("bill_type");

    navigate({
      pathname: `${DEBIT_NOTE_INVOICE_REPORT_ENDPOINT}`,
      search: `?${createSearchParams([
        ...searchParams.entries(),
        ["bill_type", selectedItem.value],
      ])}`,
    });
  };
  const handleSelectDebitorType = (selectedItem) => {
    searchParams.delete("page");
    searchParams.delete("status");
    searchParams.delete("debitor_type");

    navigate({
      pathname: `${DEBIT_NOTE_INVOICE_REPORT_ENDPOINT}`,
      search: `?${createSearchParams([
        ...searchParams.entries(),
        ["debitor_type", selectedItem.value],
      ])}`,
    });
  };
  const handleSelectWing = (selectedItem) => {
    if (selectedItem.value?.toString() !== searchParams.get("wingId")) {
      dispatch(
        getFlatDetails({
          societyId: loginData?.default_community?.community_id,
          wingId: selectedItem?.value,
        })
      );
      searchParams.delete("page");
      searchParams.delete("pageLimit");
      searchParams.delete("wingId");
      searchParams.delete("flatId");
      navigate({
        pathname: `${DEBIT_NOTE_INVOICE_REPORT_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["wingId", selectedItem.value],
        ])}`,
      });
    }
  };

  const handleSelectFlat = (selectedItem) => {
    if (selectedItem.value?.toString() !== searchParams.get("flatId")) {
      searchParams.delete("page");
      searchParams.delete("pageLimit");
      searchParams.delete("flatId");
      navigate({
        pathname: `${DEBIT_NOTE_INVOICE_REPORT_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["flatId", selectedItem.value],
        ])}`,
      });
    }
  };

  const handleStartDate = (selectedDate) => {
    if (
      moment(selectedDate).format("YYYY-MM-DD") !==
      searchParams.get("startDate")
    ) {
      searchParams.delete("page");
      searchParams.delete("pageLimit");
      searchParams.delete("startDate");
      navigate({
        pathname: `${DEBIT_NOTE_INVOICE_REPORT_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["startDate", moment(selectedDate).format("YYYY-MM-DD")],
        ])}`,
      });
    }
  };

  const handleEndDate = (selectedDate) => {
    if (
      moment(selectedDate).format("YYYY-MM-DD") !== searchParams.get("endDate")
    ) {
      searchParams.delete("page");
      searchParams.delete("pageLimit");
      searchParams.delete("endDate");
      navigate({
        pathname: `${DEBIT_NOTE_INVOICE_REPORT_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["endDate", moment(selectedDate).format("YYYY-MM-DD")],
        ])}`,
      });
    }
  };

  const handleClear = () => {
    // setDefaultFromValue("");
    // setDefaultToValue("");
    navigate(`${DEBIT_NOTE_INVOICE_REPORT_ENDPOINT}?invoice=A`);
  };

  useEffect(() => {
    let societyId = loginData?.default_community?.community_id;
    dispatch(getWingsAndFlatDetails({ societyId }));
    if (searchParams.get("wingId")) {
      dispatch(
        getFlatDetails({ societyId, wingId: searchParams.get("wingId") })
      );
    }
    dispatch(
      getInvoiceTypeList({
        societyId: loginData?.default_community?.community_id,
      })
    );

    if (!searchParams.get("invoice")) {
      navigate({
        pathname: `${DEBIT_NOTE_INVOICE_REPORT_ENDPOINT}`,
        search: `?${createSearchParams([["invoice", "A"]])}`,
      });
    }

    // setDefaultFromValue("");
    // setDefaultToValue("");
  }, []);

  const getTableData = () => {
    dispatch(
      getDebitNoteInvoiceReport({
        societyId: loginData?.default_community?.community_id,
        page: searchParams.get("page") || 0,
        pageLimit: searchParams.get("pageLimit") || 10,
        memberName: searchParams.get("memberName") || "",
        invoice: searchParams.get("invoice") || "",
        invoiceType: searchParams.get("invoiceType") || "",
        wingId: searchParams.get("wingId") || "",
        flatId: searchParams.get("flatId") || "",
        gstNo: searchParams.get("gstNo") || "",
        fromDate: searchParams.get("fromDate") || "",
        toDate: searchParams.get("toDate") || "",
        export: 0,
      })
    );
  };
  useEffect(() => {
    getTableData();
  }, [searchParams]);

  useEffect(() => {
    if (debitNoteInvoiceReport?.data?.length)
      setHeadCells(
        Object.entries(debitNoteInvoiceReport.data.slice(-1)[0])
          .filter((item) => item[1] !== 0)
          ?.map((item) => ({
            label: item[0],
            id: item[0].replaceAll(" ", "_"),
            sort: item[0] == "Invoice No" ? false : true,
            width: "130px",
          }))
      );
  }, [debitNoteInvoiceReport]);
  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <div className="border-b border-[#CCC]">
          <ListHeader leftContent="Debit Note Invoice Report">
            {getPermission(loginData, [
              permissions.INVOICE_REPORT_DEBIT_NOTE,
            ]) && (
              <div className="flex text-sm gap-3">
                {/* <button
                type="button"
                onClick={handleDownloadExcel}
                className="flex text-red-650 border border-red-650 bg-white text-xs px-2 font-semibold items-center whitespace-nowrap rounded-lg"
              >
                <UploadEx className="mr-2 fill-red-650" /> Upload Payment Excel
              </button> */}
                <button
                  type="button"
                  onClick={handlePrintData}
                  className="flex text-red-650 border border-red-650 bg-white text-xs px-2 font-semibold items-center whitespace-nowrap rounded-lg"
                >
                  <PrinterIcon className="mr-2 fill-red-650" /> Print
                </button>
                <ButtonIco
                  type="button"
                  onClick={handleDownloadExcel}
                  icon={DownloadExt}
                  children="Download Excel"
                  // className={"opacity-30"}
                />
                {/* <div className="border-r border-[#CCC]"></div> */}
                {/* <Button
                label="Previous"
                className="h-8"
                onClick={handleNavigation(MANAGE_DEBIT_PREVIOUS_ENDPOINT)}
              />
              <Button
                label="Draft"
                className="h-8 px-4"
                onClick={handleNavigation(MANAGE_DEBIT_DRAFT_ENDPOINT)}
              /> */}
                {/* <div className="border-r border-[#CCC]"></div> */}
                {/* <Button
                label="Upload Debit Note"
                onClick={handleNavigation("")}
              /> */}
                {/* <Button
                label="Add Debit Note"
                onClick={handleNavigation(ADD_DEBIT_NOTE_ENDPOINT)}
              /> */}
              </div>
            )}
          </ListHeader>
        </div>
        <>
          <div className="p-1 flex flex-row justify-between items-center gap-4 mt-1">
            <Search
              height="8"
              onclick={handleSearch}
              value={searchParams.get("memberName")}
              placeholder="Member Name"
            />
            <div className="flex justify-between items-center gap-3">
              <div className="text-sm">Invoice</div>
              <Dropdown2
                options={invoice}
                placeholder="Invoice"
                className="text-[11px] ms-4"
                width="172px"
                height="32px"
                onSelect={(selectedItem) => {
                  handleInvoice(selectedItem);
                }}
                value={
                  searchParams.get("invoice") && {
                    value: searchParams.get("invoice"),
                    label: invoice.find(
                      (item) => item?.value == searchParams.get("invoice")
                    )?.label,
                  }
                }
              />
            </div>
            <div className="flex justify-between items-center gap-3">
              <div className="text-sm">Invoice Type</div>
              <Dropdown2
                options={
                  typeLists.length
                    ? typeLists?.map((item) => ({
                        label: item?.invoice_title,
                        value: item?.id,
                      }))
                    : []
                }
                placeholder="Invoice type"
                className="text-[11px] ms-4"
                width="172px"
                height="32px"
                onSelect={(selectedItem) => {
                  handleInvoiceType(selectedItem.value);
                }}
                value={
                  searchParams.get("invoiceType") && {
                    value: searchParams.get("invoiceType"),
                    label: typeLists.length
                      ? typeLists?.find(
                          (item) =>
                            item?.id ===
                            parseInt(searchParams.get("invoiceType"))
                        )?.invoice_title
                      : "",
                  }
                }
              />
              {/* <div className="border-r border-[#CCC] h-full"></div>

           
            <div className="border-r border-[#CCC] h-full"></div> */}

              <div className="flex justify-between gap-3 items-center">
                <RangeDatePicker
                  className={"w-[190px] h-8"}
                  defaultStartValue={
                    searchParams.get("startDate")
                      ? new Date(searchParams.get("startDate"))
                      : ""
                  }
                  defaultEndValue={
                    searchParams.get("endDate")
                      ? new Date(searchParams.get("endDate"))
                      : ""
                  }
                  onStartDateChange={handleStartDate}
                  onEndDateChange={handleEndDate}
                />
              </div>
            </div>
          </div>
          <div className="flex items-center gap-2 p-2">
            <Dropdown2
              options={
                wingsData?.map((item) => ({
                  value: item?.wing_id,
                  label: item?.number,
                })) || []
              }
              placeholder="Select Wing"
              className="text-[11px] ms-4"
              width="172px"
              height="32px"
              onSelect={(selectedItem) => {
                handleSelectWing(selectedItem);
              }}
              value={
                searchParams.get("wingId") && {
                  value: searchParams.get("wingId"),
                  label: wingsData?.find(
                    (item) =>
                      item.wing_id?.toString() === searchParams.get("wingId")
                  )?.number,
                }
              }
            />
            <Dropdown2
              options={
                (searchParams.get("wingId") &&
                  flatsData?.manage_flat_details?.map((item) => ({
                    value: item?.flat_id,
                    label: item?.flat_no,
                  }))) ||
                []
              }
              placeholder="Select Flat"
              className="text-[11px] ms-4"
              width="172px"
              height="32px"
              onSelect={(selectedItem) => {
                handleSelectFlat(selectedItem);
              }}
              value={
                searchParams.get("flatId") && {
                  value: searchParams.get("flatId"),
                  label: flatsData?.manage_flat_details?.find(
                    (item) =>
                      item.flat_id?.toString() === searchParams.get("flatId")
                  )?.flat_no,
                }
              }
            />
            <Dropdown2
              options={gstNo}
              placeholder="GST No"
              className="text-[11px] ms-4"
              width="172px"
              height="32px"
              onSelect={(selectedItem) => {
                handleSelectGst(selectedItem);
              }}
              value={
                searchParams.get("gstNo") && {
                  value: searchParams.get("gstNo"),
                  label: gstNo.find(
                    (item) => item.value == searchParams.get("gstNo")
                  )?.label,
                }
              }
            />
            {/* <Dropdown2
            options={
              manageDebitNoteCurrent?.data?.invoice_type
                ? Object.entries(
                    manageDebitNoteCurrent?.data?.invoice_type
                  )?.map((item) => ({
                    label: item?.[1],
                    value: item?.[0],
                  }))
                : []
            }
            placeholder="Select Bill Type"
            className="text-[11px] ms-4"
            width="172px"
            height="32px"
            onSelect={(selectedItem) => {
              handelSelectBillType(selectedItem);
            }}
            value={
              searchParams.get("bill_type") && {
                value: searchParams.get("bill_type"),
                label:
                  manageDebitNoteCurrent?.data?.invoice_type?.[
                    searchParams.get("bill_type")
                  ],
              }
            }
          />
          <Dropdown2
            options={
              manageDebitNoteCurrent?.data?.debitor_type
                ? Object.entries(
                    manageDebitNoteCurrent?.data?.debitor_type
                  )?.map((item) => ({
                    label: item?.[1],
                    value: item?.[0],
                  }))
                : []
            }
            placeholder="Select Debitor Type"
            className="text-[11px] ms-4"
            width="172px"
            height="32px"
            onSelect={(selectedItem) => {
              handleSelectDebitorType(selectedItem);
            }}
            value={
              searchParams.get("debitor_type") && {
                value: searchParams.get("debitor_type"),
                label:
                  manageDebitNoteCurrent?.data?.debitor_type?.[
                    searchParams.get("debitor_type")
                  ],
              }
            }
          /> */}
            <ButtonG label="Clear" className="h-8" onClick={handleClear} />
          </div>
          {/* <div>
          <div className="flex justify-end gap-3 items-center my-2">
            <div className="text-sm">Due Filter</div>
            <FilterTextField
              defaultFromValue={defaultFromValue && defaultFromValue}
              defaultToValue={defaultToValue && defaultToValue}
              onAmountFromChange={(e) => {
                setDefaultFromValue(e.target.value);
              }}
              onAmountToChange={(e) => {
                setDefaultToValue(e.target.value);
              }}
            />
            <Button
              label="Search"
              type="button"
              onClick={() => {
                handleAmountChange();
              }}
              className={`${
                !defaultFromValue || !defaultToValue ? "opacity-50" : ""
              }`}
              disabled={!defaultFromValue || !defaultToValue}
            />
            <Button
              label="Reset"
              type="button"
              className={`${
                !defaultFromValue || !defaultToValue ? "opacity-50" : ""
              }`}
              onClick={() => handleAmountChange(true)}
              disabled={!defaultFromValue || !defaultToValue}
            />
          </div>
        </div> */}
          <div>
            <DebitNoteInvoiceReportTable
              loading={loading}
              data={debitNoteInvoiceReport?.data}
              columns={headCells}
              checkbox={false}
              pagination={debitNoteInvoiceReport?.data?.length}
              totalCount={debitNoteInvoiceReport?.paginate?.total_count}
              totalPages={debitNoteInvoiceReport?.paginate?.total_page}
              start={
                debitNoteInvoiceReport?.paginate?.current *
                  debitNoteInvoiceReport?.paginate?.limit +
                1
              }
              end={
                debitNoteInvoiceReport?.paginate?.current *
                  debitNoteInvoiceReport?.paginate?.limit +
                debitNoteInvoiceReport?.data?.length -
                1
              }
              currentPage={Number(debitNoteInvoiceReport?.paginate?.current)}
              rowsPerPage={searchParams.get("pageLimit") || 10}
              getTableData={getTableData}
              selected={selected}
              setSelected={setSelected}
            ></DebitNoteInvoiceReportTable>

            {!debitNoteInvoiceReport?.data?.length && !loading && (
              <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
                No Records
              </div>
            )}
            {/* {isUploadModalOpen && (
            <UploadDebitNoteExcelModal
              isOpen={isUploadModalOpen}
              onClose={handleCloseUploadModal}
              buttons={[
                "Download Sample",
                // "Download CSV Sample",
                // "Download Format",
              ]}
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              file={file}
              setFile={setFile}
              onUpload={onUpload}
              // invoiceTypeList={invoiceTypeList}
              // setInvoicetype={setInvoicetype}
              // invoiceType={invoiceType}
              // downloadCSVSampleLink={handleDownloadSampleLink}
              downloadSampleLink={handleDownloadExcel}
              // downloadFormatLink={handleDownloadFormatLink}
            />
          )} */}
          </div>
        </>
      </div>
    </Layout>
  );
};

export default DebitNoteInvoiceReport;
