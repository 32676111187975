// auth module 
export const LOGIN_ENDPOINT = "/account/login"
export const RESET_PASSWORD_ENDPOINT = "/account/reset-password/:token"
export const FORGOTPASSWORD_ENDPOINT = "/account/forgot-password"
export const AUTO_LOGIN_ENDPOINT = "/account/auto-login"
// dashboard module 

export const HOME_ENDPOINT = "/home"

// employee module 
export const EMPLOYEE_ENDPOINT = "/employee"
export const ADD_EMPLOYEE_ENDPOINT = "/employee/add-employee"
export const EXISTING_EMPLOYEE_ENDPOINT = "/employee/add-from-existing"
export const EMPLOYEE_LOGIN_REPORT_ENDPOINT = "/employee/employee-login-report"
export const PRINT_EMPLOYEE_LOGIN_REPORT_ENDPOINT = "/employee/employee-login-report/print"
export const EMPLOYEE_RECORDS_ENDPOINT = "/employee/employee-records"
export const PRINT_EMPLOYEE_RECORDS_ENDPOINT = "/employee/employee-records/print"
export const VIEW_EMPLOYEE_DETAILS_ENDPOINT = "/employee/view-employee-details"
export const EDIT_EMPLOYEE_DETAILS_ENDPOINT = "/employee/edit-employee-details"
export const AUDIT_EMPLOYEE_DETAILS_ENDPOINT = "/employee/audit-employee-details"

// contact module 
export const CONTACT_ENDPOINT = "/contact"
export const ADD_CONTACT_ENDPOINT = "/contact/add-contact"
export const MANAGE_CATEGORY_ENDPOINT = "/contact/manage-category"
export const VIEW_CONTACT_DETAILS_ENDPOINT = "/contact/view-contact-details"
export const RESIDENT_VENDORS_ENDPOINT = "/contact/resident-vendors"
export const ADD_RESIDENT_VENDOR_ENDPOINT = "/contact/resident-vendors/add-resident-vendor"
export const SOCIETY_VENDORS_ENDPOINT = "/contact/society-vendors"
export const ARCHIVEDAMC_ENDPOINT = "/contact/society-vendors/archived-amc"
export const ADD_SOCIETY_VENDOR_ENDPOINT = "/contact/society-vendors/add-vendor"
export const VENDOR_AUDIT_LOG_ENDPOINT = "/contact/society-vendors/vendor-audit-log"

// quick links 
export const DOCUMENTS_ENDPOINT = "/documents"
export const MEMBERS_LIST_ENDPOINT = "/membersList"
export const MANAGE_BILLS_ENDPOINT = "/manageBills"
export const IREGISTER_ENDPOINT = "/Iregister"
export const BOOK_FACILITY_ENDPOINT = "/bookfacility"

// setup & records 
// building 
export const SOCIETY_PROFILE_ENDPOINT = "/society-profile"
export const EDITSOCIETY_PROFILE_ENDPOINT = "/society-profile/edit-society-profile"
export const SOCIETY_PROFILE_AUDIT_LOGS_ENDPOINT = "/society-profile/society-profile-audit-logs"
export const CHANGE_PASSWORD_ENDPOINT = "/society-profile/change-password"
export const SPECIFIC_DETAILS_ENDPOINT = "/specific-details"
export const WINGS_AND_FLATS_ENDPOINT = "/wings-and-flats"
export const WINGS_AND_FLATS_AUDIT_LOG_ENDPOINT = "/wings-and-flats/wings-audit-logs"
export const BANK_DETAILS_ENDPOINT = "/bank-details"
export const BANK_DETAILS_AUDIT_LOG_ENDPOINT = "/bank-details/bank-details-audit-logs"
export const CREATE_BANK_ACCOUNT_ENDPOINT = "/bank-details/create-bank-account"
export const ADD_BANK_ACCOUNT_ENDPOINT = "/bank-details/add-bank-account"
export const MANAGE_FLATS_ENDPOINT = "/wings-and-flats/manage-flat"
export const BILL_TARRIF_SETUP_ENDPOINT = "/wings-and-flats/bill-tarrif-setup"
export const UTILITY_READING_ENDPOINT = "/wings-and-flats/utility-reading"

// occupants info 
export const OCCUPANTS_INFO_ENDPOINT = "/occupants-info"
export const OCCUPANTS_AUDIT_LOG_ENDPOINT = "/occupants-info/audit-log"
export const ADD_OCCUPANTS_ENDPOINT = "/occupants-info/add-occupants"
export const EDIT_MEMBER_DETAILS_ENDPOINT = "/occupants-info/edit-member-details"
export const MEMBER_DETAILS_ENDPOINT = "/occupants-info/member-details"
export const MEMBER_PRIVACY_ENDPOINT = "/occupants-info/member-privacy"
export const ARCHIVE_MEMBERS_OCCUPANTS_ENDPOINT = "/occupants-info/archive-members"
export const ADD_FROM_EXISITING_OCCUPANT_ENDPOINT = "/occupants-info/add-from-existing-occupant"
export const MORTGAGE_FORM_ENDPOINT = "/occupants-info/mortgage-register"
export const FAMILY_MEMBERS_LIST_ENDPOINT = "/occupants-info/family-members"
export const VIEW_FAMILY_MEMBERS_ENDPOINT = "/occupants-info/family-members/view-family-member"
export const ADD_FAMILY_MEMBERS_ENDPOINT = "/occupants-info/family-members/add-family-member"
export const EDIT_FAMILY_MEMBERS_ENDPOINT = "/occupants-info/family-members/edit-family-member"
export const VEHICLE_DETAILS_ENDPOINT = "/occupants-info/vehicles-details"
export const NOMINEES_DETAILS_ENDPOINT = "/occupants-info/nominees-details"
export const REVOKE_DETAILS_LIST_ENDPOINT = "/occupant-info/nominees-details/revoke-list"
export const UPLOAD_FLAT_DOCUMENTS_ENDPOINT = "/occupant-info/upload-flat-documents"
export const OCCUPANTS_STAFF_TABLE_ENDPOINT = "/occupant-info/edit-member-details/staff-list"
export const MEMBER_STAFF_DETAILS_ENDPOINT = "/occupants-info/staff-details"
export const ADD_MEMBER_STAFF_ENDPOINT = "/occupants-info/add-staff-details"
export const EDIT_MEMBER_STAFF_ENDPOINT = "/occupants-info/edit-staff-details"

// tenants info 
export const TENANTS_INFO_ENDPOINT = "/tenants-info"
export const TENANTS_AUDIT_LOG_ENDPOINT = "/tenants-info/audit-log"
export const VIEW_TENANTS_DETAILS_ENDPOINT = "/tenants-info/view-tenants-details"
export const EDIT_TENANTS_DETAILS_ENDPOINT = "/tenants-info/edit-tenants-details"
export const ADD_TENANTS_ENDPOINT = "/tenants-info/add-tenant"
export const ADD_FROM_EXISITING_TENANT_ENDPOINT = "/tenants-info/add-from-existing-tenant"
export const ARCHIVE_TENANTS_ENDPOINT = "/tenants-info/archive-tenants"
export const RENEW_TENANCY_ENDPOINT = "/tenants-info/renew-tenancy"
export const ONLINE_REGISTRATION_FORM_FOR_RENT_PAYMENT_ENDPOINT = "/tenants-info/online-registration-form-for-rent-payment"
export const VIEW_ONLINE_REGISTRATION_FORM_FOR_RENT_PAYMENT_ENDPOINT = "/tenants-info/view-online-registration-form-for-rent-payment"
export const RENT_REGISTRATION_LIST_ENDPOINT = "/tenants-info/online-registration-form-for-rent-payment/rent-registration-list"
export const ACCESS_SETTINGS_ENDPOINT = "/tenants-info/access-settings"
export const LEAVE_AND_LICENSE_ENDPOINT = "/leave-and-license"
export const APPLY_FOR_TENANCY_ENDPOINT = "/tenant-application-form"
export const VIEW_INVITES_ENDPOINT = "/view-invites"
export const PRINT_TENANTS_INFO_ENDPOINT = "/tenants-info/print"

// staff management
export const SOCIETY_STAFF_ENDPOINT = "/society-staff-list"
export const ADD_SOCIETY_STAFF_ENDPOINT = "/society-staff-list/add-society-staff"
export const EDIT_SOCIETY_STAFF_ENDPOINT = "/society-staff-list/edit-society-staff"
export const VIEW_SOCIETY_STAFF_DETAILS_ENDPOINT = "/society-staff-list/view-society-staff-details"
export const SOCIETY_STAFF_ID_CARD_ENDPOINT = "/society-staff-list/id-card"
export const MAINTENANCE_STAFF_LIST_ENDPOINT = "/maintenance-staff"
export const VIEW_MAINTENANCE_STAFF_LIST_ENDPOINT = "/maintenance-staff/view-maintenance-staff"
export const EDIT_MAINTENANCE_STAFF_LIST_ENDPOINT = "/maintenance-staff/edit-maintenance-staff"
export const ADD_MAINTENANCE_STAFF_FORM_ENDPOINT = "/maintenance-staff/add-maintenance-staff"
export const MAINTENANCE_STAFF_ID_CARD_ENDPOINT = "/maintenance-staff/id-card"
export const AGENCY_ENDPOINT = "/agency"
export const ADD_AGENCY_ENDPOINT = "/agency/add-agency"
export const EDIT_AGENCY_ENDPOINT = "/agency/edit-agency"
export const VIEW_AGENCY_ENDPOINT = "/agency/view-agency"
export const MANAGING_COMMITTEE_ENDPOINT = "/managing-committee"
export const COMMITTEE_DETAILS_AUDIT_ENDPOINT = "/managing-committee/committee-details-audit-log"
export const COMMITTEE_DETAILS_MEMBERS_AUDIT_ENDPOINT = "/managing-committee/committee-details-members-audit-log"
export const ARCHIVE_COMMITTEE_MEMBERS_ENDPOINT = "/managing-committee/archive-committee-member"
export const VIEW_ARCHIVE_COMMITTEE_MEMBERS_ENDPOINT = "/managing-committee/archive-committee-member/view"
export const ADD_COMMITTEE_FORM_ENDPOINT = "/managing-committee/add-committee"
export const EDIT_COMMITTEE_FORM_ENDPOINT = "/managing-committee/edit-committee"
export const VIEW_COMMITTEE_FORM_ENDPOINT = "/managing-committee/view-committee"
export const RETIRE_COMMITTEE_MEMBER_FORM_ENDPOINT = "/managing-committee/view-committee/retire-committee-member"
export const RESIGN_COMMITTEE_MEMBER_FORM_ENDPOINT = "/managing-committee/view-committee/resign"
export const VIEW_ACCESS_ENDPOINT = "/managing-committee/view-access"
export const COMMITTEE_ACCESS_SETTINGS_ENDPOINT = "/managing-committee/committee-access-settings"
export const VIEW_COMMITTEE_ACCESS_SETTINGS_ENDPOINT = "/managing-committee/view-committee-access-settings"
export const APPROVE_RESIGN_ENDPOINT = "/managing-committee/approve-resign"
export const REAPPOINT_MEMBER_ENDPOINT = "/managing-committee/reappoint-member"

// billing and invoice setup 
export const ACCOUNT_LEDGER_MAPPING_ENDPOINT = "/billing-and-invoice/account-ledger-mapping"
export const ADD_MORE_HEADS_ENDPOINT = "/billing-and-invoice/add-more-headers"
export const EDIT_MORE_HEADS_ENDPOINT = "/billing-and-invoice/edit-more-headers"
export const INVOICE_LIST_ENDPOINT = "/billing-invoice/invoice-list"
export const EDIT_INVOICE_LIST_ENDPOINT = "/billing-invoice/edit-invoice-list"
export const INVOICE_SETTINGS_FUNCTIONAL_SETTING_ENDPOINT = "/billing-and-invoice/invoice-setting-functional-setting"
export const INVOICE_SETTINGS_TAXABLE_INVOICE_ENDPOINT = "/billing-and-invoice/invoice-setting-taxable-invoice-format"
export const INVOICE_SETTINGS_TAXABLE_INVOICE_SUBHEAD_ENDPOINT = "/billing-and-invoice/invoice-setting-taxable-invoice-format-sub-head"
export const INVOICE_SETTINGS_DISPLAY_SETTINGS_ENDPOINT = "/billing-and-invoice/invoice-setting-dispalay-setting"
export const GST_AND_INVOICE_SETTINGS_ENDPOINT = "/billing-and-invoice/gst-and-noc-setting"
export const INVOICE_SERIES_SETUP_ENDPOINT = "/billing-and-invoice/invoice-series-setup"
export const DEBIT_NOTE_SETTINGS_ENDPOINT = "/billing-and-invoice/debit-note-settings"
export const CREDIT_NOTE_SETTINGS_ENDPOINT = "/billing-and-invoice/credit-note-settings"
//Desk route
export const COMPLAINT_DASHBOARD_ENDPOINT = "/desk/complaint-dashboard"
export const COMPLAINT_AGING_ENDPOINT = "/desk/complaint-aging"
export const VIEW_COMPLAINTS_ENDPOINT = "/desk/view-complaints"
export const EDIT_COMPLAINTS_ENDPOINT = "/desk/edit-complaints"
export const POST_COMMENTS_ENDPOINT = "/desk/complaint/post-comment"
export const ASSIGN_COMPLAINTS_ENDPOINT = "/desk/assign-complaints"
export const PRINT_COMPLAINT_ENDPOINT = "/desk/print-complaints"

export const LOG_A_COMPLAINT_ENDPOINT = "/desk/log-a-complaint/"
export const ALL_DOCUMENTS_ENDPOINT = "/desk/log-a-complaint/all-documents"
export const ALL_FOLDERS_ENDPOINT = "/desk/log-a-complaint/all-folders"
export const CREATE_FOLDER_ENDPOINT = "/desk/log-a-complaint/create-folder"
export const CREATE_SUBFOLDER_ENDPOINT = "/desk/log-a-complaint/create-subfolder"
export const UPLOAD_DOCUMENT_ENDPOINT = "/desk/log-a-complaint/upload-document"
export const EDIT_DOCUMENT_ENDPOINT = "/desk/log-a-complaint/edit-document"

export const MANAGE_COMPLAINT_CATEGORY_ENDPOINT = "/desk/manage-complaint-category"
export const ADD_COMPLAINT_CATEGORY_ENDPOINT = "/desk/add-complaint-category"
export const EDIT_COMPLAINT_CATEGORY_ENDPOINT = "/desk/edit-complaint-category"

export const COMPLAINT_ESCALATION_LEVEL_SETTINGS_ENDPOINT = "/desk/complaint-escalation-level-setting"
export const EDIT_COMPLAINT_ESCALATION_LEVEL_SETTINGS_ENDPOINT = "/desk/complaint-escalation-level-setting/edit"

export const COMPLAINT_SMS_EMAIL_SETTING_ENDPOINT = "/desk/complaint-sms-email-setting"

export const COMPLAINT_SETTINGS_ENDPOINT = "/desk/complaint-setting"
export const COMPLAINT_TYPE_SETTINGS_ENDPOINT = "/desk/complaint-type-setting"
export const GENERAL_COMPLAINT_SETTINGS_ENDPOINT = "/desk/general-complaint-settings"


// finance module 
export const MY_INVOICE_ENDPOINT = "/finance/my-invoice"
export const VIEW_MY_INVOICE_ENDPOINT = "/finance/my-invoice/view-invoice"
export const MYINVOICE_MAKE_PAYMENT_ENDPOINT = "/finance/Invoice/make-payment"

export const CREDIT_DEBIT_NOTE_CAUSE_ENDPOINT = "/finance-settings/credit-debit-note-cause"
export const VIEW_NOTE_CAUSE_ENDPOINT = "/finance-settings/credit-debit-note-cause/view-note-cause"
export const ADD_CAUSE_ENDPOINT = "/finance-settings/credit-debit-note-cause/add-credit-debit-note-cause"
export const EDIT_CAUSE_ENDPOINT = "/finance-settings/credit-debit-note-cause/edit-credit-debit-note-cause"
export const CREDIT_DEBIT_NOTE_CAUSE_AUDIT_LOGS_ENDPOINT = "/finance-settings/credit-debit-note-cause/credit-debit-audit-logs"
export const CREDIT_DEBIT_NOTE_CAUSE_ALL_AUDIT_LOGS_ENDPOINT = "/finance-settings/credit-debit-note-cause/credit-debit-all-audit-logs"
export const DUE_REMINDER_ENDPOINT = "/finance-setting/due-reminder"
export const DEPOSIT_DUE_REMINDER_ENDPOINT = "/finance-setting/deposit-due-reminder"


export const INVOICE_DETAILS_MAINTENANCE_AND_OTHER_CHARGES = "/finance-setting/invoice-details-maintenance-and-other-charges"
export const MEMBER_DUE_REMINDER_ENDPOINT = "/finance-setting/member-due-reminder"
export const FIXED_DEPOSIT_MATURITY_REMINDER_ENDPOINT = "/finance-setting/fixed-deposit-maturity-reminder"
export const NOMINATION_REMINDER_ENDPOINT = "/finance-setting/nomination-reminder"
export const MANAGE_ADVANCE_NOTE_ENDPOINT = "/finance/billing-management/manage-advance-note"
export const PRINT_MANAGE_ADVANCE_NOTE_ENDPOINT = "/finance/billing-management/print-advance-note-list"
export const ADD_ADVANCE_NOTE_ENDPOINT = "/finance/billing-management/manage-advance-note/add-advance-note"
export const UPLOAD_ADVANCE_NOTE_ENDPOINT = "/finance/billing-management/manage-credit-note/upload-advance-note"
export const EDIT_ADVANCE_NOTE_ENDPOINT = "/finance/billing-management/manage-advance-note/edit-advance-note"
export const PRINT_ADVANCE_NOTE_RECIEPT_ENDPOINT = "/finance/billing-management/manage-advance-note/print-advance-note-reciept"
export const DEBIT_NOTE_HISTORY_ENDPOINT = "/finance/billing-report/debit-note-history"
export const PRINT_DEBIT_NOTE_HISTORY_ENDPOINT = "/finance/billing-report/print-debit-note-history"
export const DELETE_RECEIPT_ENDPOINT = "/finance/billing-report/delete-receipt"
export const VIEW_DELETED_RECEIPT_ENDPOINT = "/finance/billing-report/view-delete-receipt"
export const CHEQUE_DEPOSIT_SLIP_ENDPOINT = "/finance/billing-report/cheque-deposit-slip-pay-slip"
export const COUNTED_INVOICE_REPORT_ENDPOINT = "/finance/billing-report/counted-invoice-report"
export const PRINT_CHEQUE_DEPOSIT_SLIP_ENDPOINT = "/finance/billing-report/print-cheque-deposit-slip-pay-slip"
export const RECEIPTS_TRANSACTIONS_ENDPOINT = "/finance/billing-report/receipts-transactions"
export const VIEW_RECEIPTS_TRANSACTIONS_ENDPOINT = "/finance/billing-report/view-receipts-transactions"
export const PRINT_RECEIPTS_TRANSACTIONS_ENDPOINT = "/finance/billing-report/print-receipts-transactions"
export const INCOMPLETE_TRANSACTIONS_ENDPOINT = "/finance/billing-report/receipts-transactions/incomplete-transactions"
export const VIEW_INCOMPLETE_TRANSACTIONS_ENDPOINT = "/finance/billing-report/view-incomplete-transactions"
export const MEMBER_DUE_SUMMARY_ENDPOINT = "/finance/billing-report/member-due-summary"
export const PRINT_MEMBER_DUE_SUMMARY_ENDPOINT = "/finance/billing-report/print-member-due-summary"
export const MEMBER_BILL_DUE_DETAILS_ENDPOINT = "/finance/billing-report/member-bill-due-report"
export const PRINT_MEMBER_BILL_DUE_DETAILS_ENDPOINT = "/finance/billing-report/print-member-bill-due-report"
export const OPENING_BALANCE_ENDPOINT = "/finance/billing-report/opening-balance"


export const MANAGE_INVOICE_CURRENT_ENDPOINT = "/finance/billing-management/manage-invoice-current"
export const MANAGE_INVOICE_PREVIOUS_ENDPOINT = "/finance/billing-management/manage-invoice-previous"
export const MANAGE_INVOICE_DRAFTS_ENDPOINT = "/finance/billing-management/manage-invoice-draft"
export const VIEW_MANAGE_INVOICE_CURRENT_ENDPOINT = "/finance/billing-management/manage-invoice-current/view-invoice"
export const VIEW_MANAGE_INVOICE_PREVIOUS_ENDPOINT = "/finance/billing-management/manage-invoice-previous/view-invoice"

export const UPLOAD_PAYMENT_EXCEL_ENDPOINT = "/finance/billing-management/manage-invoice/upload-payment-excel"
export const MULTI_PAYMENT_ENDPOINT = "/finance/billing-management/manage-invoice/multi-payment"

export const UPLOAD_GENERATE_INVOICE_ENDPOINT = "/finance/billing-management/manage-invoice/generate-invoice/upload-excel-format"

export const GENERATE_INVOICE_ENDPOINT = "/finance/billing-management/manage-invoice/generate-invoice"
export const GENERATE_CURRENT_INVOICE_ENDPOINT = "/finance/billing-management/manage-invoice/generate-current-invoice"
export const EDIT_INVOICE_ENDPOINT = "/finance/billing-management/manage-invoice/edit-invoice"

export const EDIT_DRAFT_INVOICE_ENDPOINT = "/finance/billing-management/manage-invoice/edit-draft-invoice"
export const EDIT_DRAFT_INVOICE_ENDPOINT_MULTI = "/finance/billing-management/manage-invoice/edit-draft-invoice-multi"

export const MAKE_PAYMENT_ENDPOINT = "/finance/make-payment"

export const PAYMENT_SUCCESS_ENDPOINT = "/finance/make-payment/payment-success"

export const EDIT_PAYMENT_ENDPOINT = "/finance/edit-payment"

export const EDIT_RECEIPT_ENDPOINT = "/finance/edit-receipt"

export const PAYMENT_AND_ADJUSTMENT_ENDPOINT = "/finance/billing-management/manage-invoice/payment-and-adjustment"

export const MANAGE_DEBIT_CURRENT_ENDPOINT = "/finance/billing-management/manage-debit-note-current"
export const MANAGE_DEBIT_PREVIOUS_ENDPOINT = "/finance/billing-management/manage-debit-note-previous"
export const MANAGE_DEBIT_DRAFT_ENDPOINT = "/finance/billing-management/manage-debit-note-draft"
export const ADD_DEBIT_NOTE_ENDPOINT = "/finance/billing-management/manage-debit-note/add-debit-note"
export const ADD_MULTIPLE_DEBIT_NOTE_ENDPOINT = "/finance/billing-management/manage-debit-note/add-multiple-debit-note"
export const VIEW_MANAGE_DEBIT_NOTE_ENDPOINT = "/finance/billing-management/manage-debit-note/view-debit-note-details"
export const EDIT_MANAGE_DEBIT_NOTE_ENDPOINT = "/finance/billing-management/manage-debit-note/edit-debit-note";
export const EDIT_MANAGE_DEBIT_NOTE_DRAFT_ENDPOINT = "/finance/billing-management/manage-debit-note/edit-debit-note-draft";
export const PAYMENT_AND_ADJUSTMENT_DEBIT_NOTE_ENDPOINT = "/finance/billing-management/manage-debit-note/payment-and-adjustment";
export const MAKE_PAYMENT_DEBIT_NOTE_ENDPOINT = "/finance/billing-management/manage-debit-note/make-payment";
export const EDIT_PAYMENT_DEBIT_NOTE_ENDPOINT = "/finance/billing-management/manage-debit-note/edit-payment";
export const MAKE_PAYMENT_DEBIT_NOTE_MULTI_ENDPOINT = "/finance/billing-management/manage-debit-note/make-payment-multi";
export const MAKE_PAYMENT_SUCCESS_DEBIT_NOTE_ENDPOINT = "/finance/billing-management/manage-debit-note/make-payment/payment-success";
export const PRINT_DEBIT_NOTE_INVOICE_ENDPOINT = "/finance/billing-management/manage-debit-note/print-debit"
export const PRINT_DEBIT_NOTE_INVOICE_MULTI_ENDPOINT = "/finance/billing-management/manage-debit-note/print-debit-multi"
export const PRINT_DEBIT_NOTE_RECEIPT_ENDPOINT = "/finance/billing-management/manage-debit-note/print-receipt"
export const PRINT_DEBIT_NOTE_RECEIPT_MULTI_ENDPOINT = "/finance/billing-management/manage-debit-note/print-receipt-multi"

export const MANAGE_CREDIT_NOTE_ENDPOINT = "/finance/billing-management/manage-credit-note"
export const ADD_CREDIT_NOTE_ENDPOINT = "/finance/billing-management/manage-credit-note/add-credit-note"
export const EDIT_CREDIT_NOTE_ENDPOINT = "/finance/billing-management/manage-credit-note/edit-credit-note"
export const UPLOAD_CREDIT_NOTE_ENDPOINT = "/finance/billing-management/manage-credit-note/upload-credit-note"
export const CREDIT_NOTE_PAYMENT_ENDPOINT = "/finance/billing-management/manage-credit-note/credit-note-payment"

export const PRINT_CREDIT_NOTE_RECIEPT_ENDPOINT = "/finance/billing-management/manage-credit-note/print-credit-note-reciept"
export const PRINT_INVOICE_RECEIPT_ENDPOINT = "/finance/my-invoice/view-invoice/print-receipt"
export const PRINT_INVOICE_RECEIPT_MULTI_ENDPOINT = "/finance/my-invoice/view-invoice/print-receipt-multi"
export const PRINT_INVOICE_BILL_ENDPOINT = "/finance/my-invoice/view-invoice/print-invoice"
export const PRINT_INVOICE_BILL_MULTI_ENDPOINT = "/finance/my-invoice/view-invoice/print-invoice-multi"


// Bulk Uploads

export const BULK_UPLOAD_OCCUPANTS_ENDPOINT = "/bulk-upload-occupants"
export const BULK_UPLOAD_TENANTS_ENDPOINT = "/bulk-upload-tenants"
export const BULK_UPLOAD_STAFF_PERSONNEL_ENDPOINT = "/bulk-upload/staff-personnel"
export const UPDATE_MEMBED_EXCEL_ENDPOINT = "/bulk-upload-occupants/update-member"
export const BULK_UPLOAD_OPENING_BALANCE_ENDPOINT = "/bulk-upload/opening-balance-upload"

// Society Profile Setup 

export const REVIEW_ENDPOINT = "/society-profile-setup/review"
export const CONTENT_MANAGEMENT_TEMPLATE = "/society-profile-setup/content-management-template"
export const VIEW_CONTENT_MANAGEMENT_TEMPLATE = "/society-profile-setup/content-management-template/view-content"
export const EDIT_CONTENT_MANAGEMENT_TEMPLATE = "/society-profile-setup/content-management-template/edit-content"
export const AUDIT_LOGS_CONTENT_MANAGEMENT_TEMPLATE = "/society-profile-setup/content-management-template/audit-logs-content-managment"
export const SMS_CREDIT_PACKAGES_ENDPOINT = "/society-profile-setup/sms-credit-packages"
export const SMS_CREDIT_ORDER_HISTORY_ENDPOINT = "/society-profile-setup/sms-credit-packages/sms-credit-order-history"
export const VIEW_SMS_CREDIT_ORDER_HISTORY_ENDPOINT = "/society-profile-setup/sms-credit-packages/sms-credit-order-history/view"
export const SMS_HISTORY_LIST_ENDPOINT = "/society-profile-setup/sms-history-list"


// manage roles and rights 

export const MANAGE_EMPLOYEE_ROLES_ENDPOINT = "/manage-roles-and-rights/employee-roles"
export const ADD_EMPLOYEE_ROLES_ENDPOINT = "/manage-roles-and-rights/add-employee-roles"
export const VIEW_EMPLOYEE_ROLES_ENDPOINT = "/manage-roles-and-rights/view-employee-roles"
export const EDIT_EMPLOYEE_ROLES_ENDPOINT = "/manage-roles-and-rights/edit-employee-roles"
export const MANAGE_OCCUPANT_ROLES_ENDPOINT = "/manage-roles-and-rights/occupant-roles"
export const ADD_OCCUPANT_ROLES_ENDPOINT = "/manage-roles-and-rights/add-occupant-roles"
export const EDIT_OCCUPANT_ROLES_ENDPOINT = "/manage-roles-and-rights/edit-occupant-roles"
export const VIEW_OCCUPANT_ROLES_ENDPOINT = "/manage-roles-and-rights/view-occupant-roles"
export const MANAGE_SOCIETY_ROLES_ENDPOINT = "/manage-roles-and-rights/society-roles"
export const MANAGE_FAMILY_ROLES_ENDPOINT = "/manage-roles-and-rights/family-roles"
export const ADD_FAMILY_ROLES_ENDPOINT = "/manage-roles-and-rights/add-family-roles"
export const EDIT_FAMILY_ROLES_ENDPOINT = "/manage-roles-and-rights/edit-family-roles"
export const VIEW_FAMILY_ROLES_ENDPOINT = "/manage-roles-and-rights/view-family-roles"
export const SOCIETY_FORMS_AND_BYLAWS_ENDPOINT = "/society-forms-and-bylaws"


// reports 

export const SOCIETY_EMAIL_REPORT_ENDPOINT = "/reports/society-report/society-email-report"
export const MEMBER_LEDGER_REPORT_ENDPOINT = "/reports/society-report/member-ledger-report"

// finance report
export const DEBIT_NOTE_INVOICE_REPORT_ENDPOINT = "/reports/finance-report/debit-note-invoice-report"
export const PRINT_DEBIT_NOTE_INVOICE_REPORT_ENDPOINT = "/reports/finance-report/print-debit-note-invoice-report"
export const INVOICE_REPORT_ENDPOINT = "/reports/finance-report/invoice-report"
export const PRINT_INVOICE_REPORT_ENDPOINT = "/reports/finance-report/print-invoice-report"
export const RECEIPT_REPORT_ENDPOINT = "/reports/finance-report/receipt-report"
export const PRINT_RECEIPT_REPORT_ENDPOINT = "/reports/finance-report/print-receipt-report"
export const SUPPLEMENTARY_BILL_REPORT_ENDPOINT = "/reports/finance-report/supplementary-bill-report"
export const PRINT_SUPPLEMENTARY_BILL_REPORT_ENDPOINT = "/reports/finance-report/print-supplementary-bill-report"
export const DEBIT_NOTE_RECEIPT_REPORT_ENDPOINT = "/reports/finance-report/debit-note-receipt-report"
export const PRINT_DEBIT_NOTE_RECEIPT_REPORT_ENDPOINT = "/reports/finance-report/print-debit-note-receipt-report"
export const DELETED_INVOICE_REPORT_ENDPOINT = "/reports/finance-report/deleted-invoice-report"
export const PRINT_DELETED_INVOICE_REPORT_ENDPOINT = "/reports/finance-report/print-deleted-invoice-report"
export const DELETED_DEBIT_NOTE_REPORT_ENDPOINT = "/reports/finance-report/deleted-debit-note-report"
export const PRINT_DELETED_DEBIT_NOTE_REPORT_ENDPOINT = "/reports/finance-report/print-deleted-debit-note-report"
export const INVOICE_INTEREST_REPORT_ENDPOINT = "/reports/finance-report/invoice-interest-report"
export const PREV_INVOICE_INTEREST_REPORT_ENDPOINT = "/reports/finance-report/prev-invoice-interest-report"
export const INTEREST_CALCULATION_REPORT_ENDPOINT = "/reports/finance-report/interest-calculation-report"
export const PRINT_INTEREST_CALCULATION_REPORT_ENDPOINT = "/reports/finance-report/print-interest-calculation-report"
export const RECONCILIATION_REPORT_ENDPOINT = "/reports/finance-report/reconciliation-report"
export const PRINT_RECONCILIATION_REPORT_ENDPOINT = "/reports/finance-report/print-reconciliation-report"
export const DEBITNOTE_INTEREST_CALCULATION_REPORT_ENDPOINT = "/reports/finance-report/debitnote-interest-calculation-report"
export const PRINT_DEBITNOTE_INTEREST_CALCULATION_REPORT_ENDPOINT = "/reports/finance-report/print-debitnote-interest-calculation-report"



// gate management 
export const VISITOR_DASHBOARD_ENDPOINT = "/gate/visitor-management"
export const PRINT_VISITOR__LOGS_ENDPOINT = "/gate/print-visitor-logs"
export const PRINT_VISITOR_PASS_ENDPOINT = "/gate/print-visitor-pass"
export const MANAGE_PARKING_TYPE_ENDPOINT = "/gate/parking-management/manage-parking-type"
export const SOCIETY_PARKING_CHARGES_ENDPOINT = "/gate/parking-management/society-parking-charges"
export const EDIT_SOCIETY_PARKING_CHARGES_ENDPOINT = "/gate/parking-management/edit-society-parking-charges"
export const AUDIT_LOG_SOCIETY_PARKING_CHARGES_ENDPOINT = "/gate/parking-management/audit-logs-society-parking-charges"
export const VEHICLE_DASHBOARD_ENDPOINT = "/gate/vehicle-dashboard"
export const PRINT_VISITOR_VEHICLE_LOGS_ENDPOINT = "/gate/print-vehicle-logs"

// club - facility 
export const VIEW_BOOKINGS_ENDPOINT = "/club/view-bookings"
export const VIEW_BOOKINGS_DETAILS_ENDPOINT = "/club/view-bookings/view-details"
export const MAKE_PAYMENT_FOR_BOOKINGS_ENDPOINT = "/club/view-bookings/make-payment-for-booking"
export const MAKE_PAYMENT_BOOKINGS_ENDPOINT = "/club/view-bookings/make-payment-bookings"
export const PRINT_BOOKING_RECIEPT_ENDPOINT = "/club/view-bookings/print-reciept"
export const PRINT_BOOKING_INVOICE_ENDPOINT = "/club/view-bookings/print-invoice"
export const ALL_FACILITY_AUDIT_LOG_ENDPOINT = "/club/view-bookings/facility-audit"
export const FACILITY_REPORT_ENDPOINT = "/club/view-bookings/facility-report"
export const PRINT_FACILITY_REPORT_ENDPOINT = "/club/view-bookings/print-facility-report"
export const BOOKING_PAYMENT_AND_ADJUSTMENT_ENDPOINT = "/club/view-bookings/payment-and-adjustment"
export const ALL_FACILITY_ENDPOINT = "/club/all-facility"
export const VIEW_FACILTIY_DETAILS_ENDPOINT = "/club/view-facility"
export const EDIT_FACILITY_ENDPOINT = "/club/edit-facility"
export const ADD_FACILITY_ENDPOINT = "/club/add-facility"
export const MAKE_BOOKING_ENDPOINT = "/club/make-booking"
export const ADD_TIME_SLOT_ENDPOINT = "/club/add-time-slot"
export const VIEW_TIME_SLOT_TYPE_ENDPOINT = "/club/view-time-slot-type"
export const EDIT_TIME_SLOT_ENDPOINT = "/club/all-facility/edit-time-slot"
export const EDIT_RATE_CHART_ENDPOINT = "/club/all-facility/edit-rate-chart"
export const ADD_RATE_CHART_ENDPOINT = "/club/add-rate-chart"
export const VIEW_FACILTIY_AUDIT_LOGS_ENDPOINT = "/club/view-facility-audit"

//Compliance

export const I_FORM_ENDPOINT = "/compliance/registers/i-form"
export const PRINT_I_FORM_ENDPOINT = "/compliance/registers/print-i-form"
export const J_FORM_ENDPOINT = "/compliance/registers/j-form"
export const PRINT_J_FORM_ENDPOINT = "/compliance/registers/print-j-form"
export const SHARE_REGISTER_ENDPOINT = "/compliance/registers/share-register"
export const PROPERTY_REGISTER_ENDPOINT = "/compliance/registers/property-register"
export const NOMINATION_REGISTER_ENDPOINT = "/compliance/registers/nomiation-register"
export const LIEN_MORTGAGE_REGISTER_ENDPOINT = "/compliance/registers/lien-mortgage-register"
export const COMPLIANCE_DOCUMENTS_ISSUED_ENDPOINT = "/compliance/compliance-administration/documents-issued"
export const ISSUE_DOCUMENT_ENDPOINT = "/compliance/compliance-administration/documents-issued/issue-document"
export const VIEW_COMPLIANCE_DOCUMENTS_ISSUED_ENDPOINT = "/compliance/compliance-administration/documents-issued/view"
export const EDIT_COMPLIANCE_DOCUMENTS_ISSUED_ENDPOINT = "/compliance/compliance-administration/documents-issued/edit"
export const SAVED_NOTICE_ENDPOINT = "/compliance/meetings/saved-notice"
export const SAVED_NOTICE_SENT_MINUTES_ENDPOINT = "/compliance/meetings/saved-notice/sent-minutes"
export const VIEW_SAVED_NOTICE_ENDPOINT = "/compliance/meetings/saved-notice/view-saved-notice"
export const EDIT_SAVED_NOTICE_ENDPOINT = "/compliance/meetings/saved-notice/edit-saved-notice"
export const AUDIT_LOGS_SAVED_NOTICE_ENDPOINT = "/compliance/meetings/saved-notice/audit-logs-saved-notice"

export const SENT_NOTICE_ENDPOINT = "/compliance/meetings/sent-notice"
export const SENT_NOTICE_SENT_MINUTES_ENDPOINT = "/compliance/meetings/sent-notice/sent-minutes"
export const VIEW_SENT_NOTICE_ENDPOINT = "/compliance/meetings/sent-notice/view-sent-notice"
export const RESCHEDULE_SENT_NOTICE_ENDPOINT = "/compliance/meetings/sent-notice/reschedule-sent-notice"
export const AUDIT_LOGS_SENT_NOTICE_ENDPOINT = "/compliance/meetings/sent-notice/audit-logs-sent-notice"
export const SCHEDULE_A_MEETING_ENDPOINT = "/compliance/meetings/schedule-a-meeting"
export const SELECT_MINUTES_FOR_MINUTES_ENDPOINT = "/compliance/meetings/schedule-a-meeting/select-member-for-minutes"
export const CREATE_NOTICE_FOR_SCHEDULE_MEETING_ENDPOINT = "/compliance/meetings/schedule-a-meeting/select-member-for-minutes/create-notice"

export const CREATE_MINUTES_FOR_MEETINGS_ENDPOINT = "/compliance/meetings/create-minutes-for-meetings"
export const CREATE_MINUTES_MEETINGS_ENDPOINT = "/compliance/meetings/create-minutes-for-meetings/create-minutes"

export const SAVED_MINUTES_ENDPOINT = "/compliance/meetings/saved-minutes"
export const VIEW_SAVED_MINUTES_ENDPOINT = "/compliance/meetings/view-saved-minutes"

export const TRANSFER_FLAT_DOCUMENTS_ENDPOINT = "/complinace/compliance-administration/transfer-flat"
export const TRANSFER_FLAT_FORM_DOCUMENTS_ENDPOINT = "/complinace/compliance-administration/transfer-flat-form"
export const FLAT_TRANSFER_DOCUMENTS_ENDPOINT = "/complinace/compliance-administration/flat-transfer-doc"



// Old financial Reports 

export const VIEW_BILL_GENERATION_ENDPOINT = "/reports/old-financial-reports/bill-generation/view"
export const BILL_REPORTS_ENDPOINT = "/reports/old-financial-reports/bill-reports"
export const ACCOUNT_REPORTS_ENDPOINT = "/reports/old-financial-reports/account-reports"
export const FINANCE_REPORTS_ENDPOINT = "/reports/old-financial-reports/outstanding-reports"



// social and communications

export const PHOTO_GALLERY_ENDPOINT = "/social-Communications/photo-gallery"
export const ALBUM_PHOTOS_ENDPOINT = "/social-communications/photo-gallery/album-photos"
export const NOTICE_AND_CIRCULARS_ENDPOINT = "/social-communications/notice-and-circulars"
export const ARCHIVE_NOTICE_AND_CIRCULARS_ENDPOINT = "/social-communications/notice-and-circulars/archive"
export const VIEW_ARCHIVE_NOTICE_AND_CIRCULARS_ENDPOINT = "/social-communications/notice-and-circulars/archive/view"
export const DRAFT_NOTICE_AND_CIRCULARS_ENDPOINT = "/social-communications/notice-and-circulars/draft"
export const EDIT_DRAFT_NOTICE_AND_CIRCULARS_ENDPOINT = "/social-communications/notice-and-circulars/draft/edit"
export const CREATE_NOTICE_AND_CIRCULARS_ENDPOINT = "/social-communications/notice-and-circulars/write-a-notice"
export const EDIT_NOTICE_AND_CIRCULARS_ENDPOINT = "/social-communications/notice-and-circulars/edit"
export const VIEW_NOTICE_AND_CIRCULARS_ENDPOINT = "/social-communications/notice-and-circulars/view"
export const PRINT_NOTICE_AND_CIRCULARS_ENDPOINT = "/social-communications/notice-and-circulars/print"

export const ACKNOWLEDGMENT_LIST_NOTICE_AND_CIRCULARS_ENDPOINT = "/social-communications/notice-and-circulars/acknowledgment-list"
export const ACKNOWLEDGEMENT_FOR_NOTICE_AND_CIRCULARS_ENDPOINT = "/notice/acknowledgement"

export const TEST_ENDPOINT = "/test"