import React, { useEffect, useState } from "react";
import Layout from "../../../Layout/Layout";
import {
  MANAGE_DEBIT_CURRENT_ENDPOINT,
  ADD_MULTIPLE_DEBIT_NOTE_ENDPOINT,
} from "../../../../Routing/routes";
import ListHeader from "../../../../components/ListHeader/ListHeader";
import Button from "../../../../components/Button/Button";
import { useNavigate, useSearchParams } from "react-router-dom";
import ButtonG from "../../../../components/Button/ButtonG";
import { TextInputFM } from "../../../../components/InputFields/TextInput";
import { TextAreaFM } from "../../../../components/InputFields/TextArea";
import { DropdownFM2 } from "../../../../components/Dropdown/dropdown2";
import { societyStaffStatusOptions } from "../../../../consts/DropDownOptions";
import Loader from "../../../../components/Loader/Loader";
import parse from "html-react-parser";
import { DatePickerComponentFM } from "../../../../components/DatePicker/DatePicker";
import Checkbox from "../../../../components/Checkbox/Checkbox";
import DebitDetailBox from "./DebitDetailBox";
import { Form, Formik } from "formik";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  getWingsAndFlatDetails,
  getFlatDetails,
} from "../../../../redux/actions/SetupRecordsActions/BuildingActions/WingsAndFlatsAction";
import {
  getDebiteNoteTarrifList,
  getDebiteNoteFlatWingWiseList,
  getDebiteNoteArrearDetails,
  addDebiteNote,
  downloadDebitNoteCurrentPayment,
  uploadDebitNoteCurrentExcel,
  downloadDebitNoteCurrentExcel,
} from "../../../../redux/actions/FinanceActions/BillingManagementActions/ManageDebitNoteAction";
import { getNonMemberList } from "../../../../redux/actions/FinanceActions/BillingManagementActions/ManageAdvanceNoteAction";
import { getBookingGLMappingList } from "../../../../redux/actions/ClubActions/ViewBookingsAction";
import { getTenantsDetailsList } from "../../../../redux/actions/SetupRecordsActions/Occupants&TenantsActions/TenantsInfoAction";
import { AddDebitNoteValidation } from "../../../../validationSchema/financeSchema/billingManagementSchema";
import { TextInput } from "../../../../components/InputFields/TextInput";
import { TextArea } from "../../../../components/InputFields/TextArea";
import { getInvoiceTypeList } from "../../../../redux/actions/SetupRecordsActions/BillingAndInvoiceSetupActions/InvoiceSettingAction";
import ButtonIco from "../../../../components/Button/ButtonPrint";
import { DownloadExt, UploadEx } from "../../../../assets";
import { downloadExcel } from "../../../../utils/helpers/downloadMedia";
import UploadDebitNoteExcelModal from "../../../../components/Modal/UploadDebitNoteExcelUploadModal";

const DebitNoteMemberTypeOptions = [
  { value: "Member", label: "Members" },
  { value: "Vendor", label: "Non Member" },
  { value: "Tenant", label: "Tenants" },
];
const BillingType = [
  { value: "debit_note", label: "Debit Note" },
  { value: "supplementary", label: "Supplementary", isDisabled: false },
];
const headCells = [
  { label: "Tariff Header" },
  { label: "Credit A/C" },
  { label: "Amount (Rs.)" },
  { label: "Description" },
  { label: "From Date" },
  { label: "To Date" },
];

const AddDebitNote = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { wingsData } = useSelector((state) => state.wingsReducer);
  const { loginData } = useSelector((state) => state.loginReducer);
  const {
    updateLoader,
    loading,
    manageDebitNoteTarrifList,
    manageDebitNoteFlatWingsWise,
    loadingManageDebitNoteFlatWingsWise,
    loadingManageDebitNoteArrearDetails,
    manageDebitNoteArrearDetails,
  } = useSelector((state) => state.manageDebitNoteReducer);
  const { glMappingList } = useSelector((state) => state.viewBookingReducer);
  const { nonMemberList } = useSelector(
    (state) => state.manageAdvanceNoteReducer
  );
  const { typeLists } = useSelector((state) => state.invoiceSettingReducer);
  const [invoiceType, setInvoiceType] = useState(1);

  const [file, setFile] = useState(null);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [gstInterest, setGstInterest] = useState({
    cgst: 0,
    sgst: 0,
    igst: 0,
    total: 0,
  });

  const handleOpenUploadModal = () => {
    setIsUploadModalOpen(true);
  };
  const handleCloseUploadModal = () => {
    setIsUploadModalOpen(false);
  };
  const onUpload = () => {
    let data = {
      societyId: loginData?.default_community?.community_id,
      invoiceType: invoiceType,
      file,
      onSuccessCallback: () => {
        navigate(ADD_MULTIPLE_DEBIT_NOTE_ENDPOINT);
      },
      // onRequestEndCallback: () => {
      //   setFile(null);
      //   setIsUploadModalOpen(false);
      // },
    };
    dispatch(uploadDebitNoteCurrentExcel(data));
  };
  const handleDownloadExcel = () => {
    const onSucessCallback = (data) => {
      downloadExcel({ data, fileName: "debitnote-excel.xlsx" });
    };
    const data = {
      societyId: loginData?.default_community?.community_id,
      onSucessCallback,
    };
    dispatch(downloadDebitNoteCurrentExcel(data));
  };

  useEffect(() => {
    const societyId = loginData?.default_community?.community_id;
    dispatch(getWingsAndFlatDetails());
    dispatch(getDebiteNoteTarrifList({ societyId, invoiceType }));
    dispatch(getBookingGLMappingList({ societyId }));
    dispatch(getInvoiceTypeList({ societyId }));
    dispatch(getNonMemberList({ societyId }));
    dispatch(
      getTenantsDetailsList({
        societyId: loginData?.default_community?.community_id,
        pageLimit: 10,
        page: 1,
      })
    );
    dispatch(getDebiteNoteArrearDetails());
    setTenantListData([]);
    setGstInterest({
      cgst: 0,
      sgst: 0,
      igst: 0,
      total: 0,
    });
  }, []);

  useEffect(() => {
    const percentage = (amount, rate) => {
      return (Number(amount) * Number(rate)) / 100;
    };
    if (
      manageDebitNoteArrearDetails?.interest &&
      manageDebitNoteTarrifList?.debitsetting?.gst_applicable == 1 &&
      manageDebitNoteTarrifList?.debitsetting?.interest_gst == 1
    ) {
      if (
        manageDebitNoteTarrifList?.debitsetting?.cgst_rate &&
        manageDebitNoteTarrifList?.debitsetting?.cgst_head
      ) {
        let value = percentage(
          manageDebitNoteArrearDetails?.interest,
          manageDebitNoteTarrifList?.debitsetting?.cgst_rate
        );
        setGstInterest((e) => ({
          ...e,
          cgst_rate: value,
          total: e.total + value,
        }));
      }
      if (
        manageDebitNoteTarrifList?.debitsetting?.sgst_rate &&
        manageDebitNoteTarrifList?.debitsetting?.sgst_head
      ) {
        let value = percentage(
          manageDebitNoteArrearDetails?.interest,
          manageDebitNoteTarrifList?.debitsetting?.sgst_rate
        );
        setGstInterest((e) => ({
          ...e,
          sgst_rate: value,
          total: e.total + value,
        }));
      }
      if (
        manageDebitNoteTarrifList?.debitsetting?.igst_rate &&
        manageDebitNoteTarrifList?.debitsetting?.igst_head
      ) {
        let value = percentage(
          manageDebitNoteArrearDetails?.interest,
          manageDebitNoteTarrifList?.debitsetting?.igst_rate
        );
        setGstInterest((e) => ({
          ...e,
          igst_rate: value,
          total: e.total + value,
        }));
      }
    }
  }, [manageDebitNoteArrearDetails, manageDebitNoteTarrifList]);
  const { tenantsList } = useSelector((state) => state.tenantInfoReducer);
  const [tenantListData, setTenantListData] = useState([]);

  useEffect(() => {
    if (!(tenantListData.length > 0)) {
      if (tenantsList?.page_limit > 10) {
        setTenantListData(tenantsList?.tenants_list);
      } else if (tenantsList?.total_pages > 1) {
        dispatch(
          getTenantsDetailsList({
            societyId: loginData?.default_community?.community_id,
            pageLimit:
              tenantsList.total_pages > 1
                ? 10 * tenantsList?.total_pages
                : 10 * 2,
            page: 1,
          })
        );
      } else if (tenantsList?.total_pages <= 1) {
        setTenantListData(tenantsList?.tenants_list);
      }
    }
  }, [tenantsList?.page_limit]);

  const getArrears = (values) => {
    if (
      values.invoiceDate &&
      values.dueDate &&
      values.issuedTo &&
      ((values.flatId && values.flatId != "0") ||
        (values.tenantId && values.tenantId != "0") ||
        (values.nonMemberId && values.nonMemberId != "0"))
    ) {
      dispatch(
        getDebiteNoteArrearDetails({
          id: loginData?.default_community?.community_id,
          invoiceType: values.invoiceType,
          invoice_date: values.invoiceDate,
          due_date: values.dueDate,
          issue_type: values.issuedTo,
          flat_id: values.issuedTo == "Member" ? values.flatId : "",
          debitor_id:
            values.issuedTo == "Tenant"
              ? values.tenantId
              : values.issuedTo == "Vendor"
              ? values.nonMemberId
              : "",
        })
      );
    }
  };

  const handleSubmit = (values, { resetForm }) => {
    values.societyId = loginData?.default_community?.community_id;
    values.lastdebitnote = manageDebitNoteArrearDetails?.debitnoteid;
    values.cgst_head = manageDebitNoteTarrifList?.debitsetting?.cgst_head;
    values.sgst_head = manageDebitNoteTarrifList?.debitsetting?.sgst_head;
    values.igst_head = manageDebitNoteTarrifList?.debitsetting?.igst_head;
    values.roundoff = manageDebitNoteTarrifList?.debitsetting?.roundoff;
    values.cgst_rate = manageDebitNoteTarrifList?.debitsetting?.cgst_rate;
    values.sgst_rate = manageDebitNoteTarrifList?.debitsetting?.sgst_rate;
    values.igst_rate = manageDebitNoteTarrifList?.debitsetting?.igst_rate;

    values.interest = manageDebitNoteArrearDetails
      ? Math.round(manageDebitNoteArrearDetails?.interest)
      : 0;

    values.principal_arrear = manageDebitNoteArrearDetails
      ? Math.round(manageDebitNoteArrearDetails?.principal_arrear)
      : 0;
    values.interest_arrear = manageDebitNoteArrearDetails
      ? Math.round(manageDebitNoteArrearDetails?.interest_arrear)
      : 0;
    values.cgst_amount = values.cgst_amount + gstInterest?.cgst;
    values.sgst_amount = values.sgst_amount + gstInterest?.sgst;
    values.igst_amount = values.igst_amount + gstInterest?.igst;
    values.subTotal = values.subTotal + gstInterest?.total;

    dispatch(
      addDebiteNote({
        ...values,
        onSuccessCallback: () => navigate(MANAGE_DEBIT_CURRENT_ENDPOINT),
      })
    );
  };
  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <Formik
          initialValues={{
            invoiceType:
              manageDebitNoteTarrifList?.debitsetting?.invoice_type ||
              invoiceType,
            invoiceDate: "",
            dueDate: "",
            issuedTo: "Member",
            // billType: "debit_note",
            wingId: "",
            flatId: "",
            tenantId: "0",
            nonMemberId: "0",
            debitAccount: "",
            sendSMS: "N",
            sendEmail: "N",
            is_draft: 0,
            details: manageDebitNoteTarrifList?.debitcause,
            cgst_amount:
              (manageDebitNoteTarrifList?.debitcause.reduce((prevVal, item) => {
                return item?.gstapplicable == "Y"
                  ? prevVal + Number(item?.amount)
                  : prevVal;
              }, 0) *
                parseInt(
                  manageDebitNoteTarrifList?.debitsetting?.gst_applicable ==
                    1 &&
                    manageDebitNoteTarrifList?.debitsetting?.cgst_rate &&
                    manageDebitNoteTarrifList?.debitsetting?.cgst_head
                    ? Number(manageDebitNoteTarrifList?.debitsetting?.cgst_rate)
                    : 0
                )) /
              100,
            sgst_amount:
              (manageDebitNoteTarrifList?.debitcause.reduce((prevVal, item) => {
                return item?.gstapplicable == "Y"
                  ? prevVal + Number(item?.amount)
                  : prevVal;
              }, 0) *
                parseInt(
                  manageDebitNoteTarrifList?.debitsetting?.gst_applicable ==
                    1 &&
                    manageDebitNoteTarrifList?.debitsetting?.sgst_rate &&
                    manageDebitNoteTarrifList?.debitsetting?.sgst_head
                    ? Number(manageDebitNoteTarrifList?.debitsetting?.sgst_rate)
                    : 0
                )) /
              100,
            igst_amount:
              (manageDebitNoteTarrifList?.debitcause.reduce((prevVal, item) => {
                return item?.gstapplicable == "Y"
                  ? prevVal + Number(item?.amount)
                  : prevVal;
              }, 0) *
                parseInt(
                  manageDebitNoteTarrifList?.debitsetting?.gst_applicable ==
                    1 &&
                    manageDebitNoteTarrifList?.debitsetting?.igst_rate &&
                    manageDebitNoteTarrifList?.debitsetting?.igst_head
                    ? Number(manageDebitNoteTarrifList?.debitsetting?.igst_rate)
                    : 0
                )) /
              100,
            subTotal:
              manageDebitNoteTarrifList?.debitcause.reduce((prevVal, item) => {
                return prevVal + Number(item?.amount);
              }, 0) +
              (manageDebitNoteTarrifList?.debitcause.reduce((prevVal, item) => {
                return item?.gstapplicable == "Y"
                  ? prevVal + Number(item?.amount)
                  : prevVal;
              }, 0) *
                parseInt(
                  manageDebitNoteTarrifList?.debitsetting?.gst_applicable == 1
                    ? (manageDebitNoteTarrifList?.debitsetting?.cgst_rate &&
                      manageDebitNoteTarrifList?.debitsetting?.cgst_head
                        ? Number(
                            manageDebitNoteTarrifList?.debitsetting?.cgst_rate
                          )
                        : 0) +
                        (manageDebitNoteTarrifList?.debitsetting?.sgst_rate &&
                        manageDebitNoteTarrifList?.debitsetting?.sgst_head
                          ? Number(
                              manageDebitNoteTarrifList?.debitsetting?.sgst_rate
                            )
                          : 0) +
                        (manageDebitNoteTarrifList?.debitsetting?.igst_rate &&
                        manageDebitNoteTarrifList?.debitsetting?.igst_head
                          ? Number(
                              manageDebitNoteTarrifList?.debitsetting?.igst_rate
                            )
                          : 0)
                    : 0
                )) /
                100,
            interest: 0,
            principal_arrear: 0,
            interest_arrear: 0,

            roundoff_amount: manageDebitNoteTarrifList?.debitsetting?.roundoff,
            cgst_head: manageDebitNoteTarrifList?.debitsetting?.cgst_head,
            sgst_head: manageDebitNoteTarrifList?.debitsetting?.sgst_head,
            igst_head: manageDebitNoteTarrifList?.debitsetting?.igst_head,
            gst_applicable:
              Number(manageDebitNoteTarrifList?.debitsetting?.gst_applicable) ||
              0,
          }}
          enableReinitialize
          validationSchema={AddDebitNoteValidation}
          onSubmit={handleSubmit}
        >
          {({ values, errors, setFieldValue }) => (
            <Form>
              <>
                <div className="border-b border-[#CCC]">
                  <ListHeader leftContent="Add Debit Note">
                    <div className="flex gap-2">
                      <button
                        type="button"
                        onClick={handleOpenUploadModal}
                        className="flex items-center px-2 text-xs font-semibold bg-white border rounded-lg text-red-650 border-red-650 whitespace-nowrap"
                      >
                        <UploadEx className="mr-2 fill-red-650" /> Upload Excel
                      </button>
                      {/* <ButtonIco
                        type="button"
                        onClick={handleDownloadExcel}
                        icon={DownloadExt}
                        children="Download Excel"
                      /> */}
                      <div className="border-r border-[#CCC]"></div>

                      <ButtonG
                        label="Cancel"
                        onClick={() => {
                          navigate(-1);
                        }}
                        className="h-8"
                        type="button"
                      />
                      <Button
                        label="Save"
                        className={`h-8 px-5 ${
                          loadingManageDebitNoteArrearDetails ||
                          manageDebitNoteArrearDetails?.unverified_status ==
                            "Unverified"
                            ? "opacity-40"
                            : ""
                        }`}
                        type="submit"
                        disabled={
                          loadingManageDebitNoteArrearDetails ||
                          manageDebitNoteArrearDetails?.unverified_status ==
                            "Unverified"
                        }
                        // onClick={""}
                      />
                      <Button
                        label="Save As Draft"
                        className={`h-8 px-5  ${
                          loadingManageDebitNoteArrearDetails ||
                          manageDebitNoteArrearDetails?.unverified_status ==
                            "Unverified"
                            ? "opacity-40"
                            : ""
                        }`}
                        type="submit"
                        disabled={
                          loadingManageDebitNoteArrearDetails ||
                          manageDebitNoteArrearDetails?.unverified_status ==
                            "Unverified"
                        }
                        onClick={() => {
                          setFieldValue("is_draft", 1);
                        }}
                      />
                    </div>
                  </ListHeader>
                </div>

                {loading || updateLoader ? (
                  <Loader />
                ) : (
                  <>
                    <div className="flex gap-4 p-2">
                      <div className="flex flex-row justify-between w-full gap-7">
                        <div className="flex flex-col w-1/2">
                          <div className="flex flex-row justify-between mb-1">
                            <span className="mt-2 text-sm">Invoice Type</span>
                            <div className="flex gap-3">
                              <DropdownFM2
                                options={
                                  typeLists?.map((item) => ({
                                    label: item?.invoice_title,
                                    value: item?.id,
                                  })) || []
                                }
                                placeholder="Select Invoice Type"
                                className=""
                                width="225px"
                                height="36px"
                                onSelect={(selectedItem) => {
                                  setFieldValue(
                                    "invoiceType",
                                    selectedItem.value
                                  );
                                  setInvoiceType(selectedItem.value);
                                  dispatch(
                                    getDebiteNoteTarrifList({
                                      societyId:
                                        loginData?.default_community
                                          ?.community_id,
                                      invoiceType,
                                      invoiceType: selectedItem.value,
                                    })
                                  );
                                  dispatch(getDebiteNoteArrearDetails());
                                }}
                                value={
                                  values.invoiceType === ""
                                    ? null
                                    : {
                                        label:
                                          typeLists?.find(
                                            (item) =>
                                              item.id === values.invoiceType
                                          )?.invoice_title || "",
                                        value: values.invoiceType,
                                      }
                                }
                                name={"invoiceType"}
                              />
                            </div>
                          </div>
                          <div className="flex flex-row justify-between mb-1">
                            <span className="mt-2 text-sm">
                              Debit Note Date
                            </span>
                            <div className="flex gap-3">
                              <DatePickerComponentFM
                                className="justify-between w-56 h-9"
                                onDateChange={(selectedDate) => {
                                  if (
                                    new Date(values.dueDate).getTime() <
                                    new Date(selectedDate).getTime()
                                  ) {
                                    setFieldValue("dueDate", null);
                                  }
                                  setFieldValue(
                                    "invoiceDate",
                                    moment(selectedDate).format("YYYY-MM-DD")
                                  );
                                  getArrears({
                                    ...values,
                                    invoiceDate:
                                      moment(selectedDate).format("YYYY-MM-DD"),
                                  });
                                }}
                                name="invoiceDate"
                                defaultValue={
                                  values.invoiceDate
                                    ? new Date(values.invoiceDate)
                                    : null
                                }
                              />
                            </div>
                          </div>
                          <div className="flex flex-row justify-between mb-1">
                            <span className="mt-2 text-sm">
                              Payment Due Date
                            </span>
                            <div className="flex gap-3">
                              <DatePickerComponentFM
                                className="justify-between w-56 h-9"
                                onDateChange={(selectedDate) => {
                                  setFieldValue(
                                    "dueDate",
                                    moment(selectedDate).format("YYYY-MM-DD")
                                  );
                                  getArrears({
                                    ...values,
                                    dueDate:
                                      moment(selectedDate).format("YYYY-MM-DD"),
                                  });
                                }}
                                name="dueDate"
                                minDate={new Date(values.invoiceDate)}
                                defaultValue={
                                  values.dueDate
                                    ? new Date(values.dueDate)
                                    : null
                                }
                              />
                            </div>
                          </div>
                          <div className="flex items-center justify-between gap-3 mb-1">
                            <div className="text-sm">Issued To</div>
                            <div className="flex gap-6">
                              <DropdownFM2
                                options={DebitNoteMemberTypeOptions}
                                placeholder="Select Issued To"
                                className="text-[11px] ms-4"
                                width="224px"
                                height="36px"
                                onSelect={(selectedItem) => {
                                  setFieldValue("issuedTo", selectedItem.value);
                                  setFieldValue("debitAccount", "");
                                  setFieldValue(
                                    "wingId",
                                    selectedItem.value == "Member" ? "" : "0"
                                  );
                                  setFieldValue(
                                    "flatId",
                                    selectedItem.value == "Member" ? "" : "0"
                                  );
                                  setFieldValue(
                                    "tenantId",
                                    selectedItem.value == "Tenant" ? "" : "0"
                                  );
                                  setFieldValue(
                                    "nonMemberId",
                                    selectedItem.value == "Vendor" ? "" : "0"
                                  );
                                  getArrears({
                                    ...values,
                                    issuedTo: selectedItem.value,
                                  });
                                }}
                                value={
                                  DebitNoteMemberTypeOptions && {
                                    label: DebitNoteMemberTypeOptions.find(
                                      (item) => item.value == values.issuedTo
                                    )?.label,
                                    value: values.issuedTo,
                                  }
                                }
                                name="issuedTo"
                              />
                            </div>
                          </div>
                          {/* <div className="flex items-center justify-between gap-3 mb-1">
                            <div className="text-sm">Bill Type</div>
                            <div className="flex gap-6">
                              <DropdownFM2
                                options={BillingType}
                                placeholder="Select Bill Type"
                                className="text-[11px] ms-4"
                                width="224px"
                                height="36px"
                                onSelect={(selectedItem) => {
                                  setFieldValue("billType", selectedItem.value);
                                  getArrears({
                                    ...values,
                                    billType: selectedItem.value,
                                  });
                                }}
                                value={
                                  BillingType && {
                                    label: BillingType.find(
                                      (item) => item.value == values.billType
                                    )?.label,
                                    value: values.billType,
                                  }
                                }
                                name="billType"
                              />
                            </div>
                          </div> */}

                          <div className="flex justify-between gap-3 mb-1">
                            <div className="text-sm ">
                              {values.issuedTo == "Tenant"
                                ? "Tenant "
                                : values.issuedTo == "Vendor"
                                ? "Vendor "
                                : "Member "}
                              Details
                            </div>
                            <div>
                              {values.issuedTo == "Member" && (
                                <div className="flex gap-2">
                                  <DropdownFM2
                                    options={
                                      values.issuedTo == "Member" && wingsData
                                        ? wingsData?.map((item) => ({
                                            label: item?.number,
                                            value: item?.wing_id,
                                          }))
                                        : []
                                    }
                                    placeholder="Wing"
                                    className="text-[11px] ms-4"
                                    width="108px"
                                    height="36px"
                                    onSelect={(selectedItem) => {
                                      if (selectedItem.value != values.wingId) {
                                        setFieldValue("flatId", "");
                                        dispatch(
                                          getDebiteNoteFlatWingWiseList({
                                            id: loginData?.default_community
                                              ?.community_id,
                                            wing_no: selectedItem.value,
                                          })
                                        );
                                      }
                                      setFieldValue(
                                        "wingId",
                                        selectedItem.value
                                      );
                                    }}
                                    name="wingId"
                                    value={
                                      values.issuedTo == "Member" &&
                                      values.wingId
                                        ? {
                                            label: wingsData?.find(
                                              (item) =>
                                                item.wing_id == values.wingId
                                            )?.number,
                                            value: values.wingId,
                                          }
                                        : []
                                    }
                                    disabled={!(values.issuedTo == "Member")}
                                  />
                                  <DropdownFM2
                                    options={
                                      values.wingId &&
                                      manageDebitNoteFlatWingsWise
                                        ? manageDebitNoteFlatWingsWise
                                            ?.filter(
                                              (item) =>
                                                item.main_member &&
                                                item.gl_codeid > 0
                                            )
                                            ?.map((item) => ({
                                              label: item?.flat_no,
                                              value: item?.id,
                                            }))
                                        : []
                                    }
                                    loading={
                                      loadingManageDebitNoteFlatWingsWise
                                    }
                                    placeholder="Flat Unit"
                                    className="text-[11px] ms-4"
                                    width="108px"
                                    height="36px"
                                    onSelect={(selectedItem) => {
                                      // setFieldValue(
                                      //   "debitAccount",
                                      //   manageDebitNoteFlatWingsWise.find(
                                      //     (item) => item?.id == selectedItem.value
                                      //   )?.codeId
                                      // );
                                      setFieldValue(
                                        "flatId",
                                        selectedItem.value
                                      );
                                      if (values.flatId != selectedItem.value)
                                        getArrears({
                                          ...values,
                                          flatId: selectedItem.value,
                                        });
                                    }}
                                    name="flatId"
                                    // {...(values.flatId === "" && { value: null })}
                                    value={
                                      values.issuedTo == "Member" &&
                                      values.flatId &&
                                      manageDebitNoteFlatWingsWise?.length > 0
                                        ? {
                                            label:
                                              manageDebitNoteFlatWingsWise?.find(
                                                (item) =>
                                                  item.id == values.flatId
                                              )?.flat_no,
                                            value: values.flatId,
                                          }
                                        : []
                                    }
                                    disabled={!(values.issuedTo == "Member")}
                                  />
                                </div>
                              )}
                              {values.issuedTo == "Tenant" && (
                                <div className="flex gap-6">
                                  <DropdownFM2
                                    options={
                                      values.issuedTo == "Tenant"
                                        ? tenantListData?.map((item) => ({
                                            label: item?.tenant_name,
                                            value: item?.tenant_id,
                                          }))
                                        : []
                                    }
                                    loading={!tenantListData.length > 0}
                                    placeholder="Select Tenant Name"
                                    className="text-[11px] ms-4"
                                    width="224px"
                                    height="36px"
                                    onSelect={(selectedItem) => {
                                      setFieldValue(
                                        "tenantId",
                                        selectedItem.value
                                      );
                                      // setFieldValue("debitAccount", "");
                                      if (values.tenantId != selectedItem.value)
                                        getArrears({
                                          ...values,
                                          tenantId: selectedItem.value,
                                        });
                                    }}
                                    value={
                                      values.tenantId &&
                                      values.issuedTo == "Tenant"
                                        ? {
                                            label: tenantListData?.find(
                                              (item) =>
                                                item?.tenant_id ==
                                                values.tenantId
                                            )?.tenant_name,
                                            value: values.tenantId,
                                          }
                                        : []
                                    }
                                    name="tenantId"
                                    disabled={!(values.issuedTo == "Tenant")}
                                  />
                                </div>
                              )}

                              {values.issuedTo == "Vendor" && (
                                <div className="flex gap-6">
                                  <DropdownFM2
                                    options={
                                      values.issuedTo == "Vendor" &&
                                      nonMemberList
                                        ? nonMemberList.map((item) => ({
                                            label: item?.vendorname,
                                            value: item?.id,
                                          }))
                                        : []
                                    }
                                    placeholder="Select Non-Member Name"
                                    className="text-[11px] ms-4"
                                    width="224px"
                                    height="36px"
                                    onSelect={(selectedItem) => {
                                      setFieldValue(
                                        "nonMemberId",
                                        selectedItem.value
                                      );
                                      // setFieldValue(
                                      //   "debitAccount",
                                      //   nonMemberList?.find(
                                      //     (item) => item?.id == selectedItem.value
                                      //   )?.glcode
                                      // );
                                      if (
                                        values.nonMemberId != selectedItem.value
                                      )
                                        getArrears({
                                          ...values,
                                          nonMemberId: selectedItem.value,
                                        });
                                    }}
                                    value={
                                      values?.nonMemberId &&
                                      values.issuedTo == "Vendor"
                                        ? {
                                            label: nonMemberList?.find(
                                              (item) =>
                                                item.id == values.nonMemberId
                                            )?.vendorname,
                                            value: values.nonMemberId,
                                          }
                                        : []
                                    }
                                    name="nonMemberId"
                                    disabled={!(values.issuedTo == "Vendor")}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="flex items-center justify-between gap-3 mb-1">
                            <div className="text-sm">Debit A/C</div>
                            {/* <div className="flex gap-6">
                              <TextInputFM
                                name="debitAccount"
                                label=""
                                placeholder="Debit A/C"
                                className="w-[224px]"
                                disabled={true}
                              />
                            </div> */}

                            <DropdownFM2
                              options={
                                values.issuedTo == "Member" && values.flatId
                                  ? manageDebitNoteFlatWingsWise
                                      ?.filter(
                                        (item) => item.id == values.flatId
                                      )
                                      ?.map((item) => ({
                                        label: item.acName,
                                        value: item.codeId,
                                      }))
                                  : values.issuedTo == "Vendor" &&
                                    values.nonMemberId
                                  ? glMappingList
                                      ?.filter(
                                        (item) =>
                                          item?.ac_code ==
                                          nonMemberList?.find(
                                            (item2) =>
                                              item2?.id == values.nonMemberId
                                          )?.glcode
                                      )
                                      ?.map((item) => ({
                                        label: item.name,
                                        value: item.ac_code,
                                      }))
                                  : values.issuedTo == "Tenant" &&
                                    values.tenantId
                                  ? glMappingList?.map((item) => ({
                                      label: item?.name,
                                      value: item?.ac_code,
                                    }))
                                  : []
                              }
                              placeholder="Select Gl"
                              className="text-[11px] ms-4"
                              width="224px"
                              height="36px"
                              onSelect={(selectedItem) => {
                                setFieldValue(
                                  "debitAccount",
                                  selectedItem.value
                                );
                              }}
                              name="debitAccount"
                              value={
                                values.issuedTo == "Member" &&
                                values.flatId &&
                                values.debitAccount
                                  ? {
                                      label: manageDebitNoteFlatWingsWise?.find(
                                        (item) =>
                                          item.codeId == values.debitAccount
                                      )?.acName,
                                      value: values.debitAccount,
                                    }
                                  : values.issuedTo == "Vendor" &&
                                    values.nonMemberId &&
                                    values.debitAccount
                                  ? {
                                      label: glMappingList?.find(
                                        (item) =>
                                          item.ac_code == values.debitAccount
                                      )?.name,
                                      value: values.debitAccount,
                                    }
                                  : values.issuedTo == "Tenant" &&
                                    values.tenantId &&
                                    values.debitAccount
                                  ? {
                                      label: glMappingList?.find(
                                        (item) =>
                                          item?.ac_code == values.debitAccount
                                      )?.name,
                                      value: values.debitAccount,
                                    }
                                  : []
                              }
                            />
                          </div>

                          <div className="flex items-center justify-between gap-3 mb-1">
                            <div className="text-sm">Send SMS And Email</div>
                            <div className="py-2 text-sm">
                              <Checkbox
                                text={"SMS"}
                                className={"w-28"}
                                initialchecked={values.sendSMS === "Y"}
                                onChange={(e) => {
                                  setFieldValue(
                                    "sendSMS",
                                    e.target.checked ? "Y" : "N"
                                  );
                                }}
                              />
                              <Checkbox
                                text={"Email"}
                                className={"w-28"}
                                initialchecked={values.sendEmail === "Y"}
                                onChange={(e) => {
                                  setFieldValue(
                                    "sendEmail",
                                    e.target.checked ? "Y" : "N"
                                  );
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <DebitDetailBox
                      values={values}
                      manageDebitNoteTarrifList={manageDebitNoteTarrifList}
                      manageDebitNoteArrearDetails={
                        manageDebitNoteArrearDetails
                      }
                      setFieldValue={setFieldValue}
                      columns={headCells}
                      gstInterest={gstInterest}
                    />
                    {/* <div className="border-b border-[#CCC] -mt-2"></div> */}
                    <div className="w-full">
                      <BillSection
                        manageDebitNoteArrearDetails={
                          manageDebitNoteArrearDetails
                        }
                        manageDebitNoteTarrifList={manageDebitNoteTarrifList}
                        values={values}
                        gstInterest={gstInterest}
                      />
                    </div>
                    {manageDebitNoteArrearDetails && (
                      <div className="text-sm bg-[#ececec] rounded-sm p-2 my-1">
                        <h3 className="font-semibold">
                          Interest Calculation Log:
                        </h3>
                        {manageDebitNoteArrearDetails?.info ? (
                          parse(manageDebitNoteArrearDetails.info)
                        ) : (
                          <p className="text-red-500 font-semibold">
                            {manageDebitNoteArrearDetails?.message}{" "}
                          </p>
                        )}
                      </div>
                    )}
                    {isUploadModalOpen && (
                      <UploadDebitNoteExcelModal
                        isOpen={isUploadModalOpen}
                        onClose={handleCloseUploadModal}
                        buttons={[
                          "Download Sample",
                          // "Download CSV Sample",
                          // "Download Format",
                        ]}
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        file={file}
                        setFile={setFile}
                        onUpload={onUpload}
                        invoiceTypeList={typeLists}
                        setInvoicetype={setInvoiceType}
                        invoiceType={invoiceType}
                        // downloadCSVSampleLink={handleDownloadSampleLink}
                        downloadSampleLink={handleDownloadExcel}
                        // downloadFormatLink={handleDownloadFormatLink}
                      />
                    )}
                  </>
                )}
              </>
            </Form>
          )}
        </Formik>
      </div>
    </Layout>
  );
};
export const BillSection = ({
  values,
  manageDebitNoteArrearDetails = null,
  manageDebitNoteTarrifList = null,
  gstInterest = null,
}) => {
  return (
    <div className="flex justify-between mt-4">
      <table className="border-separate">
        <tbody>
          <tr>
            <td className="text-sm w-56 pt-2.5 align-top ">CGST</td>
            <td>
              <TextInput
                label=""
                placeholder="CGST"
                className="w-52"
                type="number"
                name="sgst_amount"
                value={values.cgst_amount + gstInterest?.cgst}
                // maxLength={15}
                disabled={true}
              />
            </td>
            <td className="text-sm w-56 pt-2.5 align-top ps-3">SGST</td>
            <td>
              <TextInput
                label=""
                placeholder="SGST"
                className="w-52"
                type="number"
                name="cgst_amount"
                value={values.sgst_amount + gstInterest?.sgst}
                // maxLength={15}
                disabled={true}
              />
            </td>
          </tr>
          <tr>
            <td className="text-sm w-56 pt-2.5 align-top ">IGST</td>
            <td>
              <TextInput
                label=""
                placeholder="IGST"
                className="w-52"
                type="number"
                name="igst_amount"
                value={values.igst_amount + gstInterest?.igst}
                // maxLength={15}
                disabled={true}
              />
            </td>
          </tr>

          <tr>
            <td className="text-sm w-56 pt-2.5 pb-2.5 align-top font-semibold ">
              Sub Total
            </td>
            <td className="text-sm">{values.subTotal + gstInterest?.total}</td>

            <td className="text-sm w-56 pt-2.5 align-top font-semibold ps-3">
              {manageDebitNoteTarrifList?.debitsetting?.roundoff == 1 &&
                "Round Off"}
            </td>
            <td className="text-sm">
              {manageDebitNoteTarrifList?.debitsetting?.roundoff == 1 &&
              values.subTotal + gstInterest?.total
                ? (
                    Math.round(values.subTotal + gstInterest?.total) -
                    values.subTotal +
                    gstInterest?.total
                  ).toFixed(2)
                : ""}
            </td>
          </tr>
          <tr>
            <td className="text-sm w-56 pt-2.5 align-top">Interest</td>
            <td>
              <TextInput
                name="interest"
                label=""
                placeholder="Enter Interest"
                className="w-52"
                type="number"
                value={
                  manageDebitNoteArrearDetails
                    ? Math.round(manageDebitNoteArrearDetails?.interest)
                    : 0
                }
                disabled={true}
              />
            </td>

            <td className="text-sm w-56 pt-2.5 ps-3 align-top">
              Interest Arrears
            </td>
            <td>
              <TextInput
                name="interest_arrear"
                label=""
                placeholder="Enter Interest Arrears"
                className="w-52"
                type="number"
                value={
                  manageDebitNoteArrearDetails
                    ? Math.round(manageDebitNoteArrearDetails?.interest_arrear)
                    : 0
                }
                disabled={true}
              />
            </td>
          </tr>
          <tr>
            <td className="text-sm w-56 pt-2.5 pb-2.5 align-top ">
              Principle Arrears
            </td>
            <td>
              <TextInput
                name="principal_arrear"
                label=""
                placeholder="Enter Principle Arrears"
                className="w-52"
                type="number"
                value={
                  manageDebitNoteArrearDetails
                    ? Math.round(manageDebitNoteArrearDetails?.principal_arrear)
                    : 0
                }
                disabled={true}
              />
            </td>

            <td className="text-sm w-56 pt-2.5 align-top font-semibold ps-3">
              Grand Total
            </td>
            <td className="text-sm">
              {values.subTotal + gstInterest?.total
                ? (
                    values.subTotal +
                    gstInterest?.total +
                    (manageDebitNoteArrearDetails
                      ? Math.round(manageDebitNoteArrearDetails?.interest) +
                        Math.round(
                          manageDebitNoteArrearDetails?.principal_arrear
                        ) +
                        Math.round(
                          manageDebitNoteArrearDetails?.interest_arrear
                        )
                      : 0) +
                    (manageDebitNoteTarrifList?.debitsetting?.roundoff == 1
                      ? Math.round(values.subTotal + gstInterest?.total) -
                        values.subTotal +
                        gstInterest?.total
                      : 0)
                  ).toFixed(2)
                : "-"}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
export default AddDebitNote;
