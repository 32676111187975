import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Layout from "../../../Layout/Layout";
import { CircularProgress } from "@mui/material";
import { Form, Formik, FieldArray } from "formik";
import Button from "../../../../components/Button/Button";
import ListHeader from "../../../../components/ListHeader/ListHeader";
import "../../../../components/TableComponent/Table.css";
import styled from "@emotion/styled";
import { useSearchParams } from "react-router-dom";
import Loader from "../../../../components/Loader/Loader";
import {
  TextInput,
  TextInputFM,
} from "../../../../components/InputFields/TextInput";
import { DatePickerComponentFM } from "../../../../components/DatePicker/DatePicker";
import DropdownFM2 from "../../../../components/Dropdown/dropdown2";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { AddMultiPaymentValidation } from "../../../../validationSchema/financeSchema/billingManagementSchema";
import { saveManageInvoicePaymentExcel } from "../../../../redux/actions/FinanceActions/BillingManagementActions/ManageInvoiceActions";
import {
  MANAGE_DEBIT_CURRENT_ENDPOINT,
  MANAGE_INVOICE_CURRENT_ENDPOINT,
} from "../../../../Routing/routes";
import { bulkMakePaymentDebitNoteSave } from "../../../../redux/actions/FinanceActions/BillingManagementActions/ManageDebitNoteAction";
import { getBankDetailList } from "../../../../redux/actions/SetupRecordsActions/BillingAndInvoiceSetupActions/InvoiceSettingAction";

const TableRowStyled = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: #ffffff;
  }
  &:nth-of-type(even) {
    background-color: #f4f5f8;
  }
  &:nth-of-type(odd):hover {
    background-color: #ffffff;
  }
  &:nth-of-type(even):hover {
    background-color: #f4f5f8;
  }
`;

const TableCellStyled = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 600,
    zIndex: 0,
    color: "#AAAAAA",
    fontSize: "13px",
    padding: "10px 6px 10px 0",
    wordBreak: "break-word",
    verticalAlign: "top",
  },
  [`&.${tableCellClasses.body}`]: {
    borderBottom: "none",
    fontSize: "12px",
    padding: "10px 6px 10px 0",
    wordBreak: "break-word",
    cursor: "pointer",
    verticalAlign: "top",
  },
}));

const MakeMultiPayment = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = useLocation().state.data;

  const [invoiceList, setInvoiceList] = useState([]);
  const { loginData } = useSelector((state) => state.loginReducer);
  const { uploadLoader, loading, error } = useSelector(
    (state) => state.manageInvoiceReducer
  );
  const { bankList } = useSelector((state) => state.invoiceSettingReducer);
  const modeofpaymentoption = [
    {
      value: "CASH",
      label: "CASH",
    },
    {
      value: "CHEQUE",
      label: "CHEQUE",
    },
    {
      value: "NEFT",
      label: "NEFT",
    },
  ];
  const headCells = [
    {
      id: "invoiceDetails",
      label: "Invoice Details",
      sort: false,
      width: "120px",
    },
    { id: "paymentMode", label: "Payment Mode", sort: false },
    { id: "dueAmount", label: "Due Amount", sort: false, width: "60px" },
    { id: "amountPaid", label: "Amount", sort: false },
    {
      id: "chqTranNumber",
      label: "Chq/Tran Number",
      sort: false,
    },
    { id: "chqTranDate", label: "Chq/Tran Date", sort: false },
    { id: "bankName", label: "Bank Name", sort: false },
    { id: "branchName", label: "Branch Name", sort: false },
    { id: "paymentDate", label: "Payment Date", sort: false },
    { id: "clearDate", label: "Clear Date", sort: false },
  ];
  const handleNavigation = (path) => () => {
    navigate(path);
  };

  const handleSubmit = (values) => {
    if (values?.bankId) {
      let newdata = {
        societyId: loginData?.default_community?.community_id,
        ...values,
        onSuccessCallback: () => {
          navigate(MANAGE_DEBIT_CURRENT_ENDPOINT);
        },
      };
      dispatch(bulkMakePaymentDebitNoteSave(newdata));
    } else {
      alert("Please select a bank name");
    }
  };
  useEffect(() => {
    console.log(data);
    setInvoiceList(
      data
        ? Object.entries(data)?.map((item, index) => ({
            invoiceid: item?.[1]?.id || item?.[1]?.invoiceid || "",
            invoice_total: item?.[1]?.amount || item?.[1]?.invoice_total || "",
            invoice_date: item?.[1].invoiceDate || item?.[1].invoice_date || "",
            invoice_no: item?.[1].invoiceNo || item?.[1].invoice_no || "",
            member_name: item?.[1].memberDetails || item?.[1].member_name || "",
            modeofpayment: item?.[1].modeofpayment || "CASH",
            dueamount: item?.[1]?.amountDue || item?.[1]?.dueamount || "",
            amount: item?.[1]?.amountDue || item?.[1]?.amount || "",
            chequenumber: item?.[1]?.chequenumber || "",
            chequedate: item?.[1]?.chequedate || null,
            bankname: item?.[1]?.bankname || "",
            branchname: item?.[1]?.branchname || "",
            paymentdate: item?.[1]?.paymentdate || null,
            cleardate: item?.[1]?.cleardate || null,
            wing_no: item?.[1]?.wingNo,
            flat_no: item?.[1]?.flatNo,
          }))
        : []
    );
    dispatch(
      getBankDetailList({
        societyId: loginData?.default_community?.community_id,
      })
    );
  }, []);
  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2 px-3">
        <Formik
          initialValues={{ invoiceList: invoiceList, bankId: "" }}
          enableReinitialize
          validationSchema={AddMultiPaymentValidation}
          onSubmit={(values, { setSubmitting }) => handleSubmit(values)}
        >
          {({ values, errors, setFieldValue }) => (
            <Form>
              <div className="border-b border-[#CCC]">
                <ListHeader leftContent="Multiple Payment">
                  <DropdownFM2
                    options={
                      bankList?.length > 0
                        ? bankList?.map((item) => ({
                            label: `${item?.bank_name} [Account - ${item?.account_no}]`,
                            value: item?.bankid,
                          }))
                        : []
                    }
                    placeholder="Select a Bank"
                    width="224px"
                    height="36px"
                    onSelect={(selectedItem) => {
                      setFieldValue("bankId", selectedItem.value);
                    }}
                    value={
                      values.bankId
                        ? {
                            label: bankList?.find(
                              (item) => item.bankid === values.bankId
                            )?.bank_name,
                            values: values.bankId,
                          }
                        : ""
                    }
                    name="bankId"
                  />
                  <div className="border-r border-[#CCC]"></div>

                  <Button
                    label="Cancel"
                    className="h-8"
                    onClick={handleNavigation(-1)}
                    type="button"
                  />
                  <div className="border-r border-[#CCC]"></div>
                  <Button
                    label={
                      <span className="inline-block w-12 text-enter">
                        {uploadLoader ? (
                          <CircularProgress sx={{ color: "white" }} size={17} />
                        ) : (
                          "Process"
                        )}
                      </span>
                    }
                    className="h-8 px-4 disabled:opacity-50"
                    type="submit"
                    //disabled={uploadLoader}
                    //onClick={onUpload}
                  />
                </ListHeader>
              </div>
              <Box
                sx={{ width: "100%", fontSize: "13px" }}
                className="h-full table-component"
              >
                <TableContainer
                  className="border-t border-[#CCC]"
                  sx={{
                    minHeight: Object.keys(data).length > 0 ? "500px" : "none",
                  }}
                >
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {headCells?.map((column) => (
                          <TableCellStyled
                            size="small"
                            key={column.id + "heading"}
                            align={column.numeric ? "right" : "left"}
                            sortDirection={false}
                            sx={{
                              width: column.width || "fit-content",
                              minWidth: column.width || "fit-content",
                            }}
                          >
                            {column.label}
                          </TableCellStyled>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <FieldArray
                        name="invoiceList"
                        render={(arrayHelpers) => (
                          <>
                            {values?.invoiceList &&
                              values?.invoiceList?.length > 0 &&
                              values?.invoiceList?.map((invoice, index) => {
                                return (
                                  <React.Fragment key={index}>
                                    <TableRowStyled
                                      key={invoice.id}
                                      role="checkbox"
                                      tabIndex={-1}
                                    >
                                      <TableCellStyled
                                        size="small"
                                        align="left"
                                      >
                                        {invoice.member_name} <br />
                                        {invoice.wingNo} - {invoice.flatNo}{" "}
                                        <br />
                                        {invoice.invoice_no}
                                      </TableCellStyled>
                                      <TableCellStyled
                                        size="small"
                                        align="left"
                                      >
                                        <DropdownFM2
                                          options={
                                            modeofpaymentoption?.map(
                                              (item) => ({
                                                value: item?.value,
                                                label: item?.label,
                                              })
                                            ) || []
                                          }
                                          placeholder="Select MOP"
                                          className="text-[11px] ms-4"
                                          width="115px"
                                          height="37px"
                                          name={`invoiceList.${index}.modeofpayment`}
                                          onSelect={(e) => {
                                            if (e.value === "CASH") {
                                              setFieldValue(
                                                `invoiceList.${index}.chequenumber`,
                                                ""
                                              );
                                              setFieldValue(
                                                `invoiceList.${index}.chequedate`,
                                                null
                                              );
                                              setFieldValue(
                                                `invoiceList.${index}.bankname`,
                                                ""
                                              );
                                              setFieldValue(
                                                `invoiceList.${index}.branchname`,
                                                ""
                                              );
                                            }
                                            setFieldValue(
                                              `invoiceList.${index}.modeofpayment`,
                                              e.value
                                            );
                                            //row.modeofpayment = e.value
                                            //setUploadingExcelData({...data})
                                            //handleSelectFlat(selectedItem);
                                          }}
                                          value={[
                                            {
                                              value: invoice.modeofpayment,
                                              label: invoice.modeofpayment,
                                            },
                                          ]}
                                        />
                                        {/* {row.paymentMode} */}
                                      </TableCellStyled>
                                      <TableCellStyled
                                        size="small"
                                        align="center"
                                        className="!pt-[20px]"
                                      >
                                        {values.invoiceList[index].dueamount}
                                      </TableCellStyled>
                                      <TableCellStyled
                                        size="small"
                                        align="left"
                                      >
                                        <TextInputFM
                                          placeholder="Enter Amount"
                                          className="w-[80px]"
                                          type="decimal"
                                          name={`invoiceList.${index}.amount`}
                                        />
                                      </TableCellStyled>
                                      <TableCellStyled
                                        size="small"
                                        align="left"
                                      >
                                        {console.log(
                                          invoice.modeofpayment === "CASH",
                                          "----"
                                        )}
                                        <TextInput
                                          placeholder="Enter Cheque Number"
                                          className="w-[80px] mb-1"
                                          value={invoice.chequenumber}
                                          type="text"
                                          name={`invoiceList.${index}.chequenumber`}
                                          onChange={(e) => {
                                            setFieldValue(
                                              `invoiceList.${index}.chequenumber`,
                                              e.target.value
                                            );
                                          }}
                                          disabled={
                                            invoice.modeofpayment === "CASH"
                                              ? true
                                              : false
                                          }
                                        />
                                        {/* {row.chqTranNumber} */}
                                      </TableCellStyled>
                                      <TableCellStyled
                                        size="small"
                                        align="left"
                                      >
                                        <DatePickerComponentFM
                                          placeHolder="select date"
                                          className="w-24 h-9"
                                          name={`invoiceList.${index}.chequedate`}
                                          defaultValue={
                                            invoice.chequedate
                                              ? new Date(invoice.chequedate)
                                              : null
                                          }
                                          onDateChange={(selectedDate) => {
                                            setFieldValue(
                                              `invoiceList.${index}.chequedate`,
                                              moment(selectedDate).format(
                                                "YYYY-MM-DD"
                                              )
                                            );
                                          }}
                                          readOnly={
                                            invoice.modeofpayment === "CASH"
                                              ? true
                                              : false
                                          }
                                        />
                                      </TableCellStyled>
                                      <TableCellStyled
                                        size="small"
                                        align="left"
                                      >
                                        <TextInput
                                          placeholder="Enter Bank name"
                                          className="w-[80px]"
                                          value={invoice.bankname}
                                          type="text"
                                          name={`invoiceList.${index}.bankname`}
                                          onChange={(e) => {
                                            setFieldValue(
                                              `invoiceList.${index}.bankname`,
                                              e.target.value
                                            );
                                          }}
                                          disabled={
                                            invoice.modeofpayment === "CASH"
                                              ? true
                                              : false
                                          }
                                        />
                                      </TableCellStyled>
                                      <TableCellStyled
                                        size="small"
                                        align="left"
                                      >
                                        <TextInput
                                          placeholder="Enter Branch Name"
                                          className="w-[80px]"
                                          value={invoice.branchname}
                                          type="text"
                                          name={`invoiceList.${index}.branchname`}
                                          onChange={(e) => {
                                            setFieldValue(
                                              `invoiceList.${index}.branchname`,
                                              e.target.value
                                            );
                                          }}
                                          disabled={
                                            invoice.modeofpayment === "CASH"
                                              ? true
                                              : false
                                          }
                                        />
                                      </TableCellStyled>
                                      <TableCellStyled
                                        size="small"
                                        align="left"
                                      >
                                        <DatePickerComponentFM
                                          placeHolder="select date"
                                          defaultValue={
                                            invoice.paymentdate
                                              ? new Date(invoice.paymentdate)
                                              : null
                                          }
                                          className="w-24 h-9"
                                          name={`invoiceList.${index}.paymentdate`}
                                          onDateChange={(selectedDate) => {
                                            if (
                                              invoice.modeofpayment != "CHEQUE"
                                            ) {
                                              setFieldValue(
                                                `invoiceList.${index}.cleardate`,
                                                moment(selectedDate).format(
                                                  "YYYY-MM-DD"
                                                )
                                              );
                                            }
                                            setFieldValue(
                                              `invoiceList.${index}.paymentdate`,
                                              moment(selectedDate).format(
                                                "YYYY-MM-DD"
                                              )
                                            );
                                          }}
                                          minDate={
                                            new Date(
                                              moment(
                                                invoice?.invoice_date
                                              ).format("YYYY-DD-MM")
                                            )
                                          }
                                        />
                                        {/* {row.paymentDate} */}
                                      </TableCellStyled>
                                      <TableCellStyled
                                        size="small"
                                        align="left"
                                      >
                                        <DatePickerComponentFM
                                          placeHolder="select date"
                                          defaultValue={
                                            invoice.cleardate
                                              ? new Date(invoice.cleardate)
                                              : null
                                          }
                                          className="w-24 h-9"
                                          name={`invoiceList.${index}.cleardate`}
                                          onDateChange={(selectedDate) => {
                                            setFieldValue(
                                              `invoiceList.${index}.cleardate`,
                                              moment(selectedDate).format(
                                                "YYYY-MM-DD"
                                              )
                                            );
                                          }}
                                          readOnly={
                                            invoice.modeofpayment != "CHEQUE"
                                              ? true
                                              : false
                                          }
                                        />
                                        {/* {row.clearDate} */}
                                      </TableCellStyled>
                                    </TableRowStyled>
                                  </React.Fragment>
                                );
                              })}
                          </>
                        )}
                      ></FieldArray>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Form>
          )}
        </Formik>
      </div>
    </Layout>
  );
};

MakeMultiPayment.propTypes = {
  columns: PropTypes.array.isRequired,
  defaultSortField: PropTypes.string,
  rowsPerPageOptions: PropTypes.array,
};

export default MakeMultiPayment;
