import { all } from 'redux-saga/effects';
import societyForgotPasswordSaga from '../sagas/AuthSagas/forgotPasswordSaga'
import loginSaga from './AuthSagas/loginSaga';
import passwordResetSaga from './AuthSagas/passwordResetSaga';
import logoutSaga from './AuthSagas/logoutSaga';
import societyProfileSaga from './SetupRecordsSagas/BuildingSagas/SocietyProfileSaga';
import specificDetailsSaga from './SetupRecordsSagas/BuildingSagas/SpecificDetailsSaga';
import wingsAndFlatsSaga from './SetupRecordsSagas/BuildingSagas/WingsAndFlatsSaga';
import IPAddressSaga from './IPAddressSagas/IPAddressSaga';
import bankDetailsSaga from './SetupRecordsSagas/BuildingSagas/BankDetailsSaga'
import occupantsInfoSaga from './SetupRecordsSagas/Occupants&TenantsSagas/OccupantsInfoSaga';
import getLogAComplaintSettingsSaga from './DeskSaga/LogaComplaintSaga';
import getDocumentSaga from './DeskSaga/DocumentSaga';
import TenantsInfoSaga from './SetupRecordsSagas/Occupants&TenantsSagas/TenantsInfoSaga'
import myInvoiceSaga from './FinanceSagas/MyInvoiceSaga';
import ViewInvitiesSaga from './SetupRecordsSagas/Occupants&TenantsSagas/ViewInvitesSaga';
import LeaveAndLicenseSaga from './SetupRecordsSagas/Occupants&TenantsSagas/LeaveAndLicenseSaga';
import ApplyForTenancySaga from './SetupRecordsSagas/Occupants&TenantsSagas/ApplyForTenancySaga';
import staffPersonnelSaga from './SetupRecordsSagas/StaffManagementSagas/StaffPersonnelSaga';
import maintenanceAgencySaga from './SetupRecordsSagas/StaffManagementSagas/MaintenanceAgencySaga'
import deskSaga from './DeskSaga/DeskSaga';
import complaintCategorySaga from './DeskSaga/ComplaintCategorySaga';
import EmployeeManagementSaga from './SetupRecordsSagas/StaffManagementSagas/EmployeeManagementSaga';
import ManagingCommitteeSaga from './SetupRecordsSagas/StaffManagementSagas/ManagingCommitteeSaga';
import BulkUploadSaga from './SetupRecordsSagas/BulkUploadSagas/BulkUploadSaga';
import MangeRolesAndRightsaga from './SetupRecordsSagas/ManageRolesAndRightsSagas/ManageRolesAndRightsSaga';
import importantContactSaga from './SocialAndCommunicationSagas/LobbySagas/ImportantContactsSaga';
import getNoticeCircularList from './SocialAndCommunicationSagas/NoticeAndCircularSaga'
import billingReportSaga from './FinanceSagas/BillingReportsSaga';
import societyFormsAndByeLawsSaga from './SetupRecordsSagas/SocietyFormsAndByeLawsSagas/SocietyFormsAndByeLawsSaga';
import invoiceSettingsSaga from './FinanceSagas/SettingSagas/InvoiceDetailsSaga';
import creditDebitTariffHeaderSaga from './FinanceSagas/SettingSagas/CreditDebitTariffHeaderSaga';
import visitorAndVehicleInAndOutSaga from './GateManagementSagas/VisitorAndVehicleInAndOutSaga';
import parkingManagementSaga from './GateManagementSagas/ParkingManagementSaga';
import addFacilitySaga from './ClubSagas/AddFacilitySaga';
import viewBookingsSaga from './ClubSagas/ViewBookingsSaga';
import bookAFacilitySaga from './ClubSagas/BookAFacilitySaga';
import addTimeSlotSaga from './ClubSagas/AddTimeSlotSaga';
import manageAdvanceNoteSaga from './FinanceSagas/BillingManagementSagas/ManageAdvanceNoteSaga';
import manageCreditNoteSaga from './FinanceSagas/BillingManagementSagas/ManageCreditNoteSaga';
import manageInvoiceSaga from './FinanceSagas/BillingManagementSagas/ManageInvoiceSaga';
import dueReminderSaga from './FinanceSagas/SettingSagas/DueRemindersSaga';
import gstAndNOCSettingSaga from './SetupRecordsSagas/BillingAndInvoiceSetupSagas/GSTAndNOCSettingSaga';
import invoiceSettingSaga from './SetupRecordsSagas/BillingAndInvoiceSetupSagas/InvoiceSettingSaga';
import manageDebitNoteSaga from './FinanceSagas/BillingManagementSagas/ManageDebittNoteSaga';
import registersSaga from './ComplianceSagas/RegistersSaga';
import meetingsSaga from './ComplianceSagas/MeetingsSaga';
import financeReportSaga from "./ReportsSaga/FinanceReportSaga"
import oldFinancialReportsSaga from './ReportsSaga/OldFInancialReportsSagas';
import societyReportSaga from './ReportsSaga/SocietyReportSaga';
import generateInvoiceSaga from './FinanceSagas/BillingManagementSagas/GenerateInvoiceSaga';

export default function* rootSaga() {
  yield all([
    IPAddressSaga(),
    societyForgotPasswordSaga(),
    loginSaga(),
    passwordResetSaga(),
    logoutSaga(),

    societyProfileSaga(),
    specificDetailsSaga(),
    wingsAndFlatsSaga(),
    bankDetailsSaga(),

    occupantsInfoSaga(),
    TenantsInfoSaga(),
    LeaveAndLicenseSaga(),
    ApplyForTenancySaga(),
    ViewInvitiesSaga(),

    staffPersonnelSaga(),
    maintenanceAgencySaga(),
    EmployeeManagementSaga(),
    ManagingCommitteeSaga(),

    gstAndNOCSettingSaga(),
    invoiceSettingSaga(),

    BulkUploadSaga(),

    MangeRolesAndRightsaga(),

    societyFormsAndByeLawsSaga(),

    importantContactSaga(),
    getNoticeCircularList(),

    myInvoiceSaga(),
    billingReportSaga(),
    manageAdvanceNoteSaga(),
    manageCreditNoteSaga(),
    manageInvoiceSaga(),
    creditDebitTariffHeaderSaga(),
    invoiceSettingsSaga(),
    manageDebitNoteSaga(),
    dueReminderSaga(),
    generateInvoiceSaga(),

    registersSaga(),
    meetingsSaga(),

    getLogAComplaintSettingsSaga(),
    getDocumentSaga(),
    deskSaga(),
    complaintCategorySaga(),

    viewBookingsSaga(),
    addFacilitySaga(),
    bookAFacilitySaga(),
    addTimeSlotSaga(),

    visitorAndVehicleInAndOutSaga(),
    parkingManagementSaga(),
    financeReportSaga(),
    oldFinancialReportsSaga(),
    societyReportSaga(),
  ])
}