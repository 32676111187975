import React, { useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Checkbox from "@mui/material/Checkbox";
import { visuallyHidden } from "@mui/utils";
import "../../../components/TableComponent/Table.css";
import {
  CancelIcon,
  DeleteIcon,
  DisableIcon,
  PaymentAdjustmentIcon,
  PaymentIcon,
  PrinterIcon,
  SortIcon,
  ViewIcon,
} from "../../../assets";
import styled from "@emotion/styled";
import {
  stableSort,
  getComparator,
} from "../../../utils/helpers/universalFunctions";
import { Link, createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import { Tooltip } from "@mui/material";
import Loader from "../../../components/Loader/Loader";
import ItemsPerPage from "../../../components/ItemsPerPage/ItemsPerPage";
import Pagination from "../../../components/Pagination/Pagination";
import DeleteModal from "../../../components/Modal/DeleteModal";
import {
  BOOKING_PAYMENT_AND_ADJUSTMENT_ENDPOINT,
  PRINT_BOOKING_RECIEPT_ENDPOINT,
  VIEW_BOOKINGS_DETAILS_ENDPOINT,
  VIEW_BOOKINGS_ENDPOINT,
  MAKE_PAYMENT_FOR_BOOKINGS_ENDPOINT,
  PRINT_BOOKING_INVOICE_ENDPOINT,
} from "../../../Routing/routes";
import BookingCancellationModal from "./BookingCancellationModal";
import { useDispatch } from "react-redux";
import { changeBookingStatus } from "../../../redux/actions/ClubActions/ViewBookingsAction";
import { useSelector } from "react-redux";
import RequestForCancelModal from "./RequestForCancelModal";
import ChangeStatusModal from "../../../components/Modal/ChangeStatusModal";
import { getPermission } from "../../../utils/helpers/getPermissions";
import * as permissions from '../../../Routing/permissions'

function ActionComponent({ id, invoiceId, statusValue, amountDue, memberId}) {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const {loginData} = useSelector(state=>state.loginReducer)
  const {updateLoader} = useSelector(state=>state.viewBookingReducer)

  const handleNavigation = (path) => () => {
    navigate(path);
  };
  const [bookingCancellation, setBookingCancellation] = useState(false);
  const [requestCancel, setRequestCancel] = useState(false);

  const handleBookingCancellation = () => {
    setBookingCancellation(true);
  };

  const handleDeleteClick = () => {
    setShowDeleteModal(true);
  };

  const hancleChangeStatus = (status, reason) =>{
    dispatch(changeBookingStatus({
      societyId: loginData?.default_community?.community_id,
      bookingId: id, 
      status,
      reason,
      onSuccessCallback: ()=>{
        setBookingCancellation(false)
        setShowDeleteModal(false)
        setRequestCancel(false)
      }
    }))
  }

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Tooltip title="View" arrow>
        <ViewIcon
          className="fill-[#555] hover:fill-red-650 me-1.5"
          onClick={handleNavigation(`${VIEW_BOOKINGS_DETAILS_ENDPOINT}/${id}`)}
        />
      </Tooltip>
      {(statusValue === "A" || statusValue === "UP") && (amountDue>0) && (getPermission(loginData, [permissions.MAKE_BOOKING_PAYMENT_PERMISSION]) || (getPermission(loginData, [permissions.MAKE_OWN_BOOKING_PAYMENT_PERMISSION]) && memberId === (loginData?.default_community?.subscriber_id || loginData?.default_community?.tenant_id))) && (
        <>
          <Tooltip title="Payment & Adjustment" arrow>
            <PaymentAdjustmentIcon
              className="fill-[#555] hover:fill-red-650 me-1.5"
              onClick={handleNavigation(
                `${BOOKING_PAYMENT_AND_ADJUSTMENT_ENDPOINT}/${id}`
              )}
            />
          </Tooltip>
          <Tooltip title="Payment" arrow>
            <PaymentIcon
              className="fill-[#555] hover:fill-red-650 me-1.5"
              onClick={handleNavigation(
                `${MAKE_PAYMENT_FOR_BOOKINGS_ENDPOINT}/${id}`
              )}
            />
          </Tooltip>
        </>
      )}
      {(statusValue === "A" || statusValue === "UP" || statusValue === "C") && getPermission(loginData, [permissions.PRINT_BOOKING_INVOICE_PERMISSION]) && (
        <Tooltip title="Print Reciept" arrow>
          <Link to={`${PRINT_BOOKING_RECIEPT_ENDPOINT}/${id}`} target="_blank">
            <PrinterIcon className="fill-[#555] hover:fill-red-650 me-1.5" />
          </Link>
        </Tooltip>
      )}
      
      {/* {(statusValue === "A" ) && getPermission(loginData, [permissions.PRINT_BOOKING_INVOICE_PERMISSION]) && (
        <Tooltip title="Print Invoice" arrow>
          <Link to={`${PRINT_BOOKING_INVOICE_ENDPOINT}/${invoiceId}`} target="_blank">
            <PrinterIcon className="fill-[#555] hover:fill-red-650 me-1.5" />
          </Link>
        </Tooltip>
      )} */}

      {(statusValue === "A") && getPermission(loginData, [permissions.BOOKING_REQUEST_FOR_CANCELLATION_PERMISSION])  && (
        <Tooltip title="Request For Cancellation" arrow>
          <CancelIcon
            className="fill-[#555] hover:fill-red-650 me-1.5"
            onClick={()=>{setRequestCancel(true)}}
          />
        </Tooltip>
      )}
      {(statusValue === "A" || statusValue === "UP" || statusValue === "W") && getPermission(loginData, [permissions.CANCEL_BOOKING_PERMISSION])  && (
        <Tooltip title="Cancel Booking" arrow>
          <DisableIcon
            className="fill-[#555] hover:fill-red-650 me-1.5"
            onClick={handleBookingCancellation}
          />
        </Tooltip>
      )}
      {(statusValue === "C") && getPermission(loginData, [permissions.DELETE_BOOKING_PERMISSION]) && (
        <Tooltip title="Delete" arrow>
          <DeleteIcon
            className="fill-[#555] hover:fill-red-650 me-1.5"
            onClick={handleDeleteClick}
          />
        </Tooltip>
      )}
      {/* delete recirpet  */}
      <DeleteModal
        isOpen={showDeleteModal}
        onCancel={() => setShowDeleteModal(false)}
        onDelete={()=>{hancleChangeStatus('D', '')}}
        loading={updateLoader}
      />
      <BookingCancellationModal
        isOpen={bookingCancellation}
        onClose={() => {
          setBookingCancellation(false);
        }}
        onConfirm={(reason)=>{hancleChangeStatus('C', reason)}}
        loading={updateLoader}
      />
      <RequestForCancelModal
        isOpen={requestCancel}
        onClose={() => {
          setRequestCancel(false);
        }}
        onConfirm={(reason)=>{hancleChangeStatus('RC', reason)}}
        loading={updateLoader}
      />
    </Box>
  );
}

  const TableRowStyled = styled(TableRow)`
    &:nth-of-type(odd) {
      background-color: #ffffff;
    }
    &:nth-of-type(even) {
      background-color: #f4f5f8;
    }
    &:nth-of-type(odd):hover {
      background-color: #ffffff;
    }
    &:nth-of-type(even):hover {
      background-color: #f4f5f8;
    }
  `;

  const TableCellStyled = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      fontWeight: 600,
      zIndex: 0,
      color: "#AAAAAA",
      fontSize: "13px",
      padding: "12px 8px 12px 0",
      wordBreak: "break-word",
    },
    [`&.${tableCellClasses.body}`]: {
      borderBottom: "none",
      fontSize: "13px",
      padding: "12px 8px 12px 0",
      wordBreak: "break-word",
      cursor: "pointer",
    },
  }));

const ViewBookingsTable = ({
  loading,
  data,
  columns,
  checkbox = true,
  pagination = true,

  //props are required if pagination is true
  totalCount,
  totalPages,
  start,
  end,
  currentPage,
  rowsPerPage,
}) => {
  const navigate = useNavigate();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(null);
  const [selected, setSelected] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams()
  const [showChangeStatusModal, setShowChangeStatusModal] = useState({id: '', open: false});
  const {updateLoader} = useSelector(state=>state.viewBookingReducer)
  const {loginData} = useSelector(state=>state.loginReducer)
  const dispatch = useDispatch()

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    const newSelected = event.target.checked
      ? data.map((item) => item.bookingId)
      : [];
    setSelected(newSelected);
  };

  const handleClick = (event, id) => {
    const newSelected = selected.includes(id)
      ? selected.filter((selectedId) => selectedId !== id)
      : [...selected, id];
    setSelected(newSelected);
  };

  const handleChangePage = (newPage) => {
    searchParams.delete("page")
    navigate({
      pathname: `${VIEW_BOOKINGS_ENDPOINT}`,
      search: `?${createSearchParams([...searchParams.entries(), ["page", newPage]])}`,
    });
  };

  const handleChangeRowsPerPage = (value) => {
    if (rowsPerPage !== value) {
      searchParams.delete("page")
      searchParams.delete("pageLimit")
      navigate({
        pathname: `${VIEW_BOOKINGS_ENDPOINT}`,
        search: `?${createSearchParams([...searchParams.entries(), ["pageLimit", value]])}`,
      });
    }
  };

  const handleChangeStatus = (id, status)=>{
    dispatch(changeBookingStatus({
      bookingId: id, 
      status,
      reason: '',
      onSuccessCallback: ()=>{
        setShowChangeStatusModal(false)
      }
    }))
  }

  
  const isSelected = (id) => selected.includes(id);

  return (
    <Box sx={{ width: "100%", fontSize: "13px" }} className="table-component">
      <TableContainer
        className="border-t border-[#CCC]"
        sx={{ minHeight: data?.length ? "500px" : "none" }}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {!!checkbox && (
                <TableCellStyled
                  size="small"
                  sx={{ pl: "0 !important", pr: 2, width: 36 }}
                >
                  <Checkbox
                    size="small"
                    indeterminate={false}
                    checked={
                      data?.length > 0 && selected?.length === data?.length
                    }
                    onChange={handleSelectAllClick}
                    inputProps={{
                      "aria-label": "select all",
                    }}
                    sx={{
                      p: 0,
                      color: "#AAAAA",
                      "&.Mui-checked": {
                        color: "#CF001C",
                      },
                    }}
                    disabled={loading || !data}
                  />
                </TableCellStyled>
              )}
              {columns?.map((column) => (
                <TableCellStyled
                  size="small"
                  key={column.id + "heading"}
                  align={column.numeric ? "right" : "left"}
                  sortDirection={orderBy === column.id ? order : false}
                  sx={{
                    width: column.width || "fit-content",
                    minWidth: column.width || "fit-content",
                  }}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={orderBy === column.id ? order : "asc"}
                    onClick={() => {
                      if (column.sort) {
                        handleRequestSort(column.id);
                      }
                    }}
                    IconComponent={SortIcon}
                    sx={{
                      ".MuiTableSortLabel-icon": {
                        opacity: "1 !important",
                      },
                      svg: {
                        flexShrink: 0,
                      },
                    }}
                    hideSortIcon={!column.sort}
                  >
                    {column.label}
                    {orderBy === column.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCellStyled>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCellStyled
                  sx={{ border: "none" }}
                  colSpan={columns?.length + 1}
                >
                  <Loader />
                </TableCellStyled>
              </TableRow>
            ) : (
              <>
                {!!data?.length &&
                  stableSort(data, getComparator(order, orderBy))?.map(
                    (row, rowIndex) => {
                      const isRowSelected = isSelected(row?.id);

                      return (
                        <TableRowStyled
                          key={row?.bookingId}
                          role="checkbox"
                          aria-checked={isRowSelected}
                          tabIndex={-1}
                          selected={isRowSelected}
                        >
                          {!!checkbox && (
                            <TableCellStyled
                              size="small"
                              sx={{ pl: "0 !important", zIndex: 0 }}
                              onClick={(event) =>
                                handleClick(event, row?.bookingId)
                              }
                            >
                              <Checkbox
                                checked={selected?.includes(
                                  row?.bookingId
                                )}
                                onChange={(e) =>
                                  handleClick(e, row?.bookingId)
                                }
                                size="small"
                                color="primary"
                                inputProps={{
                                  "aria-labelledby": `generic-table-checkbox-${row?.id}`,
                                }}
                                sx={{
                                  p: 0,
                                  color: "#AAAAA",
                                  "&.Mui-checked": {
                                    color: "#CF001C",
                                  },
                                }}
                              />
                            </TableCellStyled>
                          )}

                          {/* Serial Number */}
                          <TableCellStyled
                            size="small"
                            align="left"
                            onClick={(event) => handleClick(event)}
                          >
                            {row?.sNo}
                          </TableCellStyled>

                          <TableCellStyled size="small" align="left">
                            {row?.invoiceNo}
                          </TableCellStyled>

                          <TableCellStyled size="small" align="left">
                            {row?.facility}
                          </TableCellStyled>
                          <TableCellStyled size="small" align="left">
                            {row?.bookingFrequency}
                            <br />
                            {row?.booking_day !== "NULL" && row?.bookingFrequency !== "One Time" ? row?.booking_day : ""}
                          </TableCellStyled>
                          <TableCellStyled size="small" align="left">
                            {row?.noOfBooking}
                          </TableCellStyled>
                          <TableCellStyled size="small" align="left">
                            {row?.noOfDays}
                          </TableCellStyled>
                          <TableCellStyled size="small" align="left">
                            {row?.flat}
                          </TableCellStyled>
                          <TableCellStyled size="small" align="left">
                            {
                              row?.bookingFrequency==='One Time' ? 
                              row?.fromDate
                              :
                              <>
                              {row?.fromDate} - <br/> {row.toDate}
                              </>
                            }
                          </TableCellStyled>
                          <TableCellStyled size="small" align="left">
                            Amount:{row?.amount} <br />
                            Paid:{row?.amountPaid} <br />
                            Due:{row?.amountDue}
                          </TableCellStyled>
                          <TableCellStyled size="small" align="left">
                            {
                              (row.statusValue !== 'UP' && row.statusValue !== 'RC') ?
                              <span className="font-semibold">{row.status}</span>:
                              <div>
                                <u 
                                  onClick={getPermission(loginData, [permissions.APPROVE_BOOKING_PERMISSION]) ? ()=> setShowChangeStatusModal({id: row.bookingId, open: true}) : undefined} 
                                  className={`font-semibold text-blue-500`} >

                                  {row?.status}

                                </u>
                                <ChangeStatusModal
                                  isOpen={showChangeStatusModal.open && row.bookingId === showChangeStatusModal.id}
                                  onCancel={()=>setShowChangeStatusModal(false)}
                                  onConfirm={()=>{handleChangeStatus(row.bookingId, row?.statusValue==='RC' ? 'C' : 'A')}}
                                  text={`Do you want to ${row?.statusValue==='RC' ? 'cancel the booking' : ' change status to approve'} ?`}
                                  loading={updateLoader}
                                />
                              </div>
                            }
                          </TableCellStyled>

                          <TableCellStyled size="small" align="left">
                            <ActionComponent invoiceId={row.invoiceId} amountDue={row.amountDue} id={row.bookingId} status={row.status} statusValue={row.statusValue} memberId={row.memberId} />
                          </TableCellStyled>
                        </TableRowStyled>
                      );
                    }
                  )}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {!!pagination && data && (
        <div className="flex flex-row justify-between mt-3 pt-1 border-t border-[#ccc] text-[14px]">
          <ItemsPerPage
            rowsPerPage={rowsPerPage}
            onChange={handleChangeRowsPerPage}
          />
          <Pagination
            totalCount={totalCount}
            totalPages={totalPages}
            start={start}
            end={end}
            currentPage={currentPage}
            onChange={handleChangePage}
          />
        </div>
      )}
    </Box>
  );
};

ViewBookingsTable.propTypes = {
  columns: PropTypes.array.isRequired,
  defaultSortField: PropTypes.string,
  rowsPerPageOptions: PropTypes.array,
};

export default ViewBookingsTable;
